import {FC, useEffect, useState} from 'react';

import {HoobiizActivityAddressAtHome, HoobiizActivityAddressType} from '@shared/dynamo_model';
import {DEFAULT_HOME_RANGE_IN_KM} from '@shared/model/google_maps_constants';
import {FullItem} from '@shared/model/search_tables';

import {Input} from '@shared-frontend/components/core/input_v2';
import {Select} from '@shared-frontend/components/core/select';

import {FormColumn, FormRow} from '@src/components/admin/form/form_fragments';
import {adminInputTheme} from '@src/components/core/theme';

interface ActivityAddressAtHomeFormProps {
  initialData?: HoobiizActivityAddressAtHome;
  onChange: (newVal: HoobiizActivityAddressAtHome | undefined) => void;
  vendor?: FullItem<'HoobiizVendor'>;
}

export const ActivityAddressAtHomeForm: FC<ActivityAddressAtHomeFormProps> = props => {
  const {initialData, onChange, vendor} = props;

  // State to set the range in km
  const [rangeInKm, setRangeInKm] = useState<number>(
    initialData?.rangeInKm ?? DEFAULT_HOME_RANGE_IN_KM
  );

  // State to set the selected index of the vendor location (in case of multiple addresses)
  const [vendorLocationIndex, setVendorLocationIndex] = useState<number>(
    initialData?.vendorLocationIndex ?? 0
  );

  useEffect(() => {
    onChange({
      type: HoobiizActivityAddressType.AtHome,
      rangeInKm,
      vendorLocationIndex,
    });
  }, [onChange, rangeInKm, vendorLocationIndex]);

  // Display an input to set the range in km
  return (
    <FormRow>
      <FormColumn>
        <Select
          width="100%"
          label="ADRESSE SÉLECTIONNÉE"
          values={(vendor?.addresses ?? []).map(({name}, index) => ({label: name, value: index}))}
          value={vendorLocationIndex}
          syncState={setVendorLocationIndex}
          overrides={adminInputTheme}
        />
      </FormColumn>
      <FormColumn>
        <Input
          width="100%"
          label="RAYON EN KM"
          type="number"
          value={rangeInKm}
          syncState={setRangeInKm}
          overrides={adminInputTheme}
          min={1}
          max={1000}
          step={1}
        />
      </FormColumn>
    </FormRow>
  );
};
ActivityAddressAtHomeForm.displayName = 'ActivityAddressAtHomeForm';
