import {FC, useCallback, useEffect, useMemo, useRef} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizStockId, HoobiizTicketFileItem} from '@shared/dynamo_model';
import {removeUndefined} from '@shared/lib/type_utils';

import {apiCall} from '@shared-frontend/api';
import {showSuccess} from '@shared-frontend/components/core/notifications';
import {NULL_REF} from '@shared-frontend/lib/react';
import {useStateRef} from '@shared-frontend/lib/use_state_ref';

import {HoobiizTicketFilesUploadLine} from '@src/components/admin/activity_stock/hoobiiz_ticket_files_upload_line';
import {DropZoneDiv} from '@src/components/admin/form/drop_zone_div';
import {fileListToArray} from '@src/components/admin/form/drop_zone_lib';
import {HoobiizMessage} from '@src/components/admin/form/hoobiiz_message';
import {SmallButton} from '@src/components/core/theme';
import {Colors} from '@src/components/core/theme_base';

interface HoobiizBatchAddStockEntriesFormProps {
  stockId: HoobiizStockId;
}

type StockEntryStatus =
  | {
      type: 'initial';
      file: File;
    }
  | {
      type: 'queued';
      file: File;
      item: HoobiizTicketFileItem;
    }
  | {
      type: 'creating';
      file: File;
      item: HoobiizTicketFileItem;
    }
  | {
      type: 'success';
      file: File;
      item: HoobiizTicketFileItem;
    }
  | {
      type: 'error';
      file: File;
      item?: HoobiizTicketFileItem;
      error: string;
    };

export const HoobiizBatchAddStockEntriesForm: FC<HoobiizBatchAddStockEntriesFormProps> = ({
  stockId,
}) => {
  const [stockEntryStatuses, setStockEntryStatuses, stockEntryStatusesRef] = useStateRef<
    StockEntryStatus[]
  >([]);
  const [isStarted, setIsStarted, isStartedRef] = useStateRef(false);

  // Trigger native upload dialog when the dropzone is clicked
  const uploadInputRef = useRef<HTMLInputElement>(NULL_REF);
  const handleUploadClick = useCallback(() => {
    if (uploadInputRef.current) {
      uploadInputRef.current.click();
    }
  }, []);

  // Callback when the user dropped files directly in the dropzone
  const handleFileDrop = useCallback(
    (files: File[]) => {
      setStockEntryStatuses(stockEntryStatuses => {
        const alreadyUploadedFiles = new Set(stockEntryStatuses.map(status => status.file));
        return [
          ...stockEntryStatuses,
          ...files
            .filter(file => !alreadyUploadedFiles.has(file))
            .map(file => ({type: 'initial' as const, file})),
        ];
      });
    },
    [setStockEntryStatuses]
  );

  // Callback when the user selected files with the native file dialog
  const handleFileChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    evt => {
      const {files} = evt.currentTarget;
      // eslint-disable-next-line no-null/no-null
      if (files === null) {
        return;
      }
      // eslint-disable-next-line no-null/no-null
      evt.currentTarget.files = null;
      handleFileDrop(fileListToArray(files));
    },
    [handleFileDrop]
  );

  // Callback when the stock entry is successfully created
  const handleFileSuccess = useCallback(
    (file: File, ticketFile: HoobiizTicketFileItem) => {
      setStockEntryStatuses(stockEntryStatuses =>
        stockEntryStatuses.map(status =>
          status.file === file && status.type === 'initial'
            ? {
                ...status,
                type: 'queued' as const,
                item: ticketFile,
              }
            : status
        )
      );
    },
    [setStockEntryStatuses]
  );

  // Callback when the user cancel a file upload
  const handleFileDelete = useCallback(
    (file: File) => {
      setStockEntryStatuses(stockEntryStatuses =>
        stockEntryStatuses.filter(status => status.file !== file)
      );
    },
    [setStockEntryStatuses]
  );

  // Callback when the user click on the create button
  const handleCreateStockEntries = useCallback(() => {
    setIsStarted(true);
  }, [setIsStarted]);

  // Run periodically to check if there are some queued items that needs processing
  const isRunningRef = useRef(false);
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isStartedRef.current || isRunningRef.current) {
        return;
      }

      // Process queued items in batches of 50
      const current = stockEntryStatusesRef.current;
      const batchSize = 50;
      const queued: (StockEntryStatus & {type: 'queued'})[] = current
        .filter((status): status is StockEntryStatus & {type: 'queued'} => status.type === 'queued')
        .slice(0, batchSize);
      const queuedFiles = new Set(queued.map(status => status.file));
      const queuedTicketFileIds = new Set(queued.map(status => status.item.id));
      if (queued.length > 0) {
        isRunningRef.current = true;
        setStockEntryStatuses(stockEntryStatuses =>
          stockEntryStatuses.map(status => {
            if (queuedFiles.has(status.file)) {
              const typedStatus = status as StockEntryStatus & {type: 'queued'};
              return {type: 'creating' as const, file: typedStatus.file, item: typedStatus.item};
            }
            return status;
          })
        );
        apiCall(HoobiizApi, '/admin/add-stock-entries', {
          fileIds: queued.map(status => status.item.id),
          stockId,
        })
          .then(() => {
            isRunningRef.current = false;
            setStockEntryStatuses(stockEntryStatuses => {
              const newStockEntryStatuses = [
                ...stockEntryStatuses.filter(
                  status =>
                    !('item' in status && status.item && queuedTicketFileIds.has(status.item.id))
                ),
                ...removeUndefined(
                  queued.map((se, i) => {
                    const status = queued[i];
                    if (!status) {
                      return undefined;
                    }
                    return {...status, type: 'success' as const};
                  })
                ),
              ];

              if (newStockEntryStatuses.every(status => status.type === 'success')) {
                setIsStarted(false);
                showSuccess(`${newStockEntryStatuses.length} lignes de stock créées`);
              }

              return newStockEntryStatuses;
            });
          })
          .catch(err => {
            isRunningRef.current = false;
            setStockEntryStatuses(stockEntryStatuses => [
              ...stockEntryStatuses.filter(
                status =>
                  !('item' in status && status.item && queuedTicketFileIds.has(status.item.id))
              ),
              ...removeUndefined(
                queued.map((_, i) => {
                  const status = queued[i];
                  if (!status) {
                    return undefined;
                  }
                  return {
                    ...status,
                    type: 'error' as const,
                    error: err.message,
                  };
                })
              ),
            ]);
          });
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [
    isRunningRef,
    isStartedRef,
    setIsStarted,
    setStockEntryStatuses,
    stockEntryStatusesRef,
    stockId,
  ]);

  const sortedStockEntryStatuses = useMemo(() => {
    return stockEntryStatuses.sort((a, b) => {
      return a.file.name.localeCompare(b.file.name);
    });
  }, [stockEntryStatuses]);

  return (
    <Wrapper>
      <Title>Ajout de lignes de stock</Title>
      <HiddenInput
        type="file"
        multiple
        accept="application/pdf"
        ref={uploadInputRef}
        onChange={handleFileChange}
      />
      <DropZone onFilesDropped={handleFileDrop} onClick={handleUploadClick}>
        Ajouter des fichiers (1 fichier = 1 ligne de stock)
      </DropZone>
      {sortedStockEntryStatuses.length > 0 ? (
        <>
          <StockEntriesHeader>
            <div>
              {String(
                sortedStockEntryStatuses.length === 1
                  ? '1 fichier sélectionné'
                  : `${sortedStockEntryStatuses.length} fichiers sélectionnés`
              )}
            </div>
            <SmallButton onClick={handleCreateStockEntries}>{`Créer ${
              sortedStockEntryStatuses.length === 1
                ? 'la ligne'
                : `les ${sortedStockEntryStatuses.length} lignes`
            } de stock`}</SmallButton>
          </StockEntriesHeader>
          <HoobiizMessage
            color="warning"
            marginTop={8}
            textAlign="center"
            messages={[
              'Si des lignes de stock nécessitant un upload existent, les billets uploadés ici seront associés à ces lignes en priorité en priorité. Après celà, une nouvelles lignes de stock sera créé par billet uploadé.',
            ]}
          />
          <UploadLineList>
            {sortedStockEntryStatuses.map((status, i) => {
              const file = status.file;
              return (
                <UploadLineWrapper key={i}>
                  <HoobiizTicketFilesUploadLine
                    file={file}
                    onSuccess={handleFileSuccess}
                    onDelete={handleFileDelete}
                    preventAutoStart={!isStarted}
                    overrideMessage={
                      {
                        initial: undefined,
                        queued: "Dans la file d'attente...",
                        creating: 'Création du stock...',
                        success: 'Stock créé',
                        error: `Erreur ${'error' in status ? status.error : ''}`,
                      }[status.type]
                    }
                    overrideStripes={
                      {
                        initial: undefined,
                        queued: true,
                        creating: true,
                        success: false,
                        error: false,
                      }[status.type]
                    }
                  />
                  {/* <HoobiizStockEntryUploadLine
                    key={i}
                    stockId={stockId}
                    file={status.file}
                    onSuccess={handleFileSuccess}
                    onDelete={handleFileDelete}
                    preventAutoStart={!isStarted}
                  /> */}
                </UploadLineWrapper>
              );
            })}
          </UploadLineList>
        </>
      ) : (
        <></>
      )}
    </Wrapper>
  );
};
HoobiizBatchAddStockEntriesForm.displayName = 'HoobiizBatchAddStockEntriesForm';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  overflow-y: auto;
  padding: 32px;
  background-color: ${Colors.White};
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`;

const HiddenInput = styled.input`
  display: none;
`;

const DropZone = styled(DropZoneDiv)`
  height: 120px;
  padding: 8px;
`;

const UploadLineList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const UploadLineWrapper = styled.div`
  padding: 8px;
  border: 1px solid ${Colors.Grey};
  border-radius: 4px;
`;

const StockEntriesHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background: #00000011;
`;
