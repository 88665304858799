import {useMemo, useRef} from 'react';

import {
  CityLookupResult,
  HoobiizActivitySorting,
} from '@shared/api/definitions/public_api/hoobiiz_api';
import {FrontendUserDataContentType} from '@shared/dynamo_model';

import {createDataStore} from '@shared-frontend/lib/data_store';
import {
  createTypedSessionDataStore,
  TypedUserSessionItem,
} from '@shared-frontend/lib/session_store';

const typedDataStore = createTypedSessionDataStore<FrontendUserDataContentType.Hoobiiz>();
export const setHoobiizSession = typedDataStore.setData;

export function useHoobiizSession():
  | TypedUserSessionItem<FrontendUserDataContentType.Hoobiiz>
  | undefined {
  const session = typedDataStore.useData();
  return 'sessionId' in session ? session : undefined;
}

//
// Cart
//

export const cartCountStore = createDataStore<number | undefined>();
export const setCartCount = cartCountStore.setData;
export const useCartCount = cartCountStore.useData;
export const increaseCartCount = (inc: number): void =>
  cartCountStore.updateData(count => Math.max(0, (count ?? 0) + inc));

//
// HoobiizActivityGlobalSorting
//

export enum HoobiizActivitySortingMetadataType {
  Everywhere = 'everywhere',
  AroundMe = 'around-me',
  City = 'city',
}

export type HoobiizActivitySortingMetadata =
  | {type: HoobiizActivitySortingMetadataType.Everywhere}
  | {
      type: HoobiizActivitySortingMetadataType.AroundMe;
      location: HoobiizUserLocation;
    }
  | {type: HoobiizActivitySortingMetadataType.City; city: CityLookupResult | undefined};

export type HoobiizUserLocation =
  | {type: 'init'}
  | {type: 'pending'}
  | {type: 'success'; lat: number; lng: number}
  | {type: 'error'; err: string};

export const activitySortingStore = createDataStore<HoobiizActivitySortingMetadata>({
  type: HoobiizActivitySortingMetadataType.Everywhere,
});
export const setActivitySortingMetadata = activitySortingStore.setData;
export const useActivitySortingMetadata = activitySortingStore.useData;

export function useActivitySorting(): HoobiizActivitySorting {
  const location = useActivitySortingMetadata();
  const current = useMemo<HoobiizActivitySorting>(() => {
    if (
      location.type === HoobiizActivitySortingMetadataType.AroundMe &&
      location.location.type === 'success'
    ) {
      const {lat, lng} = location.location;
      return {type: 'position', lat, lng};
    }
    if (location.type === HoobiizActivitySortingMetadataType.City && location.city !== undefined) {
      const {lat, lng} = location.city.geometry.location;
      return {type: 'position', lat, lng};
    }
    return {type: 'featured'};
  }, [location]);
  const lastReturned = useRef<HoobiizActivitySorting>({type: 'featured'});

  // When nothing changed, return the "previous" value
  if (
    (lastReturned.current.type === 'featured' && current.type === 'featured') ||
    (lastReturned.current.type === 'position' &&
      current.type === 'position' &&
      lastReturned.current.lat === current.lat &&
      lastReturned.current.lng === current.lng)
  ) {
    return lastReturned.current;
  }

  // Something changed, store the new value and return it
  lastReturned.current = current;
  return current;
}
