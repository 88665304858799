import {FC} from 'react';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizActivityId} from '@shared/dynamo_model';

import {useApiCall} from '@shared-frontend/lib/use_api_call';
import {useResponsive} from '@shared-frontend/lib/use_responsive';
import {useParams} from '@shared-frontend/lib/use_typed_params';

import {ColumnWrapper} from '@src/components/core/scaffolding';
import {NotFoundPage} from '@src/components/pages/not_found_page';
import {HoobiizActivityPageView} from '@src/components/ui/hoobiiz_activity_page_view';

export const HoobiizActivityPage: FC = () => {
  const {id = ''} = useParams();
  const activityId = id as HoobiizActivityId;
  const {isMobile} = useResponsive();

  const {data} = useApiCall(
    HoobiizApi,
    '/get-activity',
    {activityId},
    {errMsg: `Échec du chargement de l'activité. Vous pouvez rafraichir la page pour réessayer`}
  );

  if (!data) {
    return <ColumnWrapper $isMobile={isMobile}>Chargement...</ColumnWrapper>;
  }

  const {activity} = data;
  if (!activity) {
    return <NotFoundPage />;
  }

  return (
    <HoobiizActivityPageView
      activityId={activity.id}
      label={activity.label}
      cat={activity.cat}
      activityAddress={activity.address}
      vendor={activity.vendor}
      media={activity.media}
      markdown={activity.markdown}
      headerOverride={activity.headerOverride}
      firstStockTs={activity.firstStockTs}
      promoCodeStock={activity.promoCodeStock}
      bestStock={activity.bestStock}
    />
  );
};

HoobiizActivityPage.displayName = 'HoobiizActivityPage';
