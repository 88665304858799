import {FC, useCallback} from 'react';
import styled from 'styled-components';

import {HoobiizUserItem} from '@shared/api/definitions/public_api/hoobiiz_api';
import {FrontendUserId, HoobiizUserProfile} from '@shared/dynamo_model';

import {ButtonAsLink} from '@shared-frontend/components/core/button';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';

import {
  HoobiizUserGroupStoreItem,
  useHoobiizUserGroupUser,
} from '@src/components/admin/user_and_group/admin_user_and_group_store';

export type HoobiizAdministrator = HoobiizUserProfile['permissions'];

interface AdminUserAndGroupAdministratorFormProps {
  group: HoobiizUserGroupStoreItem;
  userId: FrontendUserId;
  onDeleteClick: (user: HoobiizUserItem) => Promise<void>;
}

export const AdminUserAndGroupAdministratorForm: FC<
  AdminUserAndGroupAdministratorFormProps
> = props => {
  const {userId, onDeleteClick} = props;
  const userAndGroup = useHoobiizUserGroupUser({userId});

  const handleDeleteClick = useCallback(async () => {
    if (!userAndGroup) {
      return;
    }
    await onDeleteClick(userAndGroup.user);
  }, [onDeleteClick, userAndGroup]);

  if (!userAndGroup) {
    return <></>;
  }

  const {userEmail} = userAndGroup.user;

  return (
    <Wrapper>
      {userEmail}
      <ButtonAsLink onClickAsync={handleDeleteClick}>
        <SvgIcon name="Trash" color="#888" size={13} colorHover="#444" />
      </ButtonAsLink>
    </Wrapper>
  );
};

AdminUserAndGroupAdministratorForm.displayName = 'AdminUserAndGroupAdministratorForm';

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #00000011;
`;
