import {FC, JSX, useMemo} from 'react';
import {styled} from 'styled-components';

import {Markdown} from '@shared/dynamo_model';
import {MarkdownElement, parseMarkdown} from '@shared/lib/hoobiiz/markdown';
import {SanitizedItem} from '@shared/model/search_tables';

import {lighten} from '@shared-frontend/colors';
import {HoobiizMediaView} from '@shared-frontend/components/auth/hoobiiz_media_view';
import {useResponsive} from '@shared-frontend/lib/use_responsive';

import {LIGHT_ACCENT_RATIO} from '@src/components/core/theme_base';

interface MarkdownRendererProps {
  markdown: Markdown;
  logo?: SanitizedItem<'HoobiizMedia'>;
}

function renderElement(
  element: MarkdownElement,
  isMobile: boolean,
  key?: string | number
): JSX.Element | string {
  if (element.type === 'title1') {
    return (
      <Title1 key={key} $isMobile={isMobile}>
        {element.value.map((v, i) => renderElement(v, isMobile, i))}
      </Title1>
    );
  } else if (element.type === 'title2') {
    return (
      <Title2 key={key} $isMobile={isMobile}>
        {element.value.map((v, i) => renderElement(v, isMobile, i))}
      </Title2>
    );
  } else if (element.type === 'bold') {
    return (
      <BoldText key={key}>{element.value.map((v, i) => renderElement(v, isMobile, i))}</BoldText>
    );
  } else if (element.type === 'text') {
    return element.value;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (element.type === 'separator') {
    return <Separator key={key} />;
  }
  return <></>;
}

export const MarkdownRenderer: FC<MarkdownRendererProps> = ({markdown, logo}) => {
  const markdownElements = useMemo(() => parseMarkdown(markdown), [markdown]);
  const {isMobile} = useResponsive();

  let headerWithLogo: JSX.Element | undefined;
  const headerElements: (JSX.Element | string)[] = [];
  const lineElements: (JSX.Element | string)[] = [];
  let headerDetectionFinished = false;
  for (const [i, elements] of markdownElements.entries()) {
    const [firstElement] = elements;
    if (firstElement && firstElement.type !== 'text' && elements.length === 1) {
      if (headerDetectionFinished) {
        lineElements.push(renderElement(firstElement, isMobile, i));
      } else {
        headerElements.push(renderElement(firstElement, isMobile, i));
      }
    } else {
      if (!headerDetectionFinished) {
        headerDetectionFinished = true;
        // Add logo to the left of the header
        if (logo) {
          headerWithLogo = (
            <HeaderWithLogo>
              {/* OuterCircle and InnerCircle are here for padding inside the displayed circle */}
              <OuterCircle>
                <InnerCircle>
                  <HoobiizMediaView media={logo} cover={false} />
                </InnerCircle>
              </OuterCircle>
              <div>{headerElements}</div>
            </HeaderWithLogo>
          );
        }
      }
      lineElements.push(
        <Line key={i} data-key={i}>
          {elements.map((v, i) => renderElement(v, isMobile, i))}
        </Line>
      );
    }
  }

  return (
    <Wrapper>
      {headerWithLogo ?? headerElements}
      {lineElements}
    </Wrapper>
  );
};
MarkdownRenderer.displayName = 'MarkdownRenderer';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  width: 100%;
`;

const Line = styled.div`
  display: inline-block;
  min-height: 24px;
  line-height: 24px;
`;

const Title1 = styled.div<{$isMobile: boolean}>`
  font-size: ${({$isMobile}) => ($isMobile ? '16px' : '24px')};
  line-height: ${({$isMobile}) => ($isMobile ? '20px' : '34px')};
  font-weight: bold;
  margin-bottom: 6px;
`;
const Title2 = styled.div<{$isMobile: boolean}>`
  font-size: 20px;
  line-height: 29px;
  font-size: ${({$isMobile}) => ($isMobile ? '14px' : '20px')};
  line-height: ${({$isMobile}) => ($isMobile ? '18px' : '29px')};
  font-weight: bold;
  margin-bottom: 4px;
`;
const BoldText = styled.span`
  font-weight: bold;
`;
const Separator = styled.div`
  height: 1px;
  background-color: #00000033;
`;

const HeaderWithLogo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;
const OuterCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 12px;
  border: solid 1px ${p => lighten(p.theme.main.accentColor, LIGHT_ACCENT_RATIO)};
`;
const InnerCircle = styled.div`
  border-radius: 50%;
  width: 50px;
  height: 50px;
`;
