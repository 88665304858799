import {HoobiizData} from '@shared/api/definitions/public_api/hoobiiz_api';
import {
  CurrencyAmount,
  HoobiizExpertTicketInfo,
  HoobiizExpertTicketStockItem,
  HoobiizFlexibleStockSchedule,
  HoobiizFlexibleStockScheduleType,
  HoobiizOpeningHours,
  HoobiizStockItem,
  HoobiizStockMode,
  HoobiizStockModeType,
  HoobiizStockReservation,
  HoobiizStockReservationType,
  HoobiizStockWeeklyTemplateItem,
  HoobiizTicketInfo,
  HoobiizTicketInfoOption,
  HoobiizTimeOfDay,
  HoobiizTimePeriod,
} from '@shared/dynamo_model';
import {zip} from '@shared/lib/array_utils';
import {roundCents} from '@shared/lib/hoobiiz/currency_amount';
import {FullItem} from '@shared/model/search_tables';

export function compareTimeOfDay(d1: HoobiizTimeOfDay, d2: HoobiizTimeOfDay): number {
  const start1 = d1.startHour * 60 + d1.startMinute;
  const start2 = d2.startHour * 60 + d2.startMinute;
  if (start1 !== start2) {
    return start1 - start2;
  }
  const end1 = d1.endHour * 60 + d1.endMinute;
  const end2 = d2.endHour * 60 + d2.endMinute;
  return end1 - end2;
}

export function stockHasChanged(
  s1: HoobiizData<HoobiizStockItem>,
  s2: FullItem<'HoobiizStock'>
): boolean {
  return (
    s1.activityId !== s2.activityId ||
    s1.batchId !== s2.batchId ||
    modeHasChanged(s1.mode, s2.mode) ||
    s1.quantity !== s2.quantity ||
    reservationHasChanged(s1.reservation, s2.reservation) ||
    ticketInfoArrayHasChanged(s1.ticketInfo, s2.ticketInfo) ||
    s1.visibility !== s2.visibility ||
    s1.terms !== s2.terms
  );
}

export function expertTicketStockHasChanged(
  s1: HoobiizData<HoobiizExpertTicketStockItem>,
  s2: FullItem<'HoobiizExpertTicketStock'>
): boolean {
  return (
    s1.activityId !== s2.activityId || expertTicketInfoArrayHasChanged(s1.ticketInfo, s2.ticketInfo)
  );
}

export function templateHasChanged(
  s1: HoobiizData<HoobiizStockWeeklyTemplateItem>,
  s2: FullItem<'HoobiizStockWeeklyTemplate'>
): boolean {
  return (
    s1.activityId !== s2.activityId ||
    s1.batchId !== s2.batchId ||
    modeHasChanged(s1.mode, s2.mode) ||
    timePeriodHasChanged(s1.period, s2.period) ||
    s1.quantity !== s2.quantity ||
    ticketInfoArrayHasChanged(s1.ticketInfo, s2.ticketInfo) ||
    timeOfDayHasChanged(s1.timeOfDay, s2.timeOfDay) ||
    s1.weekday !== s2.weekday ||
    s1.visibility !== s2.visibility ||
    s1.terms !== s2.terms
  );
}

export function modeHasChanged(m1: HoobiizStockMode, m2: HoobiizStockMode): boolean {
  if (m1.type !== m2.type) {
    return true;
  }
  if (
    m1.type === HoobiizStockModeType.AdminConfirm &&
    m2.type === HoobiizStockModeType.AdminConfirm
  ) {
    return false;
  }
  if (m1.type === HoobiizStockModeType.Automatic && m2.type === HoobiizStockModeType.Automatic) {
    return m1.sendEmail !== m2.sendEmail;
  }
  if (
    m1.type === HoobiizStockModeType.Pregenerated &&
    m2.type === HoobiizStockModeType.Pregenerated
  ) {
    return m1.unlimited !== m2.unlimited;
  }
  if (
    m1.type === HoobiizStockModeType.VendorConfirm &&
    m2.type === HoobiizStockModeType.VendorConfirm
  ) {
    return m1.email !== m2.email;
  }
  throw new Error(`Unhandled types ${m1.type} and ${m2.type}`);
}

export function reservationHasChanged(
  m1: HoobiizStockReservation,
  m2: HoobiizStockReservation
): boolean {
  if (m1.type !== m2.type) {
    return true;
  }
  if (
    m1.type === HoobiizStockReservationType.Fixed &&
    m2.type === HoobiizStockReservationType.Fixed
  ) {
    return timePeriodHasChanged(m1.period, m2.period);
  }
  if (
    m1.type === HoobiizStockReservationType.Flexible &&
    m2.type === HoobiizStockReservationType.Flexible
  ) {
    if (timePeriodHasChanged(m1.period, m2.period)) {
      return true;
    }
    return flexibleScheduleHasChanged(m1.schedule, m2.schedule);
  }
  throw new Error(`Unhandled HoobiizStockReservationType ${m1.type} and ${m2.type}`);
}

export function timePeriodHasChanged(p1: HoobiizTimePeriod, p2: HoobiizTimePeriod): boolean {
  return p1.startTs !== p2.startTs || p1.endTs !== p2.endTs;
}

export function ticketInfoArrayHasChanged(
  t1: HoobiizTicketInfo[],
  t2: HoobiizTicketInfo[]
): boolean {
  if (t1.length !== t2.length) {
    return true;
  }
  for (const [tt1, tt2] of zip(t1, t2)) {
    if (ticketInfoHasChanged(tt1, tt2)) {
      return true;
    }
  }
  return false;
}

export function expertTicketInfoArrayHasChanged(
  t1: HoobiizExpertTicketInfo[],
  t2: HoobiizExpertTicketInfo[]
): boolean {
  if (t1.length !== t2.length) {
    return true;
  }
  for (const [tt1, tt2] of zip(t1, t2)) {
    if (expertTicketInfoHasChanged(tt1, tt2)) {
      return true;
    }
  }
  return false;
}

type IsEmptyObject<T> = keyof T extends never ? true : false;

export function ticketInfoHasChanged(t1: HoobiizTicketInfo, t2: HoobiizTicketInfo): boolean {
  const {
    id,
    span,
    buyingPrice,
    publicPrice,
    youpiizPrice,
    fees,
    label,
    description,
    minQuantity,
    maxQuantity,
    visibility,
    options,
    ...rest
  } = t1;
  // The type IsEmptyObject<typeof rest> should be true.
  // If it is not, this mean there are some properties left in the `rest` variable.
  // In this case we are not checking those extra properties when comparing the ticket info.
  const typecheck: IsEmptyObject<typeof rest> = true; // eslint-disable-line @typescript-eslint/no-unused-vars
  return (
    id !== t2.id ||
    span !== t2.span ||
    currencyAmountHasChanged(buyingPrice, t2.buyingPrice) ||
    currencyAmountHasChanged(publicPrice, t2.publicPrice) ||
    currencyAmountHasChanged(youpiizPrice, t2.youpiizPrice) ||
    currencyAmountHasChanged(fees, t2.fees) ||
    label !== t2.label ||
    description !== t2.description ||
    minQuantity !== t2.minQuantity ||
    maxQuantity !== t2.maxQuantity ||
    visibility !== t2.visibility ||
    ticketInfoOptionArrayHasChanged(options ?? [], t2.options ?? [])
  );
}
export function expertTicketInfoHasChanged(
  t1: HoobiizExpertTicketInfo,
  t2: HoobiizExpertTicketInfo
): boolean {
  const {id, label, description, productId, ...rest} = t1;
  // The type IsEmptyObject<typeof rest> should be true.
  // If it is not, this mean there are some properties left in the `rest` variable.
  // In this case we are not checking those extra properties when comparing the ticket info.
  const typecheck: IsEmptyObject<typeof rest> = true; // eslint-disable-line @typescript-eslint/no-unused-vars
  return (
    id !== t2.id ||
    label !== t2.label ||
    description !== t2.description ||
    productId !== t2.productId
  );
}

export function ticketInfoOptionArrayHasChanged(
  t1: HoobiizTicketInfoOption[],
  t2: HoobiizTicketInfoOption[]
): boolean {
  if (t1.length !== t2.length) {
    return true;
  }
  const t1Sorted = [...t1].sort((a, b) => a.id.localeCompare(b.id));
  const t2Sorted = [...t2].sort((a, b) => a.id.localeCompare(b.id));
  for (const [tt1, tt2] of zip(t1Sorted, t2Sorted)) {
    if (ticketInfoOptionHasChanged(tt1, tt2)) {
      return true;
    }
  }
  return false;
}

export function ticketInfoOptionHasChanged(
  t1: HoobiizTicketInfoOption,
  t2: HoobiizTicketInfoOption
): boolean {
  const {
    id,
    buyingPrice,
    publicPrice,
    youpiizPrice,
    label,
    description,
    minQuantity,
    maxQuantity,
    ...rest
  } = t1;
  // The type IsEmptyObject<typeof rest> should be true.
  // If it is not, this mean there are some properties left in the `rest` variable.
  // In this case we are not checking those extra properties when comparing the ticket info.
  const typecheck: IsEmptyObject<typeof rest> = true; // eslint-disable-line @typescript-eslint/no-unused-vars
  return (
    id !== t2.id ||
    currencyAmountHasChanged(buyingPrice, t2.buyingPrice) ||
    currencyAmountHasChanged(publicPrice, t2.publicPrice) ||
    currencyAmountHasChanged(youpiizPrice, t2.youpiizPrice) ||
    label !== t2.label ||
    description !== t2.description ||
    minQuantity !== t2.minQuantity ||
    maxQuantity !== t2.maxQuantity
  );
}

export function currencyAmountHasChanged(
  c1: CurrencyAmount | undefined,
  c2: CurrencyAmount | undefined
): boolean {
  if (!c1 && !c2) {
    // both undefined
    return false;
  }
  if (!c1 || !c2) {
    // one undefined and not the other
    return true;
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return c1.currency !== c2.currency || roundCents(c1.cents) !== roundCents(c2.cents);
}

export function flexibleScheduleHasChanged(
  s1: HoobiizFlexibleStockSchedule,
  s2: HoobiizFlexibleStockSchedule
): boolean {
  if (s1.type !== s2.type) {
    return true;
  }
  if (
    s1.type === HoobiizFlexibleStockScheduleType.Inherit &&
    s2.type === HoobiizFlexibleStockScheduleType.Inherit
  ) {
    return false;
  }
  if (
    s1.type === HoobiizFlexibleStockScheduleType.Override &&
    s2.type === HoobiizFlexibleStockScheduleType.Override
  ) {
    return openingHoursHasChanged(s1.schedule, s2.schedule);
  }
  throw new Error(`Unhandled HoobiizFlexibleStockScheduleType ${s1.type} and ${s2.type}`);
}

export function openingHoursHasChanged(o1: HoobiizOpeningHours, o2: HoobiizOpeningHours): boolean {
  return (
    timeOfDayArrayHasChanged(o1.weekdays.Monday, o2.weekdays.Monday) ||
    timeOfDayArrayHasChanged(o1.weekdays.Tuesday, o2.weekdays.Tuesday) ||
    timeOfDayArrayHasChanged(o1.weekdays.Wednesday, o2.weekdays.Wednesday) ||
    timeOfDayArrayHasChanged(o1.weekdays.Thursday, o2.weekdays.Thursday) ||
    timeOfDayArrayHasChanged(o1.weekdays.Friday, o2.weekdays.Friday) ||
    timeOfDayArrayHasChanged(o1.weekdays.Saturday, o2.weekdays.Saturday) ||
    timeOfDayArrayHasChanged(o1.weekdays.Sunday, o2.weekdays.Sunday)
  );
}

export function timeOfDayArrayHasChanged(o1: HoobiizTimeOfDay[], o2: HoobiizTimeOfDay[]): boolean {
  if (o1.length !== o2.length) {
    return true;
  }
  const o1Sorted = [...o1].sort(compareTimeOfDay);
  const o2Sorted = [...o2].sort(compareTimeOfDay);
  for (const [oo1, oo2] of zip(o1Sorted, o2Sorted)) {
    if (timeOfDayHasChanged(oo1, oo2)) {
      return true;
    }
  }
  return false;
}

export function timeOfDayHasChanged(o1: HoobiizTimeOfDay, o2: HoobiizTimeOfDay): boolean {
  return (
    o1.startHour !== o2.startHour ||
    o1.startMinute !== o2.startMinute ||
    o1.endHour !== o2.endHour ||
    o1.endMinute !== o2.endMinute
  );
}
