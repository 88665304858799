import styled from 'styled-components';

export const Wrapper = styled.div<{$isMobile: boolean}>`
  width: 100%;
  padding: ${({$isMobile}) => ($isMobile ? '12px' : '32px')};
`;

export const ColumnWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  gap: ${({$isMobile}) => ($isMobile ? '16px' : '32px')};
`;
