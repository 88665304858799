import {FC, useEffect, useState} from 'react';

import {
  HoobiizOpeningHours,
  HoobiizStockId,
  HoobiizStockModePregenerated,
  HoobiizStockModeType,
  HoobiizStockReservationFlexible,
} from '@shared/dynamo_model';
import {hoobiizTicketInfoId} from '@shared/lib/hoobiiz/hoobiiz_ids';
import {removeUndefined} from '@shared/lib/type_utils';

import {StockHint} from '@src/components/admin/activity_stock/activity_stock_form';
import {HoobiizStockModePregeneratedForm} from '@src/components/admin/activity_stock/hoobiiz_stock_mode_pregenerated_form';
import {HoobiizStockReservationFlexibleForm} from '@src/components/admin/activity_stock/hoobiiz_stock_reservation_flexible_form';
import {HoobiizStockTermsForm} from '@src/components/admin/activity_stock/hoobiiz_stock_terms_form';
import {MaybeTicket} from '@src/components/admin/activity_stock/hoobiiz_ticket_info_form';
import {HoobiizTicketInfoMultiForm} from '@src/components/admin/activity_stock/hoobiiz_ticket_info_multi_form';
import {FormColumnFull, FormWrapper} from '@src/components/admin/form/form_fragments';
import {FormSection} from '@src/components/admin/form/form_section';
import {HoobiizMessage} from '@src/components/admin/form/hoobiiz_message';

export interface FlexibleAndPregeneratedTicket {
  id?: HoobiizStockId;
  ticket: {
    reservation: HoobiizStockReservationFlexible;
    mode: HoobiizStockModePregenerated;
    quantity: 0; // always 0 for Pregenerated tickets
    ticketInfo: MaybeTicket[];
    terms: string;
  };
  stockHint?: StockHint;
}

interface ActivityStockFlexibleAndPregeneratedFormProps {
  vendorHours: HoobiizOpeningHours;
  initial?: FlexibleAndPregeneratedTicket;
  onChange: (ticket: FlexibleAndPregeneratedTicket) => void;
}

export const ActivityStockFlexibleAndPregeneratedForm: FC<
  ActivityStockFlexibleAndPregeneratedFormProps
> = props => {
  const {vendorHours, initial, onChange} = props;

  const [reservation, setReservation] = useState(initial?.ticket.reservation);
  const [ticketInfo, setTicketInfo] = useState<MaybeTicket[]>(
    initial?.ticket.ticketInfo ?? [{id: hoobiizTicketInfoId()}]
  );
  const [terms, setTerms] = useState<string>(initial?.ticket.terms ?? '');
  const [mode, setMode] = useState<HoobiizStockModePregenerated>(
    initial?.ticket.mode ?? {type: HoobiizStockModeType.Pregenerated, unlimited: true}
  );

  useEffect(() => {
    if (!reservation) {
      return;
    }
    onChange({
      id: initial?.id,
      ticket: {
        reservation,
        mode,
        quantity: 0, // always 0 for Pregenerated tickets
        ticketInfo: removeUndefined(ticketInfo),
        terms,
      },
      stockHint: initial?.stockHint,
    });
  }, [initial?.id, initial?.stockHint, mode, onChange, reservation, terms, ticketInfo]);

  return (
    <FormWrapper>
      <HoobiizStockTermsForm terms={terms} onChange={setTerms} />
      <HoobiizTicketInfoMultiForm initialData={ticketInfo} onChange={setTicketInfo} />
      <FormColumnFull>
        <HoobiizStockReservationFlexibleForm
          vendorHours={vendorHours}
          initialData={reservation}
          onChange={setReservation}
        />
      </FormColumnFull>
      <FormSection title="Lignes de stock">
        <FormColumnFull>
          <HoobiizStockModePregeneratedForm initialMode={mode} onChange={setMode} />
          {initial?.id === undefined ? (
            <HoobiizMessage
              messages={['Vous pourrez ajouter des billets une fois le stock créé']}
              marginTop={8}
            />
          ) : (
            <></>
          )}
        </FormColumnFull>
      </FormSection>
    </FormWrapper>
  );
};
ActivityStockFlexibleAndPregeneratedForm.displayName = 'ActivityStockFlexibleAndPregeneratedForm';
