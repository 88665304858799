import {FC} from 'react';
import styled, {useTheme} from 'styled-components';

import {EmailString, FrontendUserDataContent} from '@shared/dynamo_model';

import {FrontendUserDataForm} from '@shared-frontend/components/auth/frontend_user_data_form';
import {ModeDefaultTemplate} from '@shared-frontend/components/auth/mode_default/theme_0_template';
import {Button, NavLink} from '@shared-frontend/components/core/button';
import {Input, InputProps} from '@shared-frontend/components/core/input_v2';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {ComponentClass} from '@shared-frontend/lib/react';
import {optionalPx} from '@shared-frontend/lib/styled_utils';

interface RegisterModeDefaultProps {
  wrapper: ComponentClass | ComponentClass[] | undefined;
  confirmationEmailSent: boolean;
  emailInputProps: InputProps<EmailString | undefined>;
  passwordInputProps: InputProps<string | undefined>;
  userData?: Partial<FrontendUserDataContent>;
  setUserData: (userData: Partial<FrontendUserDataContent> | undefined) => void;
  handleRegisterSubmit: () => Promise<void>;
}

export const RegisterPageModeDefault: FC<RegisterModeDefaultProps> = props => {
  const {
    wrapper,
    emailInputProps,
    passwordInputProps,
    handleRegisterSubmit,
    confirmationEmailSent,
    userData,
    setUserData,
  } = props;
  const {
    auth: {userDataContentType},
  } = useTheme();

  return (
    <ModeDefaultTemplate wrapper={wrapper}>
      <Title>Inscription</Title>
      <Subtitle>Créer votre compte</Subtitle>
      <Separator $top={22} $bottom={18} />
      {confirmationEmailSent ? (
        `Un email de confirmation vous a été envoyé à l'email ${emailInputProps.value}. Vous pouvez fermer cette page.`
      ) : (
        <>
          <FormFields>
            <FormInput>
              <Input {...emailInputProps} />
            </FormInput>
            <FormInput>
              <Input {...passwordInputProps} />
            </FormInput>
            <FrontendUserDataForm
              userDataContentType={userDataContentType}
              data={userData}
              onChange={setUserData}
            />
          </FormFields>
          <Spacing height={28} />
          <LegalText>
            En continuant, vous reconnaissez avoir lu les{' '}
            <NavLink to="/terms">mentions légales</NavLink>
          </LegalText>
          <Spacing height={28} />
          <Button expand type="button" submit onClickAsync={handleRegisterSubmit}>
            Créer mon compte
          </Button>
          <BottomLinks>
            <StyledNavLink to="/login">J'ai déjà un compte</StyledNavLink>
          </BottomLinks>
        </>
      )}
    </ModeDefaultTemplate>
  );
};

RegisterPageModeDefault.displayName = 'RegisterPageModeDefault';

const Title = styled.h3`
  font-weight: 700;
  font-size: 160%;
`;

const Subtitle = styled.p`
  opacity: 0.6;
  font-weight: 400;
`;

const FormInput = styled.div`
  width: 100%;
  text-align: left;
`;

const Separator = styled.div<{$top?: number; $bottom?: number}>`
  height: 1px;
  width: 100%;
  background-color: ${p => p.theme.main.textColor}20;
  ${p => optionalPx('margin-top', p.$top)}
  ${p => optionalPx('margin-bottom', p.$bottom)}
`;

const LegalText = styled.div`
  width: 100%;
  text-align: left;
  font-size: 80%;
`;

const BottomLinks = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledNavLink = styled(NavLink)``;

const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  text-align: left;
`;
