import {FC, ReactNode, useCallback} from 'react';
import styled from 'styled-components';

import {
  HoobiizStockEntryAttachmentStatus,
  SanitizedHoobiizOrderItemItem,
} from '@shared/api/definitions/public_api/hoobiiz_api';
import {
  CurrencyAmount,
  HoobiizCartItemId,
  HoobiizOrderItemItem,
  HoobiizOrderItemTicketInfo,
  HoobiizOrderStatus,
  HoobiizPrices,
} from '@shared/dynamo_model';
import {roundCents} from '@shared/lib/hoobiiz/currency_amount';
import {baseHoobiizPrices} from '@shared/lib/hoobiiz/hoobiiz_prices';
import {SanitizedItem} from '@shared/model/search_tables';

import {HoobiizMediaView} from '@shared-frontend/components/auth/hoobiiz_media_view';
import {UnthemedNavLink} from '@shared-frontend/components/core/button';
import {useResponsive} from '@shared-frontend/lib/use_responsive';

import {SmallAlertButton} from '@src/components/core/theme';
import {DocumentLink} from '@src/components/ui/document_link';
import {HoobiizOrderItemStatus} from '@src/components/ui/hoobiiz_order_item_status';
import {HoobiizPricesView} from '@src/components/ui/hoobiiz_prices';
import {HoobiizTicketLabel} from '@src/components/ui/hoobiiz_ticket_label';
import {getActivityUrl} from '@src/lib/hoobiiz_urls';

interface HoobiizTicketItemPropsBase {
  activity: SanitizedItem<'HoobiizActivity'>;
  quantity: number;
  total: HoobiizPrices;
  fees: CurrencyAmount;
  ticketInfo: HoobiizOrderItemTicketInfo;
  ticketInfoTotal: HoobiizPrices;
  options: HoobiizOrderItemItem['options'][0][];
  validityDateStr: string;
}

type HoobiizTicketItemExtraProps =
  | {
      cartInfo: {
        id: HoobiizCartItemId;
        onDeleteClick: (cartItemId: HoobiizCartItemId) => Promise<void>;
      };
    }
  | {
      orderInfo: {
        attachments: SanitizedHoobiizOrderItemItem['attachments'];
        status: HoobiizOrderStatus;
      };
    };

type HoobiizTicketItemProps = HoobiizTicketItemPropsBase & HoobiizTicketItemExtraProps;

export const HoobiizTicketItem: FC<HoobiizTicketItemProps> = ({
  activity,
  quantity,
  total,
  ticketInfo,
  ticketInfoTotal,
  options,
  fees,
  validityDateStr,
  ...extra
}) => {
  const ticketTitle = `${quantity > 1 ? `x${quantity} ` : ''}${ticketInfo.label}`;
  const {isMobile, isDesktop} = useResponsive();

  const handleDeleteClick = useCallback(async () => {
    if ('cartInfo' in extra) {
      const {id, onDeleteClick} = extra.cartInfo;
      await onDeleteClick(id);
    }
  }, [extra]);

  let statusElement = <></>;
  const attachmentsElements: ReactNode[] = [];
  let attachmentLoadingMessage = <></>;
  let deleteButtonElement = <></>;

  if ('cartInfo' in extra) {
    deleteButtonElement = (
      <SmallAlertButton onClickAsync={handleDeleteClick}>Supprimer</SmallAlertButton>
    );
  } else {
    const {orderInfo} = extra;

    const {attachments, status} = orderInfo;
    statusElement = <StyledHoobiizOrderItemStatus status={status} />;
    for (const attachment of attachments) {
      if (attachment.status === HoobiizStockEntryAttachmentStatus.Ready) {
        attachmentsElements.push(
          attachment.urls?.map(url => <DocumentLink key={url} url={url} />) ?? []
        );
      }
    }
    attachmentLoadingMessage =
      status === HoobiizOrderStatus.Success &&
      (attachments.length === 0 ||
        attachments.some(a => a.status !== HoobiizStockEntryAttachmentStatus.Ready)) ? (
        <DocumentLoading>Création de vos billets en cours...</DocumentLoading>
      ) : (
        <></>
      );
  }

  return (
    <ItemWrapper $isMobile={isMobile} $isDesktop={isDesktop}>
      {statusElement}
      <div>
        <ActivityTitleLink to={getActivityUrl(activity)}>{activity.label}</ActivityTitleLink>
        <ValidityDate>{validityDateStr}</ValidityDate>
      </div>
      <ItemInfo $isMobile={isMobile}>
        <StyledHoobiizMediaView media={activity.media[0]} width={128} height={128} />
        <Right $isMobile={isMobile}>
          <RightTop>
            <ItemTitle>
              <TicketTitle>
                <TicketTitleLink to={getActivityUrl(activity)}>
                  <HoobiizTicketLabel label={ticketTitle} description={ticketInfo.description} />
                </TicketTitleLink>
                <HoobiizPricesView prices={ticketInfoTotal} justify="flex-end" />
              </TicketTitle>
              {options.map(({quantity, total, option: {id, label, description}}) => {
                const optionTitle = ` ${quantity > 1 ? `x${quantity} ` : ''}${label}`;
                return (
                  <OptionTitle key={id}>
                    <HoobiizTicketLabel label={optionTitle} description={description} />
                    <HoobiizPricesView prices={total} justify="flex-end" />
                  </OptionTitle>
                );
              })}
            </ItemTitle>
            {roundCents(fees.cents) > 0 ? (
              <FeeTitle>
                <FeeTitleText>Frais de gestion</FeeTitleText>
                <HoobiizPricesView prices={baseHoobiizPrices(fees)} justify="flex-end" />
              </FeeTitle>
            ) : (
              <></>
            )}
            {roundCents(fees.cents) > 0 || options.length > 0 ? (
              <TotalTitle>
                <div>Total</div>
                <HoobiizPricesView prices={total} justify="flex-end" />
              </TotalTitle>
            ) : (
              <></>
            )}
          </RightTop>
          {deleteButtonElement}
          {attachmentLoadingMessage}
          {attachmentsElements}
        </Right>
      </ItemInfo>
    </ItemWrapper>
  );
};

HoobiizTicketItem.displayName = 'HoobiizTicketItem';

const StyledHoobiizMediaView = styled(HoobiizMediaView)`
  flex-shrink: 0;
  border-radius: 8px;
  overflow: hidden;
`;

const ItemWrapper = styled.div<{$isMobile: boolean; $isDesktop: boolean}>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: ${({$isDesktop}) => (!$isDesktop ? '100%' : '800px')};
  border: solid 1px #bcbcbc;
  padding: ${({$isMobile}) => ($isMobile ? '8px 12px 0px 12px' : '16px 24px 8px 24px')};
  border-radius: 8px;
  overflow: hidden;
`;

const ItemTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActivityTitleLink = styled(UnthemedNavLink)`
  color: #333333;
  font-size: 24px;
  line-height: 150%;
  &:hover {
    text-decoration: underline;
  }
`;

const ValidityDate = styled.div`
  color: #555555;
  line-height: 150%;
`;

const BaseTitle = styled.div`
  color: #555555;
  line-height: 150%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

const TicketTitle = styled(BaseTitle)`
  font-size: 20px;
`;

const TicketTitleLink = styled(UnthemedNavLink)`
  color: #000000;
  &:hover {
    text-decoration: underline;
  }
`;

const OptionTitle = styled(BaseTitle)`
  font-size: 18px;
`;

const FeeTitle = styled(BaseTitle)`
  font-size: 15px;
`;
const FeeTitleText = styled.div`
  font-style: italic;
`;

const TotalTitle = styled(BaseTitle)`
  color: #333333;
  font-size: 24px;
`;

const ItemInfo = styled.div<{$isMobile: boolean}>`
  display: flex;
  flex-wrap: ${({$isMobile}) => ($isMobile ? 'wrap' : 'inherit')};
  margin-bottom: 16px;
  gap: 22px;
`;

const Right = styled.div<{$isMobile: boolean}>`
  width: 1px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: ${({$isMobile}) => ($isMobile ? '270px' : 'auto')};
`;

const RightTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const DocumentLoading = styled.div`
  color: #999;
  font-style: italic;
  font-size: 16px;
  font-weight: 500;
`;

const StyledHoobiizOrderItemStatus = styled(HoobiizOrderItemStatus)`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0;
  border-bottom-left-radius: 8px;
`;
