import {FC} from 'react';

import {Textarea} from '@shared-frontend/components/core/textarea_v2';

import {FormColumnFull} from '@src/components/admin/form/form_fragments';
import {TermsHelpLabel} from '@src/components/admin/form/terms_help_label';
import {adminInputTheme} from '@src/components/core/theme';

interface HoobiizStockTermsFormProps {
  terms: string;
  onChange?: (terms: string) => void;
}

export const HoobiizStockTermsForm: FC<HoobiizStockTermsFormProps> = props => {
  const {terms, onChange} = props;

  return (
    <FormColumnFull>
      <Textarea
        width="100%"
        height={256}
        value={terms}
        syncState={onChange}
        placeholder={terms}
        label={<TermsHelpLabel title="CONDITIONS D'UTILISATION" />}
        overrides={adminInputTheme}
      />
    </FormColumnFull>
  );
};
HoobiizStockTermsForm.displayName = 'HoobiizStockTermsForm';
