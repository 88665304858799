import {FC} from 'react';

import {HoobiizTicketFileId} from '@shared/dynamo_model';

import {useDocumentUrl} from '@src/components/admin/document_urls_store';
import {DocumentLink} from '@src/components/ui/document_link';

export const DocumentLinkFromFile: FC<{fileId: HoobiizTicketFileId}> = ({fileId}) => {
  const url = useDocumentUrl(fileId);

  return url === undefined ? <div>Chargement du lien...</div> : <DocumentLink url={url} />;
};
DocumentLinkFromFile.displayName = 'DocumentLinkFromFile';
