import {useEffect} from 'react';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizTicketFileId} from '@shared/dynamo_model';

import {apiCall} from '@shared-frontend/api';
import {createMapStore} from '@shared-frontend/lib/map_data_store';

const documentUrlsStore = createMapStore<HoobiizTicketFileId, string>();

export function useDocumentUrl(fileId: HoobiizTicketFileId): string | undefined {
  const url = documentUrlsStore.useData(fileId);

  useEffect(() => {
    if (url === undefined) {
      fetchNewDocumentUrl(fileId);
    }
  }, [url, fileId]);

  return url;
}

const inProgressFileIds = new Set<HoobiizTicketFileId>();
const queuedFilesIds = new Set<HoobiizTicketFileId>();

function fetchNewDocumentUrl(fileId: HoobiizTicketFileId): void {
  // Already in store
  if (documentUrlsStore.getData(fileId) !== undefined) {
    return;
  }

  // Already in progress
  const alreadyProcessedFileIds = new Set([...inProgressFileIds, ...queuedFilesIds]);
  if (alreadyProcessedFileIds.has(fileId)) {
    return;
  }

  queuedFilesIds.add(fileId);
}

const FETCH_INTERVAL_MS = 500;

export function startDocumentUrlStore(): void {
  setInterval(() => {
    if (inProgressFileIds.size === 0 && queuedFilesIds.size > 0) {
      for (const fileId of queuedFilesIds.values()) {
        inProgressFileIds.add(fileId);
      }
      queuedFilesIds.clear();
      apiCall(HoobiizApi, '/admin/get-files', {ids: [...inProgressFileIds]})
        .then(({files}) => {
          documentUrlsStore.batchSetData(
            files.map(({id, documentUrl}) => ({key: id, value: documentUrl}))
          );
          inProgressFileIds.clear();
        })
        .catch(() => {
          for (const fileId of inProgressFileIds.values()) {
            queuedFilesIds.add(fileId);
          }
          inProgressFileIds.clear();
        });
    }
  }, FETCH_INTERVAL_MS);
}
