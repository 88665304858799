import {FC, useMemo} from 'react';
import styled from 'styled-components';

import {EmailString} from '@shared/dynamo_model';
import {neverHappens} from '@shared/lib/type_utils';

import {ForgotPasswordFormMode} from '@shared-frontend/components/auth/base/forgot_password_page';
import {
  ModeHeroAuthWhiteLabeling,
  modeHeroButtonOverrides,
  modeHeroInputOverrides,
  modeHeroNavLinkOverrides,
  ModeHeroTemplate,
} from '@shared-frontend/components/auth/mode_hero/mode_hero_template';
import {Button, NavLink} from '@shared-frontend/components/core/button';
import {Input} from '@shared-frontend/components/core/input_v2';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {optionalPx} from '@shared-frontend/lib/styled_utils';

interface ForgotPasswordPageModeHeroProps {
  whiteLabeling: ModeHeroAuthWhiteLabeling;
  email: EmailString;
  setEmail: (email: EmailString) => void;
  formMode: ForgotPasswordFormMode;
  handleEmailSubmit: () => void;
  isSubmitting: boolean;
}

export const ForgotPasswordPageModeHero: FC<ForgotPasswordPageModeHeroProps> = props => {
  const {whiteLabeling, email, setEmail, formMode, handleEmailSubmit, isSubmitting} = props;

  const {inputOverrides, buttonOverrides, navLinkOverrides} = useMemo(() => {
    return {
      inputOverrides: modeHeroInputOverrides(whiteLabeling),
      buttonOverrides: modeHeroButtonOverrides(whiteLabeling),
      navLinkOverrides: modeHeroNavLinkOverrides(whiteLabeling),
    };
  }, [whiteLabeling]);

  let formContent = <></>;
  if (formMode === 'initial') {
    formContent = (
      <>
        <Title>Mot de passe oublié ?</Title>
        <Subtitle>Réinitialisez votre mot de passe</Subtitle>
        <Separator $top={22} $bottom={18} />
        <FormInput>
          <Input
            width="100%"
            type="email"
            value={email}
            syncState={setEmail}
            placeholder="jean@exemple.com"
            label="ADRESSE EMAIL"
            autoComplete="email"
            autoFocus
            overrides={inputOverrides}
          />
        </FormInput>
        <Spacing height={28} />
        <LegalText>
          En continuant, vous reconnaissez avoir lu les{' '}
          <NavLink to="/terms" overrides={navLinkOverrides}>
            mentions légales
          </NavLink>
        </LegalText>
        <Spacing height={28} />
        <Button expand submit disabled={isSubmitting} overrides={buttonOverrides}>
          Recevoir un lien par email
        </Button>
      </>
    );
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (formMode === 'finish') {
    formContent = (
      <>
        <Title>Lien envoyé</Title>
        <Spacing height={24} />
        <Message>
          Nous vous avons envoyé un email contenant un lien. Cliquez sur le lien pour modifier votre
          mot de passe. Vous pouvez fermer cette page.
        </Message>
      </>
    );
  } else {
    neverHappens(formMode);
  }

  return (
    <ModeHeroTemplate whiteLabeling={whiteLabeling}>
      <Form onSubmit={handleEmailSubmit}>{formContent}</Form>
    </ModeHeroTemplate>
  );
};

ForgotPasswordPageModeHero.displayName = 'ForgotPasswordPageModeHero';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  line-height: normal;
  text-align: center;
`;

const Title = styled.h3`
  font-weight: 700;
  font-size: 160%;
  color: ${p => p.theme.main.accentTextColor};
`;

const Subtitle = styled.p`
  opacity: 0.6;
  font-weight: 400;
  color: ${p => p.theme.main.accentTextColor};
`;

const Message = styled.p`
  font-weight: 400;
  color: ${p => p.theme.main.accentTextColor};
`;

const FormInput = styled.div`
  width: 100%;
  text-align: left;
`;

const Separator = styled.div<{$top?: number; $bottom?: number}>`
  height: 1px;
  width: 100%;
  background-color: ${p => p.theme.main.textColor}20;
  ${p => optionalPx('margin-top', p.$top)}
  ${p => optionalPx('margin-bottom', p.$bottom)}
`;

const LegalText = styled.div`
  width: 100%;
  text-align: left;
  font-size: 80%;
`;
