import {FC, useEffect, useRef} from 'react';
import styled from 'styled-components';

import {
  HoobiizApi,
  HoobiizStockEntryAttachmentStatus,
} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizOrderStatus, StripePaymentIntentId} from '@shared/dynamo_model';
import {arrayJoin} from '@shared/lib/array_utils';

import {useApiCall} from '@shared-frontend/lib/use_api_call';
import {useResponsive} from '@shared-frontend/lib/use_responsive';
import {useParams} from '@shared-frontend/lib/use_typed_params';

import {Wrapper} from '@src/components/core/scaffolding';
import {HoobiizOrderItem} from '@src/components/ui/hoobiiz_order_item';
import {Total} from '@src/components/ui/total';
import {computeOrdersTotal} from '@src/lib/currency_amount';

const MIN_REFRESH_RATE_MS = 100;
const MAX_REFRESH_RATE_MS = 5000;

export const OrderPage: FC = () => {
  const {id = ''} = useParams();
  const paymentId = id as StripePaymentIntentId;
  const {isMobile, isDesktop} = useResponsive();

  const {data: orders, refreshData: refreshPayment} = useApiCall(
    HoobiizApi,
    '/get-purchase',
    {paymentId},
    {errMsg: `Échec du chargement de la commande. Vous pouvez rafraichir la page pour réessayer`}
  );

  const refreshRate = useRef(MIN_REFRESH_RATE_MS);
  useEffect(() => {
    const hasPendingOrder =
      orders?.find(o => o.status === HoobiizOrderStatus.Pending) !== undefined;
    const hasOrderWithoutDocuments =
      orders?.find(
        o =>
          o.attachments.some(a => a.status !== HoobiizStockEntryAttachmentStatus.Ready) ||
          (o.status === HoobiizOrderStatus.Success && o.attachments.length === 0)
      ) !== undefined;
    if (hasPendingOrder || hasOrderWithoutDocuments) {
      setTimeout(() => {
        refreshPayment().catch(() => {});
      }, refreshRate.current);
      refreshRate.current = Math.min(MAX_REFRESH_RATE_MS, refreshRate.current * 2);
    }
  }, [orders, refreshPayment]);

  if (!orders) {
    return <Wrapper $isMobile={isMobile}>Chargement...</Wrapper>;
  }

  const [firstOrder] = orders;

  if (!firstOrder) {
    return <Wrapper $isMobile={isMobile}>Commande vide...</Wrapper>;
  }

  if (firstOrder.status === HoobiizOrderStatus.Pending) {
    return (
      <PendingWrapper $isMobile={isMobile}>
        <Title>Paiement accepté, nous traitons votre commande.</Title>
      </PendingWrapper>
    );
  }

  const ordersTotal = computeOrdersTotal(orders);

  return (
    <Wrapper $isMobile={isMobile}>
      <Title>Votre commande</Title>
      <Section $isMobile={isMobile} $isDesktop={isDesktop}>
        <ListWrapper>
          {arrayJoin(
            orders.map(order => <HoobiizOrderItem key={order.id} orderItem={order} />),
            i => (
              <Separator key={i} />
            )
          )}
        </ListWrapper>
        <Total itemCount={orders.length} total={ordersTotal.total} fees={ordersTotal.fees} />
      </Section>
    </Wrapper>
  );
};

OrderPage.displayName = 'OrderPage';

const PendingWrapper = styled.div<{$isMobile: boolean}>`
  width: 100%;
  padding: ${({$isMobile}) => ($isMobile ? '12px' : '32px')};
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 32px;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Separator = styled.div`
  height: 1px;
  background-color: #dddddd;
`;

const Section = styled.div<{$isDesktop: boolean; $isMobile: boolean}>`
  display: flex;
  flex-direction: ${({$isDesktop}) => (!$isDesktop ? 'column-reverse' : 'row')};
  gap: 32px;
  align-items: ${({$isDesktop}) => (!$isDesktop ? 'stretch' : 'flex-start')};
  & > *:first-child {
    flex-grow: 1;
  }
  & > *:nth-child(2) {
    flex-shrink: 0;
    position: ${({$isDesktop}) => (!$isDesktop ? 'static' : 'sticky')};
    top: 180px;
    flex-shrink: 0;
    padding: ${({$isMobile}) => ($isMobile ? '8px 12px 12px 12px' : '16px 24px 24px 24px')};
    border: solid 2px #00000010;
    border-radius: 16px;
    box-shadow: 0 0 20px -10px #00000036;
  }
`;
