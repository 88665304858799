import {FC, JSX, useCallback, useState} from 'react';
import {styled} from 'styled-components';

import {HoobiizData} from '@shared/api/definitions/public_api/hoobiiz_api';
import {
  HoobiizActivityId,
  HoobiizOpeningHours,
  HoobiizStockId,
  HoobiizStockItem,
  HoobiizStockMode,
  HoobiizStockModeType,
  HoobiizStockReservation,
  HoobiizStockReservationType,
  HoobiizStockWeeklyTemplateId,
  HoobiizStockWeeklyTemplateItem,
  HoobiizTicketInfo,
  HoobiizTimeOfDay,
  HoobiizTimePeriod,
  HoobiizVisibility,
  HoobiizWeekday,
} from '@shared/dynamo_model';
import {HOOBIIZ_ADMIN_EMAIL} from '@shared/lib/hoobiiz/hoobiiz_admin_email';
import {
  hoobiizStockBatchId,
  isStockId,
  isStockWeeklyTemplateId,
} from '@shared/lib/hoobiiz/hoobiiz_ids';
import {neverHappens, NonEmptyArray, removeUndefined} from '@shared/lib/type_utils';
import {FullItem} from '@shared/model/search_tables';

import {Button} from '@shared-frontend/components/core/button';
import {GridColumns} from '@shared-frontend/components/core/grid';
import {Radios} from '@shared-frontend/components/core/radios';
import {notifyError} from '@shared-frontend/lib/notification';

import {
  ActivityStockFixedAndAdminConfirmForm,
  FixedAndAdminConfirmTicket,
} from '@src/components/admin/activity_stock/activity_stock_tickets_fixed_and_admin_confirm_form';
import {
  ActivityStockFixedAndAutomaticForm,
  FixedAndAutomaticTicket,
} from '@src/components/admin/activity_stock/activity_stock_tickets_fixed_and_automatic_form';
import {
  ActivityStockFixedAndPregeneratedForm,
  FixedAndPregeneratedTicket,
} from '@src/components/admin/activity_stock/activity_stock_tickets_fixed_and_pregenerated_form';
import {
  ActivityStockFixedAndVendorConfirmForm,
  FixedAndVendorConfirmTicket,
} from '@src/components/admin/activity_stock/activity_stock_tickets_fixed_and_vendor_confirm_form';
import {
  ActivityStockFlexibleAndAdminConfirmForm,
  FlexibleAndAdminConfirmTicket,
} from '@src/components/admin/activity_stock/activity_stock_tickets_flexible_and_admin_confirm';
import {
  ActivityStockFlexibleAndAutomaticForm,
  FlexibleAndAutomaticTicket,
} from '@src/components/admin/activity_stock/activity_stock_tickets_flexible_and_automatic_form';
import {
  ActivityStockFlexibleAndPregeneratedForm,
  FlexibleAndPregeneratedTicket,
} from '@src/components/admin/activity_stock/activity_stock_tickets_flexible_and_pregenerated_form';
import {
  ActivityStockFlexibleAndVendorConfirmForm,
  FlexibleAndVendorConfirmTicket,
} from '@src/components/admin/activity_stock/activity_stock_tickets_flexible_and_vendor_confirm_form';
import {HoobiizStockEntriesForm} from '@src/components/admin/activity_stock/hoobiiz_stock_entries_form';
import {
  ensureTicketInfo,
  MaybeTicket,
} from '@src/components/admin/activity_stock/hoobiiz_ticket_info_form';
import {FormColumnAuto, FormWrapper} from '@src/components/admin/form/form_fragments';
import {HoobiizMessage} from '@src/components/admin/form/hoobiiz_message';
import {adminRadioTheme, AlertButton} from '@src/components/core/theme';
import {Colors} from '@src/components/core/theme_base';
import {HoobiizStockModeTypeLabels} from '@src/lib/hoobiiz_stock_mode_type';
import {HoobiizStockReservationTypeLabels} from '@src/lib/hoobiiz_stock_reservation_type';

export interface ActivityStockFormProps {
  activityId: HoobiizActivityId;
  vendorHours: HoobiizOpeningHours;
  vendorEmail?: string;
  initialData?:
    | NonEmptyArray<FullItem<'HoobiizStock'>>
    | NonEmptyArray<FullItem<'HoobiizStockWeeklyTemplate'>>;
  submitButtonText?: string;
  onSubmit: (data: StockItemAndId[]) => Promise<void>;
}

export interface HoobiizStockTicket {
  reservation: HoobiizStockReservation;
  mode: HoobiizStockMode;
  quantity: number;
  ticketInfo: MaybeTicket[];
  terms: string;
}

export interface StockHint {
  createdAt: number;
  remaining: number;
  bought: number;
  reserved: number;
}

export interface HoobiizWeeklyStockTicket {
  weeklyScheduleInfo: {
    quantity: number;
    timeOfDay: HoobiizTimeOfDay;
    weekday: HoobiizWeekday;
    period: HoobiizTimePeriod;
    ticketInfo: MaybeTicket[];
  };
  mode: HoobiizStockMode;
  terms: string;
}

type StockItemAndId =
  | {id?: HoobiizStockId; ticket?: HoobiizData<HoobiizStockItem>}
  | {
      id?: HoobiizStockWeeklyTemplateId;
      template?: HoobiizData<HoobiizStockWeeklyTemplateItem>;
    };

type StockAndId =
  | {
      id?: HoobiizStockId;
      ticket?: HoobiizStockTicket;
      stockHint?: StockHint;
    }
  | {
      id?: HoobiizStockWeeklyTemplateId;
      template?: HoobiizWeeklyStockTicket;
    };

interface DeletedStock {
  id: HoobiizStockId;
}

function ticketsAreValid(tickets: StockAndId[] | undefined): string | undefined {
  if (tickets === undefined) {
    return 'Ticket incomplet ou invalide';
  }
  if (tickets.length === 0) {
    return 'Aucun ticket renseigné';
  }
  for (const ticket of tickets) {
    let ticketInfo: MaybeTicket[] | undefined;
    if ('template' in ticket) {
      if (ticket.template === undefined) {
        continue; // We're deleting the template
      }
      ticketInfo = ticket.template.weeklyScheduleInfo.ticketInfo;
    } else if ('ticket' in ticket) {
      if (ticket.ticket === undefined) {
        continue; // We're deleting the ticket
      }
      ticketInfo = ticket.ticket.ticketInfo;
    }
    if (!ticketInfo || ticketInfo.length === 0) {
      return 'Aucun ticket renseigné';
    }
    const res = ensureTicketInfo(ticketInfo);
    if (!res.success) {
      return res.err;
    }
  }
  return undefined; // No error, tickets are valid
}

export const ActivityStockForm: FC<ActivityStockFormProps> = props => {
  const {activityId, initialData, onSubmit, submitButtonText, vendorHours, vendorEmail} = props;

  const firstInitialData = initialData?.[0];
  const [reservationType, setReservationType] = useState(
    firstInitialData
      ? 'reservation' in firstInitialData
        ? firstInitialData.reservation.type
        : HoobiizStockReservationType.Fixed
      : undefined
  );
  const [modeType, setModeType] = useState(initialData?.[0]?.mode.type);

  const [tickets, setTickets] = useState<StockAndId[] | undefined>(
    initialData?.map<StockAndId>(t => {
      if ('weekday' in t) {
        return {
          id: t.id,
          template: {
            weeklyScheduleInfo: {
              quantity: t.quantity,
              timeOfDay: t.timeOfDay,
              weekday: t.weekday,
              period: t.period,
              ticketInfo: t.ticketInfo,
            },
            mode: t.mode,
            terms: t.terms ?? '',
          },
        };
      }
      return {
        id: t.id,
        ticket: {
          reservation: t.reservation,
          mode: t.mode,
          quantity: t.quantity,
          ticketInfo: t.ticketInfo,
          terms: t.terms ?? '',
        },
        stockHint: {
          createdAt: t.createdAt,
          remaining: t.remaining,
          bought: t.bought,
          reserved: t.reserved,
        },
      };
    }) ?? []
  );
  const [deletedTickets, setDeletedTickets] = useState<DeletedStock[]>([]);

  const updateReservationType = useCallback(
    (newReservationType: HoobiizStockReservationType | undefined) => {
      setTickets([]);
      setReservationType(newReservationType);
    },
    []
  );
  const updateModeType = useCallback((newModeType: HoobiizStockModeType | undefined) => {
    setTickets([]);
    setModeType(newModeType);
  }, []);

  const handleTicketsChange = useCallback(
    (tickets: StockAndId | StockAndId[] | undefined, deleted?: DeletedStock[]) => {
      setDeletedTickets(deleted ?? []);
      if (tickets === undefined) {
        return setTickets(undefined);
      }
      setTickets(Array.isArray(tickets) ? tickets : [tickets]);
    },
    []
  );

  const handleSubmitClick = useCallback(async () => {
    const error = ticketsAreValid(tickets);
    if (
      error !== undefined ||
      tickets === undefined // For typings
    ) {
      notifyError(new Error(error), {extra: tickets});
      return;
    }
    const batchId = initialData?.[0]?.batchId ?? hoobiizStockBatchId();
    await onSubmit(
      tickets.map(t => {
        if ('template' in t) {
          return {
            id: t.id,
            template: t.template
              ? {
                  activityId,
                  batchId,
                  ticketInfo: t.template.weeklyScheduleInfo.ticketInfo as HoobiizTicketInfo[],
                  visibility: HoobiizVisibility.Public,
                  mode: t.template.mode,
                  period: t.template.weeklyScheduleInfo.period,
                  quantity: t.template.weeklyScheduleInfo.quantity,
                  timeOfDay: t.template.weeklyScheduleInfo.timeOfDay,
                  weekday: t.template.weeklyScheduleInfo.weekday,
                  terms: t.template.terms,
                }
              : undefined,
          };
        }
        if ('ticket' in t) {
          if (deletedTickets.some(d => d.id === t.id)) {
            return {id: t.id, ticket: undefined};
          }
          return {
            id: t.id,
            ticket: t.ticket
              ? {
                  activityId,
                  batchId,
                  ticketInfo: t.ticket.ticketInfo as HoobiizTicketInfo[],
                  visibility: HoobiizVisibility.Public,
                  mode: t.ticket.mode,
                  reservation: t.ticket.reservation,
                  quantity: t.ticket.quantity,
                  terms: t.ticket.terms,
                }
              : undefined,
          };
        }
        const err = new Error('Invalid ticket');
        notifyError(err, {extra: t});
        throw err;
      })
    );
  }, [activityId, deletedTickets, initialData, onSubmit, tickets]);

  const handleDeleteClick = useCallback(async () => {
    if (!initialData || !tickets) {
      return;
    }
    await onSubmit(
      tickets.map(t => {
        if ('template' in t) {
          return {id: t.id, template: undefined};
        } else if ('ticket' in t) {
          return {id: t.id, ticket: undefined};
        }
        const err = new Error('Invalid ticket');
        notifyError(err, {extra: t});
        throw err;
      })
    );
  }, [initialData, onSubmit, tickets]);

  let form = <></>;
  if (reservationType === undefined || modeType === undefined) {
    form = <Message>Sélectionnez un mode de réservation et un type de stock</Message>;
  } else if (reservationType === HoobiizStockReservationType.Flexible) {
    if (modeType === HoobiizStockModeType.Pregenerated) {
      form = (
        <ActivityStockFlexibleAndPregeneratedForm
          vendorHours={vendorHours}
          initial={tickets?.[0] as FlexibleAndPregeneratedTicket | undefined}
          onChange={handleTicketsChange}
        />
      );
    } else if (modeType === HoobiizStockModeType.AdminConfirm) {
      form = (
        <ActivityStockFlexibleAndAdminConfirmForm
          vendorHours={vendorHours}
          initial={tickets?.[0] as FlexibleAndAdminConfirmTicket | undefined}
          onChange={handleTicketsChange}
        />
      );
    } else if (modeType === HoobiizStockModeType.VendorConfirm) {
      form = (
        <ActivityStockFlexibleAndVendorConfirmForm
          vendorHours={vendorHours}
          initial={tickets?.[0] as FlexibleAndVendorConfirmTicket | undefined}
          onChange={handleTicketsChange}
        />
      );
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (modeType === HoobiizStockModeType.Automatic) {
      form = (
        <ActivityStockFlexibleAndAutomaticForm
          vendorHours={vendorHours}
          initial={tickets?.[0] as FlexibleAndAutomaticTicket | undefined}
          onChange={handleTicketsChange}
        />
      );
    } else {
      neverHappens(modeType);
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (reservationType === HoobiizStockReservationType.Fixed) {
    if (modeType === HoobiizStockModeType.Pregenerated) {
      form = (
        <ActivityStockFixedAndPregeneratedForm
          initialData={tickets as FixedAndPregeneratedTicket[]}
          vendorHours={vendorHours}
          onChange={handleTicketsChange}
        />
      );
    } else if (modeType === HoobiizStockModeType.AdminConfirm) {
      form = (
        <ActivityStockFixedAndAdminConfirmForm
          initialData={tickets as FixedAndAdminConfirmTicket[]}
          vendorHours={vendorHours}
          onChange={handleTicketsChange}
        />
      );
    } else if (modeType === HoobiizStockModeType.VendorConfirm) {
      form = (
        <ActivityStockFixedAndVendorConfirmForm
          initialData={tickets as FixedAndVendorConfirmTicket[]}
          vendorHours={vendorHours}
          onChange={handleTicketsChange}
        />
      );
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (modeType === HoobiizStockModeType.Automatic) {
      form = (
        <ActivityStockFixedAndAutomaticForm
          initialData={tickets as FixedAndAutomaticTicket[]}
          vendorHours={vendorHours}
          onChange={handleTicketsChange}
        />
      );
    } else {
      neverHappens(modeType);
    }
  } else {
    neverHappens(reservationType);
  }

  // message
  const warnings: (string | JSX.Element)[] = [];

  if (reservationType !== undefined && modeType !== undefined) {
    if (modeType === HoobiizStockModeType.AdminConfirm) {
      warnings.push(
        <span key="warning-1">
          Les demandes de confirmation seront envoyées à <Bold>{HOOBIIZ_ADMIN_EMAIL}</Bold>
        </span>
      );
    } else if (modeType === HoobiizStockModeType.VendorConfirm) {
      const confirmationEmail = tickets
        ?.map(t =>
          'ticket' in t
            ? t.ticket?.mode.type === HoobiizStockModeType.VendorConfirm
              ? t.ticket.mode.email
              : undefined
            : undefined
        )
        .filter(e => e !== undefined);
      warnings.push(
        confirmationEmail === undefined || confirmationEmail.length === 0 ? (
          <span key="warning-1">
            ⚠️ Pas d'email renseigné pour la confirmation des commandes. Les emails de confirmation
            seront envoyés à <Bold>{HOOBIIZ_ADMIN_EMAIL}</Bold>
          </span>
        ) : (
          <span key="warning-1">
            Les demandes de confirmation seront envoyées à <Bold>{confirmationEmail}</Bold>
          </span>
        )
      );
    }
    if (modeType === HoobiizStockModeType.Pregenerated) {
      warnings.push(
        <span key="warning-2">
          Si il manque des billets, un email d'alerte sera envoyé à{' '}
          <Bold>{HOOBIIZ_ADMIN_EMAIL}</Bold>
        </span>
      );
    } else if (modeType === HoobiizStockModeType.AdminConfirm) {
      warnings.push(
        <span key="warning-2">
          Après génération des billets, un email de confirmation sera envoyé à{' '}
          <Bold>{HOOBIIZ_ADMIN_EMAIL}</Bold>
        </span>
      );
    } else {
      warnings.push(
        vendorEmail === undefined || vendorEmail.length === 0 ? (
          <span key="warning-2">
            ⚠️ Pas d'email renseigné chez le partenaire. Après génération des billets, un email de
            confirmation sera envoyé à <Bold>{HOOBIIZ_ADMIN_EMAIL}</Bold>
          </span>
        ) : (
          <span key="warning-2">
            Après génération des billets, un email de confirmation sera envoyé à{' '}
            <Bold>{vendorEmail}</Bold>
          </span>
        )
      );
    }
  }

  // const [visibility, setVisibility] = useState(initialData?.visibility ?? HoobiizVisibility.Public);

  const values = [
    HoobiizStockModeType.Pregenerated,
    HoobiizStockModeType.Automatic,
    HoobiizStockModeType.VendorConfirm,
    HoobiizStockModeType.AdminConfirm,
  ].map(type => ({
    label: HoobiizStockModeTypeLabels[type].label,
    subLabel: HoobiizStockModeTypeLabels[type].subLabel,
    value: type,
    disabled: false,
  }));
  values.splice(1, 0, {
    label: '🚧 Billet uploadé individuellement, commande confirmée automatiquement',
    subLabel: 'Ex : Disney',
    value: 'invalid' as HoobiizStockModeType,
    disabled: true,
  });

  const allIds = removeUndefined((tickets ?? []).map(t => t.id));
  const ticketIds = allIds.filter(isStockId);
  const templateIds = allIds.filter(isStockWeeklyTemplateId);
  const noStock = ticketIds.length === 0 && templateIds.length === 0;

  return (
    <FormWrapper>
      <GridColumns $columns={2}>
        <FormColumnAuto>
          <Radios
            value={reservationType}
            values={[HoobiizStockReservationType.Flexible, HoobiizStockReservationType.Fixed].map(
              type => ({
                label: HoobiizStockReservationTypeLabels[type].label,
                subLabel: HoobiizStockReservationTypeLabels[type].subLabel,
                value: type,
              })
            )}
            label={'MODE DE RÉSERVATION'}
            syncState={updateReservationType}
            overrides={adminRadioTheme}
            column
            disabled={initialData !== undefined}
          />
        </FormColumnAuto>
        <FormColumnAuto>
          <Radios
            value={modeType}
            values={values}
            label={'TYPE DE STOCK'}
            syncState={updateModeType}
            overrides={adminRadioTheme}
            column
            disabled={initialData !== undefined}
          />
        </FormColumnAuto>
      </GridColumns>
      <Sep />
      {warnings.length > 0 ? <HoobiizMessage color="warning" messages={warnings} /> : <></>}
      {form}
      {!noStock ? (
        <HoobiizStockEntriesForm stockIds={ticketIds} weeklyTemplateIds={templateIds} />
      ) : (
        <></>
      )}
      <ButtonsWrapper>
        <Button onClickAsync={handleSubmitClick}>{submitButtonText}</Button>
        {initialData ? (
          <AlertButton onClickAsync={handleDeleteClick}>Supprimer</AlertButton>
        ) : (
          <></>
        )}
      </ButtonsWrapper>
    </FormWrapper>
  );
};
ActivityStockForm.displayName = 'ActivityStockForm';

const Message = styled.div`
  color: ${Colors.Grey};
`;

const Sep = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${Colors.LightGrey2};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Bold = styled.span`
  font-weight: 700;
  background: #ffffff91;
  padding: 0 4px;
  border-radius: 4px;
`;
