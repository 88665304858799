import {JSX, useCallback, useEffect} from 'react';

import {
  AccountId,
  FrontendUserDataContent,
  FrontendUserDataContentType,
} from '@shared/dynamo_model';
import {FrontendTheme} from '@shared/frontends/frontend_theme_model';
import {asBoolean, asString, isNull} from '@shared/lib/type_utils';

import {Checkbox} from '@shared-frontend/components/core/checkbox';
import {Input} from '@shared-frontend/components/core/input_v2';

interface FrontendUserDataContentTextfieldElement {
  type: 'textfield';
  placeholder?: string;
  label?: string;
  autoComplete?: string;
  attribute: string;
  required: boolean;
}

interface FrontendUserDataContentCheckboxElement {
  type: 'checkbox';
  label?: string;
  attribute: string;
}

type FrontendUserDataContentElement =
  | FrontendUserDataContentTextfieldElement
  | FrontendUserDataContentCheckboxElement;

interface FrontendUserDataContentMetadata<Type extends FrontendUserDataContentType> {
  elements: FrontendUserDataContentElement[];
  initial: FrontendUserDataContentTyped<Type>;
}

export const FRONTEND_USER_DATA_FORM_METADATA: {
  [Type in FrontendUserDataContentType]: FrontendUserDataContentMetadata<Type>;
} = {
  [FrontendUserDataContentType.Hoobiiz]: {
    elements: [
      {
        type: 'textfield',
        attribute: 'firstName',
        placeholder: 'Jean',
        label: 'PRÉNOM',
        autoComplete: 'given-name',
        required: true,
      },
      {
        type: 'textfield',
        attribute: 'lastName',
        placeholder: 'DUPONT',
        label: 'NOM',
        autoComplete: 'family-name',
        required: true,
      },
      {
        type: 'textfield',
        attribute: 'phoneNumber',
        placeholder: '0610101010',
        label: 'TÉLÉPHONE (certains achats nécessitent une vérification)',
        autoComplete: 'tel',
        required: true,
      },
      {
        type: 'textfield',
        attribute: 'registrationCode',
        label: 'CODE PREMIUM (optionel)',
        autoComplete: 'off',
        required: false,
      },
    ],
    initial: {
      type: FrontendUserDataContentType.Hoobiiz,
      firstName: '',
      lastName: '',
      phoneNumber: '',
    },
  },
  [FrontendUserDataContentType.Onescale]: {
    elements: [
      {
        type: 'textfield',
        attribute: 'accountId',
        placeholder: 'Enter accountId...',
        label: 'ACCOUNT ID',
        autoComplete: 'off',
        required: true,
      },
      {
        type: 'checkbox',
        attribute: 'isRoot',
        label: 'Is Root',
      },
    ],
    initial: {
      type: FrontendUserDataContentType.Onescale,
      accountId: '' as AccountId,
      isRoot: false,
    },
  },
};

export function isFrontendUserDataContentValid(
  type: FrontendUserDataContentType | undefined,
  data: Partial<FrontendUserDataContent>
): boolean {
  if (type === undefined) {
    return false;
  }
  const metadata = FRONTEND_USER_DATA_FORM_METADATA[type];
  for (const element of metadata.elements) {
    if (element.type === 'textfield' && element.required) {
      const value = data[element.attribute as keyof typeof data];
      if (value === undefined || (typeof value === 'string' && value.length === 0)) {
        return false;
      }
    }
  }
  return true;
}

type FrontendUserDataContentTyped<T extends FrontendUserDataContentType | undefined> =
  T extends undefined ? undefined : FrontendUserDataContent & {type: T};

export interface FrontendUserDataFormProps<T extends FrontendUserDataContentType | undefined> {
  userDataContentType?: FrontendUserDataContentType;
  data: Partial<FrontendUserDataContentTyped<T>> | undefined;
  onChange: (data: Partial<FrontendUserDataContentTyped<T>> | undefined) => void;
  inputTheme?: Partial<FrontendTheme['input']>;
  checkboxTheme?: Partial<FrontendTheme['checkbox']>;
}

export function FrontendUserDataForm<T extends FrontendUserDataContentType | undefined>(
  props: FrontendUserDataFormProps<T>
): JSX.Element {
  const {userDataContentType, data, onChange, inputTheme, checkboxTheme} = props;
  const metadata =
    userDataContentType === undefined
      ? undefined
      : FRONTEND_USER_DATA_FORM_METADATA[userDataContentType];

  const handleInputChange = useCallback(
    (inputVal: string | undefined, evt: React.ChangeEvent<HTMLInputElement>) => {
      const attribute = evt.currentTarget.getAttribute('data-attribute');
      if (isNull(attribute)) {
        return;
      }
      const newData = {...data, [attribute]: inputVal};
      onChange(newData);
    },
    [data, onChange]
  );

  useEffect(() => {
    if (data === undefined && metadata !== undefined) {
      onChange(metadata.initial as unknown as Partial<FrontendUserDataContentTyped<T>>);
    }
  }, [data, metadata, onChange]);

  return (
    <>
      {(metadata?.elements ?? []).map(element =>
        element.type === 'textfield' ? (
          <div key={element.attribute}>
            <Input<string | undefined>
              data-attribute={element.attribute}
              value={asString(data?.[element.attribute as keyof typeof data])}
              syncStateWithEvent={handleInputChange}
              overrides={inputTheme}
              autoComplete={element.autoComplete}
              label={element.label}
              placeholder={element.placeholder}
              width={'100%'}
              required={element.required}
            />
          </div>
        ) : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        element.type === 'checkbox' ? (
          <Checkbox
            key={element.attribute}
            data-attribute={element.attribute}
            checked={asBoolean(data?.[element.attribute as keyof typeof data])}
            overrides={checkboxTheme}
          >
            {element.label ?? ''}
          </Checkbox>
        ) : (
          <></>
        )
      )}
    </>
  );
}

FrontendUserDataForm.displayName = 'FrontendUserDataForm';
