import {FC, useCallback, useState} from 'react';
import styled from 'styled-components';

import {HoobiizData} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizCat2Id, HoobiizCat3Item} from '@shared/dynamo_model';
import {FullItem} from '@shared/model/search_tables';

import {Button} from '@shared-frontend/components/core/button';
import {useParams} from '@shared-frontend/lib/use_typed_params';

import {CategoryForm, CatInfo} from '@src/components/admin/category/category_form';
import {AlertButton} from '@src/components/core/theme';

interface Category3FormProps {
  initialData?: FullItem<'HoobiizCat3'>;
  submitButtonText?: string;
  onSubmit?: (data: Omit<HoobiizData<HoobiizCat3Item>, 'order'>) => Promise<void>;
  onDelete?: () => Promise<void>;
  autoFocus?: boolean;
}

export const Category3Form: FC<Category3FormProps> = props => {
  const {initialData, submitButtonText, onSubmit, onDelete, autoFocus} = props;
  const {cat2Id: cat2IdStr = ''} = useParams();
  const cat2Id = cat2IdStr as HoobiizCat2Id;

  const [info, setInfo] = useState<CatInfo | undefined>();

  const handleCreateClick = useCallback(async () => {
    if (!info) {
      return;
    }
    return onSubmit?.({...info, cat2Id});
  }, [info, onSubmit, cat2Id]);

  return (
    <Wrapper>
      <CategoryForm initialData={initialData} onChange={setInfo} autoFocus={autoFocus} />
      <ButtonsWrapper>
        <Button onClickAsync={handleCreateClick} disabled={info === undefined}>
          {submitButtonText}
        </Button>
        {onDelete ? <AlertButton onClickAsync={onDelete}>Supprimer</AlertButton> : <></>}
      </ButtonsWrapper>
    </Wrapper>
  );
};
Category3Form.displayName = 'Category3Form';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 33px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
