import {FC, useEffect, useState} from 'react';

import {
  HoobiizFlexibleStockSchedule,
  HoobiizOpeningHours,
  HoobiizStockReservationFlexible,
  HoobiizStockReservationType,
  HoobiizTimePeriod,
} from '@shared/dynamo_model';

import {HoobiizFlexibleStockScheduleForm} from '@src/components/admin/activity_stock/hoobiiz_flexible_stock_schedule_form';
import {FormColumn, FormLabel, FormRow} from '@src/components/admin/form/form_fragments';
import {HoobiizTimePeriodForm} from '@src/components/admin/form/hoobiiz_time_period_form';

interface HoobiizStockReservationFlexibleFormProps {
  vendorHours: HoobiizOpeningHours;
  initialData?: HoobiizStockReservationFlexible;
  onChange: (newData: HoobiizStockReservationFlexible) => void;
}

export const HoobiizStockReservationFlexibleForm: FC<
  HoobiizStockReservationFlexibleFormProps
> = props => {
  const {vendorHours, initialData, onChange} = props;

  const [schedule, setSchedule] = useState<HoobiizFlexibleStockSchedule | undefined>(
    initialData?.schedule
  );
  const [period, setPeriod] = useState<HoobiizTimePeriod | undefined>(initialData?.period);

  useEffect(() => {
    if (!schedule || !period) {
      return;
    }
    onChange({type: HoobiizStockReservationType.Flexible, period, schedule});
  }, [onChange, period, schedule]);

  return (
    <FormRow>
      <FormColumn $minWidth={0}>
        <FormLabel $noMargin>PÉRIODE DE VALIDITÉ</FormLabel>
        <HoobiizTimePeriodForm initialData={initialData?.period} onChange={setPeriod} />
      </FormColumn>
      <FormColumn $minWidth={0}>
        <FormLabel $noMargin>HORAIRES</FormLabel>
        <HoobiizFlexibleStockScheduleForm
          vendorHours={vendorHours}
          initialData={initialData?.schedule}
          onChange={setSchedule}
        />
      </FormColumn>
    </FormRow>
  );
};
HoobiizStockReservationFlexibleForm.displayName = 'HoobiizStockReservationFlexibleForm';
