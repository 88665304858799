import {FC, useCallback, useState} from 'react';
import styled from 'styled-components';

import {HoobiizApi, HoobiizUserItem} from '@shared/api/definitions/public_api/hoobiiz_api';
import {
  EmailString,
  FrontendUserId,
  HoobiizUserGroupId,
  HoobiizUserProfile,
} from '@shared/dynamo_model';
import {HoobiizUser} from '@shared/model/hoobiiz/hoobiiz_users';

import {apiCall} from '@shared-frontend/api';
import {Button} from '@shared-frontend/components/core/button';
import {showSuccess} from '@shared-frontend/components/core/notifications';
import {notifyError} from '@shared-frontend/lib/notification';

import {FormRow} from '@src/components/admin/form/form_fragments';
import {
  loadHoobiizUserGroupUsers,
  setAdminUserGroup,
  useHoobiizUserGroup,
} from '@src/components/admin/user_and_group/admin_user_and_group_store';
import {AdminUserAndGroupAdministratorForm} from '@src/components/admin/user_and_group/admin_user_and_group_user_administrator_form';
import {FrontendUserPicker} from '@src/components/admin/user_and_group/admin_user_and_group_user_picker';

export type HoobiizAdministrators = HoobiizUserProfile['permissions'];

interface AdminUserAndGroupAdministratorsFormProps {
  groupId: HoobiizUserGroupId;
}

export const AdminUserAndGroupAdministratorsForm: FC<
  AdminUserAndGroupAdministratorsFormProps
> = props => {
  const {groupId} = props;
  const [newAdmin, setNewAdmin] = useState<HoobiizUser | undefined>(undefined);

  const group = useHoobiizUserGroup({groupId});
  const adminIds = group?.item.adminIds;

  const handleUpdateAdmin = useCallback(
    async (
      {userId, email}: {userId: FrontendUserId; email: EmailString},
      action: 'add' | 'remove'
    ) => {
      if (!group) {
        notifyError('Utilisateur inconnu');
        return;
      }
      // Add the admin to the group
      const updatedGroup = await apiCall(HoobiizApi, '/admin/update-user-group-admins', {
        groupId,
        action,
        userId,
      });
      showSuccess(`Administrateur ${email} ${action === 'add' ? 'ajouté' : 'retiré'}`);

      // Refresh the group data store and reset the users
      setAdminUserGroup(groupId, {
        ...group,
        item: updatedGroup,
        users: {},
      });

      // Reset the form
      setNewAdmin(undefined);

      // Fetch the users again
      await loadHoobiizUserGroupUsers({
        parentGroupId: groupId,
        isInitialLoad: true,
        loadAllUsers: true,
      });
    },
    [group, groupId]
  );

  const handleAddAdminClick = useCallback(async () => {
    if (!newAdmin) {
      return;
    }
    await handleUpdateAdmin(newAdmin, 'add');
  }, [handleUpdateAdmin, newAdmin]);

  const handleRemoveAdminClick = useCallback(
    async (user: HoobiizUserItem) => {
      await handleUpdateAdmin({userId: user.userId, email: user.userEmail}, 'remove');
    },
    [handleUpdateAdmin]
  );

  if (!group || !adminIds) {
    return <Wrapper>Utilisateur inconnu</Wrapper>;
  }

  return (
    <Wrapper>
      {adminIds.length === 0 ? (
        <NoAdmin>Aucun administrateur</NoAdmin>
      ) : (
        <>
          <Title>{`${adminIds.length} Administrateur${adminIds.length > 1 ? 's' : ''}`}</Title>
          <Forms>
            {adminIds.map(id => (
              <AdminUserAndGroupAdministratorForm
                key={id}
                group={group}
                userId={id}
                onDeleteClick={handleRemoveAdminClick}
              />
            ))}
          </Forms>
        </>
      )}
      <FormRow>
        <FrontendUserPicker user={newAdmin} setUser={setNewAdmin} />
        <Button onClickAsync={handleAddAdminClick} disabled={!newAdmin}>
          Ajouter
        </Button>
      </FormRow>
    </Wrapper>
  );
};

AdminUserAndGroupAdministratorsForm.displayName = 'AdminUserAndGroupAdministratorsForm';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const NoAdmin = styled.div`
  color: ${p => p.theme.main.textColor}aa;
  padding-top: 8px;
`;

const Title = styled.div`
  font-weight: 600;
  color: #888;
`;

const Forms = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
`;
