import {FC} from 'react';
import {useLocation} from 'react-router-dom';
import styled, {useTheme} from 'styled-components';

import {textColor} from '@shared/frontends/frontend_theme_utils';

import {Link} from '@shared-frontend/components/core/button';
import {useResponsive} from '@shared-frontend/lib/use_responsive';

import {WhiteNavLink} from '@src/components/core/theme';
import {useIsHoobiiz} from '@src/lib/hoobiiz_context';
import {useNavHeight} from '@src/lib/use_nav_height';

export const Footer: FC = () => {
  const location = useLocation();
  const isHoobiiz = useIsHoobiiz();
  const {footerHeight} = useNavHeight();
  const {isMobile} = useResponsive();
  const theme = useTheme();

  if (['/404', '/devenir-partenaire', '/youpiiz-cest-quoi'].includes(location.pathname)) {
    return <></>;
  }

  return (
    <Wrapper $footerHeight={footerHeight}>
      <Container $isMobile={isMobile}>
        {isHoobiiz ? (
          <>
            <WhiteNavLink to="/devenir-partenaire">Proposez vos activités</WhiteNavLink>
            <WhiteNavLink to="/youpiiz-cest-quoi">Youpiiz c'est quoi ?</WhiteNavLink>
          </>
        ) : (
          <div>
            Ce site est proposé par{' '}
            <Link
              href="https://intercse.fr/"
              target="_blank"
              no-referer
              overrides={{...textColor(theme.main.accentTextColor)}}
            >
              InterCSE
            </Link>
          </div>
        )}
        <div>{`Copyright © ${new Date().getFullYear()}`}</div>
        <WhiteNavLink to="/terms">Mentions légales</WhiteNavLink>
      </Container>
    </Wrapper>
  );
};
Footer.displayName = 'PageWrapper';

const Wrapper = styled.footer<{$footerHeight: number}>`
  background-color: ${p => p.theme.main.accentColor};
  height: ${({$footerHeight}) => `${$footerHeight}px`};
  color: ${p => p.theme.main.accentTextColor};
  display: flex;
`;

const Container = styled.div<{$isMobile: boolean}>`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: ${({$isMobile}) => (!$isMobile ? '32px' : '20px')};
  align-items: center;
  flex-direction: ${({$isMobile}) => (!$isMobile ? 'row' : 'column')};
`;
