import {FC, JSX, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import styled, {useTheme} from 'styled-components';

import {FrontendTheme} from '@shared/frontends/frontend_theme_model';
import {Tuple3} from '@shared/lib/tuple_utils';

import {relativeLuminance} from '@shared-frontend/colors';
import {
  ColorData,
  ColorPicker,
  hexStringToHsva,
  hexStringToRgba,
} from '@shared-frontend/components/core/color_picker';
import {Input, InputLabel} from '@shared-frontend/components/core/input_v2';
import {NULL_REF} from '@shared-frontend/lib/react';
import {useClickOutside} from '@shared-frontend/lib/use_click_outside';
import {useStateRef} from '@shared-frontend/lib/use_state_ref';

interface ColorInputProps {
  color: string;
  syncState: (color: string) => void;
  label?: string | JSX.Element;
  overrides?: Partial<FrontendTheme['input']>;
  autoFocus?: boolean;
  width?: number | string;
  disabled?: boolean;
}

const LUMINANCE_THRESHOLD_FOR_TEXT_COLOR = 0.4;
const SPACE_BETWEEN_INPUT_AND_COLOR_PICKER = 8;

export const ColorInput: FC<ColorInputProps> = props => {
  const {color, syncState, label, overrides, autoFocus, width, disabled} = props;

  const inputRef = useRef<HTMLInputElement>(NULL_REF);
  const wrapperRef = useRef<HTMLDivElement>(NULL_REF);
  const theme = useTheme();

  const [colorData, setColorData, colorDataRef] = useStateRef<ColorData>({
    hex: color,
    hsva: hexStringToHsva(color),
  });
  const [pickerShown, setPickerShown] = useState(false);
  const [pickerOffset, setPickerOffset] = useState(0);
  const isHoveringPicker = useRef(false);

  // Handle outside changes to the color
  useEffect(() => {
    if (color !== colorDataRef.current.hex) {
      setColorData({
        hex: color,
        hsva: hexStringToHsva(color),
      });
    }
  }, [color, colorDataRef, setColorData]);

  // Handle changes from the color picker
  const handleColorChange = useCallback(
    (color: ColorData) => {
      if (colorDataRef.current.hex !== color.hex) {
        syncState(color.hex);
      }
      setColorData(color);
    },
    [colorDataRef, setColorData, syncState]
  );

  const bail = useCallback(() => {
    setPickerShown(false);
    inputRef.current?.blur();
  }, []);
  useClickOutside(wrapperRef, bail);

  // Compute the `pickerOffset` which should be the height of the elements before the picker div
  useLayoutEffect(() => {
    if (!wrapperRef.current) {
      return;
    }
    const {children} = wrapperRef.current;
    let heightSum = 0;
    for (let i = 0; i < children.length; i++) {
      const element = children.item(i);
      if (!element) {
        continue;
      }
      // eslint-disable-next-line unicorn/consistent-function-scoping
      const cssValue = (css: string): string | undefined =>
        document.defaultView?.getComputedStyle(element).getPropertyValue(css);
      // eslint-disable-next-line unicorn/consistent-function-scoping
      const cssPx = (css: string): number => {
        const computed = cssValue(css);
        const pxSuffix = 'px';
        if (computed?.endsWith(pxSuffix)) {
          const pxValue = computed.slice(0, -pxSuffix.length);
          const pxInt = parseFloat(pxValue);
          return isNaN(pxInt) ? 0 : pxInt;
        }
        return 0;
      };
      if (['relative', 'static'].includes(cssValue('position') ?? '')) {
        heightSum +=
          element.getBoundingClientRect().height + cssPx('margin-top') + cssPx('margin-bottom');
      }
      setPickerOffset(heightSum + SPACE_BETWEEN_INPUT_AND_COLOR_PICKER);
    }
  }, []);

  const handleFocus = useCallback(() => setPickerShown(true), []);
  const handleBlur = useCallback(() => {
    if (!isHoveringPicker.current) {
      setPickerShown(false);
    }
  }, []);

  const handleMouseEnter = useCallback(() => {
    isHoveringPicker.current = true;
  }, []);
  const handleMouseLeave = useCallback(() => {
    isHoveringPicker.current = false;
  }, []);

  const textColor = useMemo(() => {
    const RGB_MAX = 255;
    const rgba = hexStringToRgba(color);
    const c = [rgba.r * RGB_MAX, rgba.g * RGB_MAX, rgba.b * RGB_MAX] as Tuple3<number>;
    const luminance = relativeLuminance(c);

    return luminance < LUMINANCE_THRESHOLD_FOR_TEXT_COLOR ? '#ffffff' : '#000000';
  }, [color]);

  const inputOverrides = useMemo<Partial<FrontendTheme['input']>>(() => {
    return {
      ...overrides,
      textColor,
      textColorDisabled: textColor,
      focusTextColor: textColor,
      // borderWidth: 2,
      // focusBorderWidth: 2,
      borderWidth: 0,
      focusBorderWidth: 0,
      borderColor: textColor,
      focusBorderColor: textColor,
      hoverBorderColor: textColor,
      focusOutlineWidth: 0,
    };
  }, [overrides, textColor]);

  const inputStyles = useMemo<React.CSSProperties>(
    () => ({
      backgroundColor: color,
    }),
    [color]
  );
  const input = (
    <StyledInput
      ref={inputRef}
      value={color}
      syncState={syncState}
      onFocus={handleFocus}
      onBlur={handleBlur}
      overrides={inputOverrides}
      autoFocus={autoFocus}
      width={width ?? 'max-content'}
      disabled={disabled}
      // eslint-disable-next-line react/forbid-component-props
      style={inputStyles}
    />
  );

  return (
    <Wrapper ref={wrapperRef}>
      {label === undefined ? (
        input
      ) : (
        <InputLabel
          value={label}
          $paddingLeft={inputOverrides.paddingLeft}
          $labelPosition={'left'}
          $fontSize={inputOverrides.fontSize}
          $textColor={theme.input.textColor}
          $marginBottom={theme.input.titleMarginBottom}
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          $opacity={inputOverrides.labelOpacity ?? theme.input.labelOpacity ?? 0.6}
        >
          {input}
        </InputLabel>
      )}
      {pickerShown ? (
        <Popup
          $offset={pickerOffset}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <ColorPicker color={colorData} onChange={handleColorChange} />
        </Popup>
      ) : (
        <></>
      )}
    </Wrapper>
  );
};
ColorInput.displayName = 'ColorInput';

const Wrapper = styled.div`
  position: relative;
`;

const Popup = styled.div<{
  $offset: number;
}>`
  position: absolute;
  top: ${p => p.$offset}px;
  left: 0;
  overflow: auto;
  z-index: 100;

  overflow: visible;
  box-shadow: 0 0 20px -10px #00000036;
  background-color: ${p => p.theme.main.backgroundColor};
`;

const StyledInput = styled(Input)`
  box-shadow: 0px 0px 10px -4px ${p => p.theme.main.textColor};
`;
