import {FC, Fragment, useCallback, useState} from 'react';
import styled from 'styled-components';

import {Button, NavLink} from '@shared-frontend/components/core/button';
import {Checkbox} from '@shared-frontend/components/core/checkbox';
import {InputHandler} from '@shared-frontend/lib/react';

interface RegisterMfaCheckboxesProps {
  onSubmit: () => Promise<void>;
}

const Underlined = styled(NavLink)`
  /* text-decoration: underline;
  cursor: pointer; */
`;

const CHECKBOXES = [
  // 'You disabled all screen sharing applications',
  `Vous avez désactivé toutes les applications de partage d'écran.`,
  // 'No one other than you is watching the screen',
  `Personne d'autre que vous ne regarde l'écran.`,
  // 'There is no video surveillance camera behind you',
  `Il n'y a pas de caméra de surveillance vidéo derrière vous.`,
  // 'You have a Multi-Factor Authentication device ready',
  `Vous avez un dispositif d'authentification multi-facteurs prêt.`,
  <Fragment key="terms">
    <span>You agree to our&nbsp;</span>
    <Underlined to="/terms" target="_blank">
      terms and conditions
    </Underlined>
  </Fragment>,
  'You are ready to set up MFA, this is only possible once',
];

export const RegisterMfaCheckboxes: FC<RegisterMfaCheckboxesProps> = ({onSubmit}) => {
  const [currentChecked, setCurrentChecked] = useState(-1);

  const handleCheckChange = useCallback<InputHandler>(e => {
    const {checked} = e.currentTarget;
    const index = parseFloat(e.currentTarget.getAttribute('data-index') ?? '0');
    setCurrentChecked(checked ? index : index - 1);
  }, []);

  return (
    <>
      <Header>
        We are happy to have you among us. An administrator just created your account and protected
        it with Multi-Factor Authentication. To access your account, make sure that:
      </Header>
      <FlexSeparator />
      <List>
        <AlignedLeft>
          {CHECKBOXES.map((val, i) => (
            <Checkbox
              key={i}
              checked={currentChecked >= i}
              data-index={i}
              onChange={handleCheckChange}
              overrides={{
                size: 20,
                labelPaddingTop: 2,
                labelPaddingRight: 8,
                labelPaddingBottom: 2,
                labelPaddingLeft: 8,
              }}
            >
              {val}
            </Checkbox>
          ))}
        </AlignedLeft>
      </List>
      <ButtonSeparator />
      <Button hidden={currentChecked < 5} onClickAsync={onSubmit}>
        Create My Account Now!
      </Button>
    </>
  );
};
RegisterMfaCheckboxes.displayName = 'RegisterMfaCheckboxes';

const Header = styled.div`
  font-weight: 300;
  font-size: 34px;
`;

const AlignedLeft = styled.div`
  display: inline-block;
  text-align: left;
`;

const FlexSeparator = styled.div`
  height: 50px;
`;

const List = styled.div`
  font-size: 26px;
`;

const ButtonSeparator = styled.div`
  height: 40px;
`;
