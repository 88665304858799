/* eslint-disable @typescript-eslint/no-magic-numbers */
import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {
  HoobiizUserGroupId,
  HoobiizVisibility,
  HoobiizWhiteLabeling,
  HoobiizWhiteLabelingAuth,
  HoobiizWhiteLabelingTheme,
} from '@shared/dynamo_model';
import {IS_LOCALHOST_ENV} from '@shared/env_constants';
import {overwriteThemeWithWhiteLabeling} from '@shared/frontends/frontend_base_theme';
import {deepEqual} from '@shared/lib/object_utils';
import {DeepPartial, removeUndefined, UnionToTuple} from '@shared/lib/type_utils';

import {apiCall} from '@shared-frontend/api';
import {lighten} from '@shared-frontend/colors';
import {Button, Link, NavLink} from '@shared-frontend/components/core/button';
import {ColorInput} from '@shared-frontend/components/core/color_input';
import {Input} from '@shared-frontend/components/core/input_v2';
import {LoadingIndicator} from '@shared-frontend/components/core/loading_indicator';
import {showSuccess} from '@shared-frontend/components/core/notifications';
import {Radios} from '@shared-frontend/components/core/radios';
import {Select} from '@shared-frontend/components/core/select';

import {FormColumnFull, FormLabel} from '@src/components/admin/form/form_fragments';
import {FormSection} from '@src/components/admin/form/form_section';
import {HoobiizMessage} from '@src/components/admin/form/hoobiiz_message';
import {MediaUpload} from '@src/components/admin/form/media_upload';
import {MediaUploadStatus} from '@src/components/admin/form/media_upload_status';
import {getHoobiizUserGroupModalPath} from '@src/components/admin/user_and_group/admin_user_and_group_paths';
import {
  getOrFetchHoobiizUserGroup,
  HoobiizUserGroupStoreItem,
  useHoobiizUserGroup,
} from '@src/components/admin/user_and_group/admin_user_and_group_store';
import {
  AdminUserAndGroupWhiteLabelingAuth,
  isValidWhiteLabelingAuth,
} from '@src/components/admin/user_and_group/admin_user_and_group_white_labeling_auth';
import {adminInputTheme, AdminNavLink, theme as youpiizTheme} from '@src/components/core/theme';
import {Colors, FontWeight} from '@src/components/core/theme_base';
import {HoobiizReservationCta} from '@src/components/ui/hoobiiz_reservation_cta';
import {AdminWhiteLabelingValidator} from '@src/lib/hoobiiz_admin_white_labeling';
import {HoobiizVisibilityLabels} from '@src/lib/hoobiiz_visibility';

interface AdminUserAndGroupWhiteLabelingPageProps {
  groupId: HoobiizUserGroupId;
  groupIds: HoobiizUserGroupId[];
}

function groupWhiteLabeling(group?: HoobiizUserGroupStoreItem): HoobiizWhiteLabeling | undefined {
  return group ? group.item.groupProfile.whiteLabeling ?? {} : undefined;
}

// Generic parameters for the white labeling forms
export interface AdminUserAndGroupWhiteLabelingFormProps<T> {
  data: DeepPartial<T> | undefined;
  onChange: (data?: DeepPartial<T> | undefined) => void;
  baseWhiteLabeling: DeepPartial<HoobiizWhiteLabeling> | undefined;
}

export const isValidWhiteLabeling: AdminWhiteLabelingValidator<
  HoobiizWhiteLabeling | undefined
> = data => {
  // No theme override is the default theme
  if (!data) {
    return {valid: true, data: {}};
  }

  const res = isValidWhiteLabelingAuth(data.auth);
  if (!res.valid) {
    return {valid: false};
  }
  return {valid: true, data: {...data, auth: res.data}};
};

const ALL_THEMES: UnionToTuple<
  (typeof HoobiizWhiteLabelingTheme)[keyof typeof HoobiizWhiteLabelingTheme]
> = [HoobiizWhiteLabelingTheme.Youpiiz, HoobiizWhiteLabelingTheme.Cse];

const THEME_LABELS: Record<HoobiizWhiteLabelingTheme, string> = {
  [HoobiizWhiteLabelingTheme.Youpiiz]: 'Youpiiz',
  [HoobiizWhiteLabelingTheme.Cse]: 'CSE',
};

export const AdminUserAndGroupWhiteLabelingPage: FC<
  AdminUserAndGroupWhiteLabelingPageProps
> = props => {
  // Load and maintain the current WhiteLabeling of the group
  const {groupId, groupIds} = props;
  const group = useHoobiizUserGroup({groupId});

  const initialWhiteLabeling = useMemo(() => groupWhiteLabeling(group), [group]);
  useEffect(() => setWhiteLabeling(initialWhiteLabeling), [initialWhiteLabeling]);
  const [whiteLabeling, setWhiteLabeling] = useState<DeepPartial<HoobiizWhiteLabeling> | undefined>(
    groupWhiteLabeling(group)
  );

  const themeWithWhiteLabeling = useMemo(
    () => overwriteThemeWithWhiteLabeling(youpiizTheme, whiteLabeling),
    [whiteLabeling]
  );

  const hasChanged = useMemo(
    () => !deepEqual(whiteLabeling, initialWhiteLabeling),
    [whiteLabeling, initialWhiteLabeling]
  );

  // Save the white labeling changes
  const handleSave = useCallback(async () => {
    const validation = isValidWhiteLabeling(whiteLabeling);
    if (!validation.valid) {
      return;
    }
    await apiCall(HoobiizApi, '/admin/update-group-white-labeling', {
      groupId,
      whiteLabeling: validation.data,
    });
    await getOrFetchHoobiizUserGroup({groupId, noCache: true});
    showSuccess('Thème mis à jour');
  }, [whiteLabeling, groupId]);

  // AUTH change handler
  const handleWhiteLabelingAuthChange = useCallback(
    (auth?: DeepPartial<HoobiizWhiteLabelingAuth>) => {
      setWhiteLabeling(whiteLabeling => ({...whiteLabeling, auth}));
    },
    []
  );

  // THEME change handler
  const handleThemeChange = useCallback(
    (theme?: HoobiizWhiteLabelingTheme) =>
      setWhiteLabeling(whiteLabeling => ({...whiteLabeling, theme})),
    []
  );

  // TITLE change handler
  const handleTitleChange = useCallback(
    (title?: string) => setWhiteLabeling(whiteLabeling => ({...whiteLabeling, title})),
    []
  );

  // PAGE TITLE change handler
  const handlePageTitleChange = useCallback(
    (pageTitle?: string) => setWhiteLabeling(whiteLabeling => ({...whiteLabeling, pageTitle})),
    []
  );

  // LOGO change handler
  const handleLogoMediaChange = useCallback((statuses: MediaUploadStatus[]) => {
    for (const status of statuses) {
      if (status.status === 'success') {
        setWhiteLabeling(whiteLabeling => ({...whiteLabeling, logo: status.id}));
        return;
      }
    }
    setWhiteLabeling(whiteLabeling => ({...whiteLabeling, logo: undefined}));
  }, []);

  // COLORS
  const handleMainColorChange = useCallback(
    (mainColor?: string) => setWhiteLabeling(whiteLabeling => ({...whiteLabeling, mainColor})),
    []
  );
  const handleAccentTextColorChange = useCallback(
    (accentTextColor?: string) =>
      setWhiteLabeling(whiteLabeling => ({...whiteLabeling, accentTextColor})),
    []
  );
  const handleLinkTextColorChange = useCallback(
    (linkTextColor?: string) =>
      setWhiteLabeling(whiteLabeling => ({...whiteLabeling, linkTextColor})),
    []
  );
  const handleDisabledButtonColorChange = useCallback(
    (disabledButtonColor?: string) =>
      setWhiteLabeling(whiteLabeling => ({...whiteLabeling, disabledButtonColor})),
    []
  );
  const handleOutlineColorChange = useCallback(
    (outlineColor?: string) =>
      setWhiteLabeling(whiteLabeling => ({...whiteLabeling, outlineColor})),
    []
  );

  const isYoupiizTheme =
    whiteLabeling?.theme === undefined || whiteLabeling.theme === HoobiizWhiteLabelingTheme.Youpiiz;
  const [value, setValue] = useState<HoobiizVisibility | undefined>(HoobiizVisibility.Private);

  // // Print every changes
  // useEffect(() => {
  //   // eslint-disable-next-line no-console
  //   console.log('Current whiteLabeling in the form : ', whiteLabeling);
  // }, [whiteLabeling]);

  // Render
  const backLink = (
    <AdminNavLink to={getHoobiizUserGroupModalPath({hierarchy: groupIds})}>
      Retour au groupe
    </AdminNavLink>
  );
  if (!whiteLabeling) {
    return (
      <Wrapper>
        {backLink}
        <div>
          Chargement...
          <LoadingIndicator />
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {backLink}
      <HomeLink
        target="_blank"
        href={
          IS_LOCALHOST_ENV
            ? `http://${group?.item.cseDomain}:24153`
            : `https://${group?.item.cseDomain}`
        }
      >
        {group?.item.cseDomain}
      </HomeLink>
      <TitleWrapper>
        <Title>{`Thème du groupe ${group?.item.groupLabel}`}</Title>
        <SaveButton onClickAsync={handleSave} $visible={hasChanged}>
          Enregistrer les changements
        </SaveButton>
      </TitleWrapper>

      <Line>
        <FormSection title="Général">
          <FormColumnFull>
            <Select<HoobiizWhiteLabelingTheme | undefined>
              label="Thème"
              placeholder="Choisir un thème"
              value={whiteLabeling.theme}
              values={ALL_THEMES.map(theme => ({value: theme, label: THEME_LABELS[theme]}))}
              syncState={handleThemeChange}
              overrides={adminInputTheme}
            />
          </FormColumnFull>
          <FormColumnFull>
            <Input
              label="Nom"
              placeholder="Nom du site web"
              value={whiteLabeling.title}
              syncState={handleTitleChange}
              overrides={adminInputTheme}
            />
          </FormColumnFull>
          <FormColumnFull>
            <Input
              label="Titre Onglet"
              // We default to the title
              placeholder={whiteLabeling.title}
              value={whiteLabeling.pageTitle}
              syncState={handlePageTitleChange}
              overrides={adminInputTheme}
            />
          </FormColumnFull>
          <FormColumnFull>
            <FormLabel>LOGO</FormLabel>
            <MediaUpload
              initialMediaIds={removeUndefined([whiteLabeling.logo])}
              onChange={handleLogoMediaChange}
            />
          </FormColumnFull>
        </FormSection>
        <FormSection title="Couleurs">
          <FormColumnFull>
            <ColorInput
              label="Couleur principale"
              color={themeWithWhiteLabeling.main.accentColor}
              syncState={handleMainColorChange}
              overrides={adminInputTheme}
              disabled={isYoupiizTheme}
            />
          </FormColumnFull>
          <FormColumnFull>
            <ColorInput
              label="Couleur du texte sur fond"
              color={themeWithWhiteLabeling.main.accentTextColor}
              syncState={handleAccentTextColorChange}
              overrides={adminInputTheme}
              disabled={isYoupiizTheme}
            />
          </FormColumnFull>
          <FormColumnFull>
            <ColorInput
              label="Couleur des liens"
              color={themeWithWhiteLabeling.link.textColorActive ?? '#000000'}
              syncState={handleLinkTextColorChange}
              overrides={adminInputTheme}
              disabled={isYoupiizTheme}
            />
          </FormColumnFull>
          <FormColumnFull>
            <ColorInput
              label="Couleur des boutons désactivés"
              color={themeWithWhiteLabeling.button.backgroundDisabled ?? '#000000'}
              syncState={handleDisabledButtonColorChange}
              overrides={adminInputTheme}
              disabled={isYoupiizTheme}
            />
          </FormColumnFull>
          <FormColumnFull>
            <ColorInput
              label="Couleur des éléments séléctionnés"
              color={themeWithWhiteLabeling.input.focusOutlineColor}
              syncState={handleOutlineColorChange}
              overrides={adminInputTheme}
              disabled={isYoupiizTheme}
            />
          </FormColumnFull>
          {isYoupiizTheme ? (
            <HoobiizMessage
              messages={['Pour modifier les couleurs, veuillez choisir le thème "CSE"']}
            />
          ) : (
            <></>
          )}
        </FormSection>
        <FormSection title="Testeur">
          <Line>
            <CseLabel $backgroundColor={themeWithWhiteLabeling.main.accentColor}>
              Offre premium
            </CseLabel>
            <StyledHoobiizReservationCta
              promoCodeStock={{description: '', discount: ''}}
              $backgroundColor={themeWithWhiteLabeling.main.accentColor}
            />
          </Line>
          <Line>
            <Button overrides={themeWithWhiteLabeling.button}>Normal</Button>
            <Button disabled overrides={themeWithWhiteLabeling.button}>
              Désactivé
            </Button>
          </Line>
          <Line>
            <Button overrides={themeWithWhiteLabeling.button} emulateFocus>
              Séléctionné
            </Button>
          </Line>
          <Line>
            <Input placeholder="Champ normal" value="" overrides={themeWithWhiteLabeling.input} />
          </Line>
          <Line>
            <Input
              placeholder="Champ désactivé"
              value=""
              disabled
              overrides={themeWithWhiteLabeling.input}
            />
          </Line>
          <Line>
            <Input
              placeholder="Champ séléctionné"
              value=""
              overrides={themeWithWhiteLabeling.input}
              emulateFocus
            />
          </Line>
          <Line>
            <NavLink
              to={`/admin/groups/${groupIds.join('/')}/theme`}
              overrides={themeWithWhiteLabeling.link}
            >
              Lien normal
            </NavLink>
            <NavLink
              to={`/admin/groups/${groupIds.join('/')}/theme`}
              disabled
              overrides={themeWithWhiteLabeling.link}
            >
              Lien désactivé
            </NavLink>
          </Line>
          <Line>
            <Radios
              value={value}
              values={[HoobiizVisibility.Private, HoobiizVisibility.Public].map(visibility => ({
                label: HoobiizVisibilityLabels[visibility],
                value: visibility,
              }))}
              syncState={setValue}
              overrides={themeWithWhiteLabeling.radio}
            />
          </Line>
        </FormSection>
      </Line>
      <StyledFormSection title="Authentification">
        <AdminUserAndGroupWhiteLabelingAuth
          data={whiteLabeling.auth}
          onChange={handleWhiteLabelingAuthChange}
          baseWhiteLabeling={whiteLabeling}
        />
      </StyledFormSection>
      <SaveButton onClickAsync={handleSave} $visible={hasChanged}>
        Enregistrer les changements
      </SaveButton>
    </Wrapper>
  );
};

AdminUserAndGroupWhiteLabelingPage.displayName = 'AdminUserAndGroupWhiteLabelingPage';

const Line = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: flex-start;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 32px;
`;

const HomeLink = styled(Link)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px;
  background: #ffffffcc;
  border-radius: 4px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const Title = styled.div`
  font-size: 24px;
`;

const SaveButton = styled(Button)<{$visible: boolean}>`
  background-color: ${Colors.Gold};
  color: ${Colors.White};
  visibility: ${({$visible}) => ($visible ? 'visible' : 'hidden')};
`;

const CseLabel = styled.div<{$backgroundColor?: string}>`
  display: inline-block;
  color: white;
  background-color: ${({$backgroundColor}) => $backgroundColor};
  padding: 5px;
  font-size: 12px;
  font-weight: ${FontWeight.Bold700};
  border-radius: 4px;
`;

const StyledHoobiizReservationCta = styled(HoobiizReservationCta)<{$backgroundColor?: string}>`
  background: linear-gradient(
    197deg,
    ${({$backgroundColor}) => lighten($backgroundColor ?? 'transparent', 0.14)} 0%,
    ${({$backgroundColor}) => lighten($backgroundColor ?? 'transparent', 0.14)} 50%,
    ${({$backgroundColor}) => lighten($backgroundColor ?? 'transparent', 0.64)} 100%
  );
`;

const StyledFormSection = styled(FormSection)`
  width: 100%;
`;
