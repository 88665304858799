import {FC, JSX, useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {ApiDef} from '@shared/api/registry';

import {apiCall} from '@shared-frontend/api';
import {Button} from '@shared-frontend/components/core/button';
import {notifyError} from '@shared-frontend/lib/notification';

import {AdminOrdersTable} from '@src/components/admin/order/admin_orders_table';

interface AdminOrderFailureExplorerPageProps {}

type OrderItem = ApiDef<typeof HoobiizApi>['/admin/list-order-failures']['res']['items'][0];

export const AdminOrderFailureExplorerPage: FC<AdminOrderFailureExplorerPageProps> = () => {
  const [items, setItems] = useState<OrderItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const nextPageToken = useRef<string>();

  const loadOrderFailures = useCallback(() => {
    apiCall(HoobiizApi, '/admin/list-order-failures', {
      limit: 100,
      nextPageToken: nextPageToken.current,
    })
      .then(res => {
        setItems(current => [...current, ...res.items]);
        nextPageToken.current = res.nextPageToken;
      })
      .catch((err: unknown) => {
        notifyError(err, {message: 'Échec du chargement des échecs de commandes'});
      });
    setIsLoading(false);
  }, []);

  useEffect(() => {
    loadOrderFailures();
  }, [loadOrderFailures]);

  const renderFailuresRows = useCallback(
    (orderItem: unknown, index: number): JSX.Element[] => {
      const item = items[index];
      if (!item) {
        return [];
      }
      return item.failures.map(failure => (
        <tr key={`${item.orderItem.id}-${failure.ts}`}>
          <td colSpan={8}>
            <ErrorPre>{failure.error}</ErrorPre>
          </td>
        </tr>
      ));
    },
    [items]
  );

  return (
    <Wrapper>
      {isLoading && items.length === 0 ? (
        <div>Chargements des échecs de commandes...</div>
      ) : (
        <AdminOrdersTable items={items} extraRows={renderFailuresRows} />
      )}
      {!isLoading && items.length > 0 && nextPageToken.current === undefined ? (
        <></>
      ) : (
        <Button loading={isLoading} onClick={loadOrderFailures}>
          Charger plus d'échecs de commandes
        </Button>
      )}
    </Wrapper>
  );
};

AdminOrderFailureExplorerPage.displayName = 'AdminOrderFailureExplorerPage';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 32px;
`;

const ErrorPre = styled.pre`
  white-space: pre-wrap;
  font-family: monospace;
  padding: 8px;
  background: #ff000050;
`;
