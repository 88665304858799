import {FC, useCallback, useMemo, useRef, useState} from 'react';
import {styled, useTheme} from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {
  HoobiizActivityItem,
  HoobiizOrderItemItem,
  HoobiizStockEntryItem,
  HoobiizStockEntryStatus,
  HoobiizTicketFileItem,
} from '@shared/dynamo_model';
import {NO_ORDER_ITEM_ID} from '@shared/lib/hoobiiz/hoobiiz_ids';

import {apiCall} from '@shared-frontend/api';
import {Button, ButtonAsLink, NavLink} from '@shared-frontend/components/core/button';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {AgeString} from '@shared-frontend/components/core/timestamp';
import {Tooltip} from '@shared-frontend/components/core/tooltip';
import {NULL_REF} from '@shared-frontend/lib/react';

import {HoobiizTicketFilesUploadLine} from '@src/components/admin/activity_stock/hoobiiz_ticket_files_upload_line';
import {FormRow} from '@src/components/admin/form/form_fragments';
import {Colors} from '@src/components/core/theme_base';
import {DocumentLinkFromFile} from '@src/components/ui/document_link_from_file';
import {ItemSticker} from '@src/components/ui/item_sticker';

interface HoobiizStockEntryFormProps {
  stockEntry: HoobiizStockEntryItem;
  onDelete: (stockEntry: HoobiizStockEntryItem) => void;
  onChange: (newStockEntry: HoobiizStockEntryItem, oldStockEntry: HoobiizStockEntryItem) => void;
  extraInfo?: {activity?: HoobiizActivityItem; order?: HoobiizOrderItemItem};
}

type UploadStatus =
  | {type: 'initial'}
  | {type: 'uploading'; file: File}
  | {type: 'creating'; file: File; fileItem: HoobiizTicketFileItem}
  | {
      type: 'success';
      file: File;
      fileItem: HoobiizTicketFileItem;
      stockEntry: HoobiizStockEntryItem;
    }
  | {type: 'error'; file: File; error: string};

export const HoobiizStockEntryForm: FC<HoobiizStockEntryFormProps> = ({
  stockEntry,
  onDelete,
  extraInfo,
  onChange,
}) => {
  const theme = useTheme();

  const canDelete = useMemo(() => {
    return stockEntry.orderId === NO_ORDER_ITEM_ID;
  }, [stockEntry.orderId]);

  const handleDeleteClick = useCallback(async () => {
    if (!canDelete) {
      return;
    }
    await apiCall(HoobiizApi, '/admin/delete-stock-entry', {
      stockEntryId: stockEntry.id,
    });
    onDelete(stockEntry);
  }, [stockEntry, onDelete, canDelete]);

  let content = <></>;
  if (stockEntry.content?.ticketFileIds !== undefined) {
    content = (
      <div>
        {stockEntry.content.ticketFileIds.map(id => (
          <DocumentLinkFromFile key={id} fileId={id} />
        ))}
      </div>
    );
  }

  // const firstHistoryEvent = stockEntry.history?.[0];
  const lastHistoryEvent = stockEntry.history?.at(-1);

  const orderIdSticker =
    stockEntry.orderId === NO_ORDER_ITEM_ID
      ? {msg: 'En stock', color: Colors.Grey}
      : {msg: 'Acheté', color: Colors.Green};

  const statusSticker = {
    [HoobiizStockEntryStatus.AwaitingUpload]: {msg: 'UPLOAD NÉCESSAIRE', color: Colors.Red},
    [HoobiizStockEntryStatus.AwaitingGeneration]: {
      msg: 'GÉNÉRATION EN COURS',
      color: Colors.Orange,
    },
    [HoobiizStockEntryStatus.Ready]: undefined,
  }[stockEntry.status];

  //
  // FILE UPLOAD
  //

  const uploadInputRef = useRef<HTMLInputElement>(NULL_REF);
  const [status, setStatus] = useState<UploadStatus>({type: 'initial'});
  const handleFileSuccess = useCallback(
    (file: File, item: HoobiizTicketFileItem) => {
      setStatus({type: 'creating', file, fileItem: item});
      apiCall(HoobiizApi, '/admin/upload-for-stock-entry', {
        stockEntryId: stockEntry.id,
        fileIds: [item.id],
      })
        .then(res => {
          setStatus({type: 'success', file, fileItem: item, stockEntry: res.stockEntry});
          onChange(res.stockEntry, stockEntry);
        })
        .catch(err => {
          setStatus({type: 'error', file, error: err.message});
        });
    },
    [stockEntry, onChange]
  );

  // Callback when the user selected files with the native file dialog
  const handleFileChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(evt => {
    const {files} = evt.currentTarget;
    // eslint-disable-next-line no-null/no-null
    if (files === null) {
      return;
    }
    // eslint-disable-next-line no-null/no-null
    evt.currentTarget.files = null;
    const firstFile = files.item(0);
    if (!firstFile) {
      return;
    }
    setStatus({type: 'uploading', file: firstFile});
  }, []);

  const handleUploadClick = useCallback(() => {
    if (uploadInputRef.current) {
      uploadInputRef.current.click();
    }
  }, []);

  const handleFileDelete = useCallback(() => {
    setStatus({type: 'initial'});
  }, []);

  //
  // RENDER
  //

  return (
    <Wrapper>
      <Band>
        <SvgIcon name="Ticket" color="#ffffff" size={24} rotate={90} />
      </Band>
      <Content>
        <Title>Ticket</Title>
        {content}
        {statusSticker ? (
          <ItemSticker $color={statusSticker.color}>{statusSticker.msg}</ItemSticker>
        ) : undefined}
        {stockEntry.status === HoobiizStockEntryStatus.AwaitingUpload ? (
          <UploadTicketForm>
            {status.type === 'initial' ? (
              <>
                <HiddenInput
                  type="file"
                  accept="application/pdf"
                  onChange={handleFileChange}
                  ref={uploadInputRef}
                ></HiddenInput>
                <UploadTicketButton onClick={handleUploadClick}>
                  <SvgIcon name="Download" color={theme.main.accentColor} height={18} /> Uploader un
                  billet
                </UploadTicketButton>
              </>
            ) : (
              <UploadLineWrapper>
                <HoobiizTicketFilesUploadLine
                  file={status.file}
                  onSuccess={handleFileSuccess}
                  onDelete={handleFileDelete}
                  canDelete={status.type === 'error'}
                  overrideMessage={
                    {
                      uploading: undefined,
                      creating: 'Finalization...',
                      success: 'Success',
                      error: `Erreur ${'error' in status ? status.error : ''}`,
                    }[status.type]
                  }
                  overrideStripes={
                    {
                      uploading: true,
                      creating: true,
                      success: false,
                      error: false,
                    }[status.type]
                  }
                />
              </UploadLineWrapper>
            )}
          </UploadTicketForm>
        ) : undefined}
        {extraInfo ? (
          <div>
            {extraInfo.activity ? (
              <div>
                Activité :{' '}
                <NavLink to={`/admin/activity/${extraInfo.activity.id}?tab=stock`}>
                  {extraInfo.activity.label}
                </NavLink>
              </div>
            ) : undefined}
            {extraInfo.order ? (
              <div>
                Billets achetés : {extraInfo.order.ticketInfo.label} x{extraInfo.order.quantity}
              </div>
            ) : undefined}
          </div>
        ) : undefined}
      </Content>
      {lastHistoryEvent !== undefined ? (
        <Tooltip
          content={
            <Table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Événement</th>
                  <th>Source</th>
                </tr>
              </thead>
              <tbody>
                {stockEntry.history?.map(h => (
                  <tr key={h.ts}>
                    <td>
                      <AgeString ts={h.ts} ago lang="fr-FR" />{' '}
                    </td>
                    <td>{h.event}</td>
                    <td>{h.source}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          }
        >
          <div>
            <AgeString ts={lastHistoryEvent.ts} ago lang="fr-FR" />
          </div>
        </Tooltip>
      ) : undefined}
      <ItemSticker $color={orderIdSticker.color}>{orderIdSticker.msg}</ItemSticker>
      {
        <DeleteButton onClickAsync={handleDeleteClick} disabled={!canDelete}>
          <SvgIcon name="Trash" color="#ffffff" size={24} />
        </DeleteButton>
      }
    </Wrapper>
  );
};
HoobiizStockEntryForm.displayName = 'HoobiizStockEntryForm';

const Wrapper = styled(FormRow)`
  position: relative;
  border: solid 2px ${p => p.theme.main.accentColor};
  border-radius: 8px;
  align-items: center;
  padding: 8px 8px 8px 58px;
`;
const Title = styled.div``;

const Band = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 40px;
  background-color: ${p => p.theme.main.accentColor};
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeleteButton = styled(Button)`
  height: 48px;
  width: 48px;
  padding: 0;
`;

const Content = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-grow: 1;
  flex-wrap: wrap;
`;

const Table = styled.table`
  border-collapse: collapse;
  thead {
    font-weight: 600;
  }
  td,
  th {
    padding: 2px 6px;
  }
`;

//

const UploadTicketForm = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const UploadLineWrapper = styled.div`
  padding: 8px;
  border: 1px solid ${Colors.Grey};
  border-radius: 4px;
`;

const UploadTicketButton = styled(ButtonAsLink)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const HiddenInput = styled.input`
  display: none;
`;
