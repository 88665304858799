import {FC} from 'react';
import styled from 'styled-components';

import {HoobiizStockItem} from '@shared/dynamo_model';
import {IS_PRODUCTION_ENV} from '@shared/env_constants';

type StockInfo = Pick<HoobiizStockItem, 'quantity' | 'remaining' | 'bought' | 'reserved'>;

interface HoobiizStockQuantitiesTableProps {
  stock: StockInfo;
}

export const HoobiizStockQuantitiesTable: FC<HoobiizStockQuantitiesTableProps> = props => {
  const {stock} = props;
  const hasReserved = stock.reserved > 0;

  return (
    <Table>
      <thead>
        <tr>
          <th title={`Nombre de billets achetés${hasReserved ? ` (+ en cours de paiement)` : ''}`}>
            Acheté
          </th>
          <th title="Nombre de billets disponibles à l'achat">Dispo.</th>
          {IS_PRODUCTION_ENV ? <></> : <th title="Nombre total de billets">Total</th>}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{`${stock.bought}${hasReserved ? ` (+${stock.reserved.toLocaleString()})` : ''}`}</td>
          <td>{stock.remaining.toLocaleString()}</td>
          {IS_PRODUCTION_ENV ? <></> : <td>{stock.quantity.toLocaleString()}</td>}
        </tr>
      </tbody>
    </Table>
  );
};

HoobiizStockQuantitiesTable.displayName = 'HoobiizStockQuantitiesTable';

const Table = styled.table`
  border-collapse: collapse;
  border: solid 2px ${p => p.theme.main.accentColor};
  border-top: none;
  width: max-content;

  & > tbody > tr {
    background-color: transparent;
    &:nth-child(even) {
      background-color: #00000011;
    }
    &:hover {
      cursor: pointer;
      background-color: #00000022;
    }
  }

  & > thead > tr > th {
    font-size: 14px;
    font-weight: 600;
    padding: 6px 12px;
    background: ${p => p.theme.main.accentColor};
    color: #ffffff;
    vertical-align: middle;
    text-align: center;
  }

  & > tbody > tr > td {
    padding: 6px 12px;
    white-space: nowrap;
    vertical-align: middle;
    text-align: center;
  }
`;
