import {FC, MouseEventHandler, useCallback, useEffect, useState} from 'react';
import {useTheme} from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizUserGroupId} from '@shared/dynamo_model';
import {HoobiizGroupUser} from '@shared/model/hoobiiz/hoobiiz_users';

import {apiCall} from '@shared-frontend/api';
import {Input} from '@shared-frontend/components/core/input_v2';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {notifyError} from '@shared-frontend/lib/notification';

import {
  Table,
  TableHeaderCell,
  TableHeaderText,
  TableLineCellWithImage,
} from '@src/components/admin/table_fragment';
import {Pagination} from '@src/components/core/pagination';
import {adminInputTheme} from '@src/components/core/theme';

interface CseAdminUsersTableProps {
  groupId: HoobiizUserGroupId;
}

export const CseAdminUsersTable: FC<CseAdminUsersTableProps> = ({groupId}) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<HoobiizGroupUser[]>([]);
  const [sort, setSort] = useState<{name: keyof HoobiizGroupUser; desc: boolean}>({
    name: 'email',
    desc: true,
  });

  useEffect(() => {
    setIsLoading(true);
    apiCall(HoobiizApi, '/cse-admin/list-users-in-group', {groupId})
      .then(res => {
        setUsers(res.items);
        setIsLoading(false);
      })
      .catch(err => {
        notifyError(err, {message: 'Erreur lors du chargement des bénéficiaires'});
        setIsLoading(false);
      });
  }, [groupId]);

  // When clicking a column to change the sorting
  const handleHeaderClick = useCallback<MouseEventHandler>(evt => {
    const name = evt.currentTarget.getAttribute('data-sort') as keyof HoobiizGroupUser;
    setSort(sort => ({
      name,
      desc: sort.name === name && !sort.desc,
    }));
  }, []);

  // Utility to generate the sort icon on the column headers
  const sortIcon = useCallback(
    (sortName: keyof HoobiizGroupUser) => {
      const {name, desc} = sort;
      if (name !== sortName) {
        return <></>;
      }
      return (
        <SvgIcon
          name={desc ? 'ChevronUp' : 'ChevronDown'}
          size={12}
          color={theme.main.accentTextColor}
        />
      );
    },
    [sort, theme.main.accentTextColor]
  );

  const [searchQuery, setSearchQuery] = useState('');

  return (
    <>
      <Input
        placeholder="Filtrer"
        value={searchQuery}
        syncState={setSearchQuery}
        overrides={adminInputTheme}
        width="100%"
      />
      <Pagination>{users.length.toLocaleString()} bénéficiaires</Pagination>
      <Table $isLoading={isLoading}>
        {/* HEADER */}
        <thead>
          <tr>
            <th>
              <TableHeaderCell $clickable onClick={handleHeaderClick} data-sort={'email'}>
                <TableHeaderText>Email</TableHeaderText>
                {sortIcon('email')}
              </TableHeaderCell>
            </th>
            <th>
              <TableHeaderCell $clickable onClick={handleHeaderClick} data-sort={'firstName'}>
                <TableHeaderText>Prénom</TableHeaderText>
                {sortIcon('firstName')}
              </TableHeaderCell>
            </th>
            <th>
              <TableHeaderCell $clickable onClick={handleHeaderClick} data-sort={'lastName'}>
                <TableHeaderText>Nom</TableHeaderText>
                {sortIcon('lastName')}
              </TableHeaderCell>
            </th>
            <th>
              <TableHeaderCell $clickable onClick={handleHeaderClick} data-sort={'phoneNumber'}>
                <TableHeaderText>Téléphone</TableHeaderText>
                {sortIcon('phoneNumber')}
              </TableHeaderCell>
            </th>
            <th>
              <TableHeaderCell $clickable onClick={handleHeaderClick} data-sort={'role'}>
                <TableHeaderText>Rôle</TableHeaderText>
                {sortIcon('role')}
              </TableHeaderCell>
            </th>
          </tr>
        </thead>
        {/* BODY */}
        <tbody>
          {users
            .filter(user => {
              if (searchQuery.length === 0) {
                return true;
              }
              const s = searchQuery.toLocaleLowerCase();
              return (
                user.email.toLocaleLowerCase().includes(s) ||
                user.firstName.toLocaleLowerCase().includes(s) ||
                user.lastName.toLocaleLowerCase().includes(s) ||
                user.phoneNumber.toLocaleLowerCase().includes(s) ||
                user.role.toLocaleLowerCase().includes(s)
              );
            })
            .sort((a, b) => {
              const {name, desc} = sort;
              return desc ? a[name].localeCompare(b[name]) : b[name].localeCompare(a[name]);
            })
            .map(user => {
              return (
                <tr key={user.userId}>
                  <td>
                    <TableLineCellWithImage $noWrapp $overflow="hidden">
                      {user.email}
                    </TableLineCellWithImage>
                  </td>
                  <td>
                    <TableLineCellWithImage $noWrapp $overflow="hidden">
                      {user.firstName}
                    </TableLineCellWithImage>
                  </td>
                  <td>
                    <TableLineCellWithImage $noWrapp $overflow="hidden">
                      {user.lastName}
                    </TableLineCellWithImage>
                  </td>
                  <td>
                    <TableLineCellWithImage $noWrapp $overflow="hidden">
                      {user.phoneNumber}
                    </TableLineCellWithImage>
                  </td>
                  <td>
                    <TableLineCellWithImage $noWrapp $overflow="hidden">
                      {user.role}
                    </TableLineCellWithImage>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </>
  );
};

CseAdminUsersTable.displayName = 'CseAdminUsersTable';
