import {FC, useCallback, useEffect, useMemo} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {ApiDef} from '@shared/api/registry';
import {HoobiizOrderStatus} from '@shared/dynamo_model';
import {values} from '@shared/lib/map_utils';

import {apiCall} from '@shared-frontend/api';
import {Button} from '@shared-frontend/components/core/button';
import {notifyError} from '@shared-frontend/lib/notification';
import {useMemoRef} from '@shared-frontend/lib/use_memo_ref';
import {useStateRef} from '@shared-frontend/lib/use_state_ref';

import {AdminOrdersTable} from '@src/components/admin/order/admin_orders_table';

interface AdminOrderExplorerPageProps {}

type OrderItem = ApiDef<typeof HoobiizApi>['/admin/list-orders']['res']['items'][0];

// How many orders we load at a time for each status
const LIMIT = 100;

const orderStatuses = Object.values(HoobiizOrderStatus).filter(
  s => typeof s === 'number'
) as HoobiizOrderStatus[];

export const AdminOrderExplorerPage: FC<AdminOrderExplorerPageProps> = () => {
  const [itemsByStatus, setItemsByStatus, itemsByStatusRef] = useStateRef<
    Partial<
      Record<
        HoobiizOrderStatus,
        {
          items: OrderItem[];
          nextPageToken?: string;
          isLoading: boolean;
        }
      >
    >
  >({});

  // Aggregate order items from all statuses
  const [items, itemsRef] = useMemoRef(() => {
    // Get items across all status and add `isLastAndHasMore` info
    // (indicating if the item is the last of the array for the status, but there are more
    // items that can be loaded)
    const allItems = [
      ...values(itemsByStatus).flatMap(data => {
        return (data?.items ?? []).map((item, i, arr) => {
          const isLast = i === arr.length - 1;
          const hasMore = data?.nextPageToken !== undefined;
          const isLastAndHasMore = isLast && hasMore;
          return {item, isLastAndHasMore};
        });
      }),
    ];

    // Sort items by date (latest order first)
    allItems.sort((a, b) => b.item.orderItem.startedAt - a.item.orderItem.startedAt);

    // Trim items to only get items with `isLastAndHasMore: false` + the first item with `isLastAndHasMore: true
    const trimmedItems: OrderItem[] = [];
    for (const {item, isLastAndHasMore} of allItems) {
      trimmedItems.push(item);
      if (isLastAndHasMore) {
        break;
      }
    }

    return trimmedItems;
  }, [itemsByStatus]);

  const loadOrdersByStatus = useCallback(
    async (status: HoobiizOrderStatus) => {
      const {items, nextPageToken} = itemsByStatusRef.current[status] ?? {};
      // If we have items but no nextPageToken, there is nothing left to load
      if (items !== undefined && nextPageToken === undefined) {
        return;
      }

      // If we have more than `LIMIT` items that are after the last items of the aggregated items list,
      // we don't need to load more.
      const lastItem = itemsRef.current.at(-1)?.orderItem;
      if (items) {
        const indexOfFirstItemAfterLastItem = items.findIndex(
          ({orderItem}) => !lastItem || orderItem.startedAt < lastItem.startedAt
        );
        const itemsAfterLastItemCount =
          indexOfFirstItemAfterLastItem === -1
            ? 0
            : items.length - indexOfFirstItemAfterLastItem + 1;
        if (itemsAfterLastItemCount >= LIMIT) {
          return;
        }
      }

      // Mark `isLoading: true`
      setItemsByStatus(current => ({
        ...current,
        [status]: {items, nextPageToken, isLoading: true},
      }));
      await apiCall(HoobiizApi, '/admin/list-orders', {
        status,
        limit: LIMIT,
        nextPageToken,
      })
        .then(res => {
          // Update the data and mark `isLoading: false`
          setItemsByStatus(current => ({
            ...current,
            [status]: {
              items: [...(current[status]?.items ?? []), ...res.items].sort(
                (a, b) => b.orderItem.startedAt - a.orderItem.startedAt
              ),
              nextPageToken: res.nextPageToken,
              isLoading: false,
            },
          }));
        })
        .catch((err: unknown) => {
          // Mark `isLoading: false`
          setItemsByStatus(current => ({
            ...current,
            [status]: {items, nextPageToken, isLoading: false},
          }));
          throw err;
        });
    },
    [itemsByStatusRef, itemsRef, setItemsByStatus]
  );

  const loadOrders = useCallback(() => {
    Promise.all(orderStatuses.map(async status => loadOrdersByStatus(status))).catch(
      (err: unknown) => {
        notifyError(err, {message: 'Échec du chargement des commandes'});
      }
    );
  }, [loadOrdersByStatus]);

  useEffect(() => loadOrders(), [loadOrders]);

  // Check if there are at least one `isLoading: true`
  const isLoading = useMemo(() => {
    for (const status of orderStatuses) {
      if (itemsByStatus[status]?.isLoading) {
        return true;
      }
    }
    return false;
  }, [itemsByStatus]);

  // Check if there are more items that could be loaded
  const hasMore = useMemo(() => {
    for (const status of orderStatuses) {
      if (!itemsByStatus[status] || itemsByStatus[status]?.nextPageToken !== undefined) {
        return true;
      }
    }
    return false;
  }, [itemsByStatus]);

  return (
    <Wrapper>
      {isLoading && items.length === 0 ? (
        <div>Chargements des commandes...</div>
      ) : (
        <AdminOrdersTable items={items} />
      )}
      {!isLoading && !hasMore ? (
        <></>
      ) : (
        <Button loading={isLoading} disabled={!hasMore} onClick={loadOrders}>
          Charger plus de commandes
        </Button>
      )}
    </Wrapper>
  );
};

AdminOrderExplorerPage.displayName = 'AdminOrderExplorerPage';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 32px;
`;
