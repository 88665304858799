import {FC} from 'react';
import styled from 'styled-components';

import {CurrencyAmount, HoobiizPrices} from '@shared/dynamo_model';
import {roundCents} from '@shared/lib/hoobiiz/currency_amount';
import {baseHoobiizPrices, decreaseHoobiizPrices} from '@shared/lib/hoobiiz/hoobiiz_prices';

import {useResponsive} from '@shared-frontend/lib/use_responsive';

import {HoobiizPricesView} from '@src/components/ui/hoobiiz_prices';

interface TotalProps {
  itemCount: number;
  total: HoobiizPrices;
  fees: CurrencyAmount;
}

export const Total: FC<TotalProps> = ({itemCount, total, fees}) => {
  const {isMobile} = useResponsive();

  const hasFees = roundCents(fees.cents) > 0;

  const articleCountStr = `${itemCount} article${itemCount === 1 ? '' : 's'}`;

  if (hasFees) {
    return (
      <Wrapper>
        <TotalLine key="subtotal" $isMobile={isMobile}>
          <TotalLabel>
            {`Sous-total `}
            <ArticleCount $isMobile={isMobile}>({articleCountStr})</ArticleCount>
          </TotalLabel>
          <HoobiizPricesView prices={decreaseHoobiizPrices(total, fees)} />
        </TotalLine>
        <TotalLine key="fees" $isMobile={isMobile}>
          <FeesLabel>{`Frais de gestion`}</FeesLabel>
          <HoobiizPricesView prices={baseHoobiizPrices(fees)} />
        </TotalLine>
        <Separator key="sep" />
        <TotalLine key="total" $isMobile={isMobile}>
          <TotalLabel>Total</TotalLabel>
          <HoobiizPricesView prices={total} />
        </TotalLine>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <TotalLine key="total" $isMobile={isMobile}>
        <TotalLabel>
          {`Total `}
          <ArticleCount $isMobile={isMobile}>({articleCountStr})</ArticleCount>
        </TotalLabel>
        <HoobiizPricesView prices={total} />
      </TotalLine>
    </Wrapper>
  );
};

Total.displayName = 'Total';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TotalLine = styled.div<{$isMobile: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-size: ${({$isMobile}) => ($isMobile ? '16px' : '24px')};
  gap: 28px;
`;
const TotalLabel = styled.div``;
const FeesLabel = styled.div`
  font-style: italic;
  font-size: 15px;
  color: #777;
`;
const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin: 8px 0;
  background: #e7e7e7;
`;

const ArticleCount = styled.span<{$isMobile: boolean}>`
  display: ${({$isMobile}) => ($isMobile ? 'block' : 'inline')};
`;
