import {FC, useEffect} from 'react';
import styled from 'styled-components';

import {LoadingIndicator} from '@shared-frontend/components/core/loading_indicator';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';

import {Colors} from '@src/components/core/theme_base';
import {HoobiizUserLocation} from '@src/lib/stores';

interface HoobiizSearchLocationAroundMeProps {
  location: HoobiizUserLocation;
  onChange: (newLocation: HoobiizUserLocation) => void;
  disabled?: boolean;
}

export const HoobiizSearchLocationAroundMe: FC<HoobiizSearchLocationAroundMeProps> = props => {
  const {location, onChange, disabled} = props;

  useEffect(() => {
    if (!disabled && location.type === 'init') {
      onChange({type: 'pending'});
      navigator.geolocation.getCurrentPosition(
        ({coords}) => onChange({type: 'success', lat: coords.latitude, lng: coords.longitude}),
        err => onChange({type: 'error', err: err.message})
        // {maximumAge: 3600 * 1000}
      );
    }
  }, [disabled, location.type, onChange]);

  return (
    <Wrapper>
      Autour de moi
      {location.type === 'pending' ? <LoadingIndicator size={18} thickness={3} /> : <></>}
      {location.type === 'success' ? (
        <SvgIcon name="Check" color={Colors.Green} size={16} />
      ) : (
        <></>
      )}
      {location.type === 'error' ? <Error>Échec de la localisation</Error> : <></>}
    </Wrapper>
  );
};

HoobiizSearchLocationAroundMe.displayName = 'HoobiizSearchLocationAroundMe';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
`;

const Error = styled.div`
  font-style: italic;
  font-weight: 600;
  color: ${Colors.RedLight};
  font-size: 13px;
  white-space: break-spaces;
`;
