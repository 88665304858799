import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {NO_TIMEOUT} from '@shared/constants/uncategorized_constants';
import {
  HoobiizStockId,
  HoobiizStockItem,
  HoobiizStockReservationFixed,
  HoobiizStockReservationType,
  HoobiizStockWeeklyTemplateId,
  HoobiizStockWeeklyTemplateItem,
  HoobiizTicketInfo,
  HoobiizTicketInfoOption,
  HoobiizTicketInfoOptionId,
} from '@shared/dynamo_model';
import {paddings} from '@shared/frontends/frontend_theme_utils';
import {currencyAmountToString} from '@shared/lib/hoobiiz/currency_amount';
import {isStockWeeklyTemplateId} from '@shared/lib/hoobiiz/hoobiiz_ids';
import {randomStringUnsafe} from '@shared/lib/rand';
import {asNumber} from '@shared/lib/type_utils';
import {FullItem} from '@shared/model/search_tables';

import {apiCall} from '@shared-frontend/api';
import {Button, ButtonAsLink} from '@shared-frontend/components/core/button';
import {Input} from '@shared-frontend/components/core/input_v2';
import {Select} from '@shared-frontend/components/core/select';
import {Textarea} from '@shared-frontend/components/core/textarea_v2';
import {triggerUrlFileDownload} from '@shared-frontend/lib/download';
import {notifyError} from '@shared-frontend/lib/notification';

import {HoobiizStockReservationFixedForm} from '@src/components/admin/activity_stock/hoobiiz_stock_reservation_fixed_form';
import {
  FormColumn,
  FormColumnAuto,
  FormColumnFull,
  FormRow,
} from '@src/components/admin/form/form_fragments';
import {adminInputTheme} from '@src/components/core/theme';
import {Colors} from '@src/components/core/theme_base';
interface HoobiizStockPdfModalProps {
  stockId: HoobiizStockId | HoobiizStockWeeklyTemplateId;
}

export const HoobiizStockPdfModal: FC<HoobiizStockPdfModalProps> = props => {
  const {stockId} = props;

  //
  // Load data
  //

  const [data, setData] = useState<
    | {
        stock: HoobiizStockItem | HoobiizStockWeeklyTemplateItem;
        activity: FullItem<'HoobiizActivity'>;
      }
    | undefined
  >(undefined);
  useEffect(() => {
    setData(undefined);

    const p = isStockWeeklyTemplateId(stockId)
      ? apiCall(HoobiizApi, '/admin/get-stock-weekly-template', {stockId})
      : apiCall(HoobiizApi, '/admin/get-stock', {stockId});

    p.then(({stock}) => {
      apiCall(HoobiizApi, '/admin/search/get', {
        table: 'HoobiizActivity',
        id: stock.activityId,
        mode: 'full',
        permissions: [],
      })
        .then(({item}) => setData({stock, activity: item as FullItem<'HoobiizActivity'>}))
        .catch(err => {
          notifyError(err, {
            message: `Échec de la récupération des infos de l'activité`,
          });
        });
    }).catch(err => {
      notifyError(err, {
        message: `Échec de la récupération des infos du stock`,
      });
    });
  }, [stockId]);

  //
  // Track ticket info, options, and quantities
  //

  const [quantity, setQuantity] = useState(1);
  const [ticketInfo, setTicketInfo] = useState<HoobiizTicketInfo | undefined>(
    data?.stock.ticketInfo[0]
  );
  const [options, setOptions] = useState<
    Record<HoobiizTicketInfoOptionId, {quantity: number; option: HoobiizTicketInfoOption}>
  >({});

  // Refresh ticket info when data changes
  useEffect(() => setTicketInfo(data?.stock.ticketInfo[0]), [data]);

  // Refresh options when ticket info changes
  useEffect(() => {
    setOptions(
      Object.fromEntries((ticketInfo?.options ?? []).map(o => [o.id, {quantity: 0, option: o}]))
    );
  }, [ticketInfo]);

  const handleOptionQuantityChange = useCallback(
    (optionQuantity: number, evt: React.ChangeEvent<HTMLInputElement>) => {
      const optionId = evt.currentTarget.getAttribute('data-option-id') ?? '';
      const optionData = options[optionId as HoobiizTicketInfoOptionId];
      if (optionData) {
        setOptions(prev => ({...prev, [optionId]: {...optionData, quantity: optionQuantity}}));
      }
    },
    [options]
  );

  //
  // Reservation for template
  //

  const [templateReservation, setTemplateReservation] = useState<
    HoobiizStockReservationFixed | undefined
  >(undefined);

  useEffect(() => {
    if (!data) {
      return;
    }
    if ('weekday' in data.stock) {
      const start = new Date();
      start.setHours(data.stock.timeOfDay.startHour, data.stock.timeOfDay.startMinute, 0, 0);
      const end = new Date(start);
      end.setHours(data.stock.timeOfDay.startHour + 1, data.stock.timeOfDay.startMinute, 0, 0);
      setTemplateReservation({
        type: HoobiizStockReservationType.Fixed,
        period: {startTs: start.getTime(), endTs: end.getTime()},
      });
    } else {
      setTemplateReservation(undefined);
    }
  }, [data]);

  //
  // Track codes
  //

  const [codes, setCodes] = useState('');
  const qrCodes = useMemo(() => {
    return codes
      .split('\n')
      .map(code => code.trim())
      .filter(code => code.length > 0);
  }, [codes]);

  const [randomCodeCount, setRandomCodeCount] = useState(1);
  const handleRandomCodeCountClick = useCallback(() => {
    setCodes(
      [...new Array(randomCodeCount)].map(() => randomStringUnsafe(10).toUpperCase()).join('\n')
    );
  }, [randomCodeCount]);
  useEffect(handleRandomCodeCountClick, [handleRandomCodeCountClick]);

  //
  // Generate PDFs
  //

  const handleGeneratePdfClick = useCallback(async () => {
    if (!data || !ticketInfo) {
      return;
    }

    const {activity, stock} = data;
    const res = await apiCall(
      HoobiizApi,
      '/admin/generate-pdf',
      {
        activityId: activity.id,
        stocks:
          'reservation' in stock
            ? [{id: stock.id, reservation: stock.reservation}]
            : templateReservation
              ? [
                  {
                    id: stock.id,
                    reservation: templateReservation,
                  },
                ]
              : [],
        ticketInfo,
        quantity,
        options: Object.values(options)
          .filter(o => o.quantity > 0)
          .map(({quantity, option}) => ({
            optionId: option.id,
            quantity,
          })),
        codes: qrCodes,
      },
      {timeout: NO_TIMEOUT}
    );
    triggerUrlFileDownload({url: res.url});
  }, [data, options, qrCodes, quantity, templateReservation, ticketInfo]);

  //
  // Render
  //

  if (data === undefined) {
    return (
      <Wrapper>
        <Title>Chargement...</Title>
      </Wrapper>
    );
  }

  const {activity, stock} = data;
  return (
    <Wrapper>
      <Title>Générateur de PDFs</Title>
      <SubTitle>Activité : {activity.label}</SubTitle>
      <Separator />
      <FormRow>
        <FormColumn>
          <Select
            value={ticketInfo}
            syncState={setTicketInfo}
            label="Ticket"
            placeholder="--- Sélectionnez un ticket ---"
            values={stock.ticketInfo.map(t => ({
              label: `${t.label} (${currencyAmountToString(t.youpiizPrice)})`,
              value: t,
            }))}
            overrides={adminInputTheme}
          />
        </FormColumn>
        <FormColumnAuto>
          <Input
            label="Qté"
            value={quantity}
            syncState={setQuantity}
            fromString={asNumber}
            type="number"
            width={64}
            min={1}
            overrides={adminInputTheme}
          />
        </FormColumnAuto>
      </FormRow>
      {Object.values(options).map(({quantity, option}) => (
        <FormRow key={option.id}>
          <FormColumn>
            <Input
              readOnly
              label="Option"
              value={`${option.label} (${currencyAmountToString(option.youpiizPrice)})`}
              overrides={adminInputTheme}
            />
          </FormColumn>
          <FormColumnAuto>
            <Input
              value={quantity}
              label={`Qté`}
              data-option-id={option.id}
              syncStateWithEvent={handleOptionQuantityChange}
              fromString={asNumber}
              type="number"
              width={64}
              overrides={adminInputTheme}
              min={0}
            />
          </FormColumnAuto>
        </FormRow>
      ))}
      {templateReservation ? (
        <HoobiizStockReservationFixedForm
          reservation={templateReservation}
          onChange={setTemplateReservation}
        />
      ) : (
        <></>
      )}
      <FormColumnFull>
        <StyledTextarea
          value={codes}
          syncState={setCodes}
          label={
            <CodeLabel>
              <div>Codes (1 ligne = 1 code = 1 PDF)</div>
              <FormRow $gap={6}>
                <CodeInput
                  value={randomCodeCount}
                  syncState={setRandomCodeCount}
                  fromString={asNumber}
                  width={30}
                  overrides={{fontSize: 12, ...paddings(0), height: 24}}
                />
                <ButtonAsLink onClick={handleRandomCodeCountClick}>codes aléatoires</ButtonAsLink>
              </FormRow>
            </CodeLabel>
          }
          overrides={{...adminInputTheme, paddingTop: 16, paddingBottom: 16}}
          height={250}
        />
      </FormColumnFull>
      <Button onClickAsync={handleGeneratePdfClick}>
        {`Générez ${qrCodes.length} PDF${qrCodes.length > 1 ? 's' : ''}`}
      </Button>
    </Wrapper>
  );
};

HoobiizStockPdfModal.displayName = 'HoobiizStockPdfModal';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  overflow-y: auto;
  padding: 32px;
  background-color: ${Colors.White};
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`;

const SubTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: #555;
  text-align: center;
`;

const StyledTextarea = styled(Textarea)`
  font-family: monospace;
`;

const CodeLabel = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CodeInput = styled(Input)`
  text-align: center;
`;

const Separator = styled.div`
  height: 1px;
  background-color: ${Colors.LightGrey2};
  margin: 16px 30%;
  flex-shrink: 0;
`;
