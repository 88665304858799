import {FC, useCallback, useRef, useState} from 'react';

import {
  HoobiizOpeningHours,
  HoobiizStockModeAdminConfirm,
  HoobiizStockModeType,
  HoobiizStockWeeklyTemplateId,
} from '@shared/dynamo_model';
import {removeUndefined} from '@shared/lib/type_utils';

import {HoobiizStockTermsForm} from '@src/components/admin/activity_stock/hoobiiz_stock_terms_form';
import {
  HoobiizTicketWeeklySchedule,
  HoobiizTicketWeeklyScheduleInfo,
  mergeTicketInfoAndInitialData,
} from '@src/components/admin/activity_stock/hoobiiz_ticket_weekly_schedule';
import {FormColumnFull, FormWrapper} from '@src/components/admin/form/form_fragments';

export interface FixedAndAdminConfirmTicket {
  id?: HoobiizStockWeeklyTemplateId;
  template?: {
    weeklyScheduleInfo: HoobiizTicketWeeklyScheduleInfo;
    mode: HoobiizStockModeAdminConfirm;
    terms: string;
  };
}

interface ActivityStockFixedAndAdminConfirmFormProps {
  vendorHours: HoobiizOpeningHours;
  initialData?: FixedAndAdminConfirmTicket[];
  onChange: (tickets: FixedAndAdminConfirmTicket[] | undefined) => void;
}

export const ActivityStockFixedAndAdminConfirmForm: FC<
  ActivityStockFixedAndAdminConfirmFormProps
> = props => {
  const {initialData, vendorHours, onChange} = props;
  const [terms, setTerms] = useState<string>(initialData?.[0]?.template?.terms ?? '');

  const initialDataRef = useRef(initialData);

  const handleTicketsChange = useCallback(
    (tickets: HoobiizTicketWeeklyScheduleInfo[] | undefined) => {
      if (tickets === undefined) {
        return onChange(undefined);
      }
      onChange(
        mergeTicketInfoAndInitialData(
          initialDataRef.current,
          tickets,
          {
            type: HoobiizStockModeType.AdminConfirm,
          },
          terms
        )
      );
    },
    [onChange, terms]
  );

  return (
    <FormWrapper>
      <HoobiizStockTermsForm terms={terms} onChange={setTerms} />
      <FormColumnFull>
        <HoobiizTicketWeeklySchedule
          initialData={
            initialData
              ? removeUndefined(initialData.map(d => d.template?.weeklyScheduleInfo))
              : undefined
          }
          vendorHours={vendorHours}
          onChange={handleTicketsChange}
        />
      </FormColumnFull>
    </FormWrapper>
  );
};
ActivityStockFixedAndAdminConfirmForm.displayName = 'ActivityStockFixedAndAdminConfirmForm';
