import {FC, useEffect} from 'react';

import {HoobiizActivityAddressType, HoobiizActivityAddressVendor} from '@shared/dynamo_model';

interface ActivityAddressVendorFormProps {
  initialData?: HoobiizActivityAddressVendor;
  onChange: (newVal: HoobiizActivityAddressVendor | undefined) => void;
}

export const ActivityAddressVendorForm: FC<ActivityAddressVendorFormProps> = props => {
  const {onChange} = props;

  useEffect(() => {
    onChange({type: HoobiizActivityAddressType.Vendor});
  }, [onChange]);

  return <></>;
};
ActivityAddressVendorForm.displayName = 'ActivityAddressVendorForm';
