import {FC, useCallback, useEffect} from 'react';
import styled from 'styled-components';

import {HoobiizUserGroupId} from '@shared/dynamo_model';

import {ButtonAsLink} from '@shared-frontend/components/core/button';

import {AdminUserAndGroupCreateSubgroupForm} from '@src/components/admin/user_and_group/admin_user_and_group_create_subgroup_form';
import {
  loadHoobiizUserGroupSubgroups,
  useHoobiizUserGroup,
} from '@src/components/admin/user_and_group/admin_user_and_group_store';
import {
  AdminUserAndGroupTree,
  AdminUserAndGroupTreeProps,
} from '@src/components/admin/user_and_group/admin_user_and_group_tree';

interface AdminUserAndGroupSubGroupsProps extends Omit<AdminUserAndGroupTreeProps, 'groupId'> {
  groupId: HoobiizUserGroupId;
}

export const AdminUserAndGroupSubGroups: FC<AdminUserAndGroupSubGroupsProps> = props => {
  const {groupId, ...opts} = props;
  const group = useHoobiizUserGroup({groupId});

  // Load the next page of subgroups
  const fetchNextPage = useCallback(
    async () => loadHoobiizUserGroupSubgroups({parentGroupId: groupId, isInitialLoad: false}),
    [groupId]
  );

  // Initial load
  useEffect(() => {
    loadHoobiizUserGroupSubgroups({parentGroupId: groupId, isInitialLoad: true}).catch(() => {});
  }, [groupId]);

  if (!group?.subGroups) {
    return <Wrapper>Chargement des groupes...</Wrapper>;
  }

  const {subGroups} = group;

  return (
    <Wrapper>
      <div>
        {subGroups.items?.map(subGroupId => (
          <AdminUserAndGroupTree key={subGroupId} groupId={subGroupId} {...opts} />
        ))}
      </div>
      <AdminUserAndGroupCreateSubgroupForm groupId={groupId} />
      {subGroups.nextPageToken !== undefined ? (
        <Footer>
          <ButtonAsLink key="button" onClickAsync={fetchNextPage}>
            Charger plus de groupes
          </ButtonAsLink>
        </Footer>
      ) : (
        <></>
      )}
    </Wrapper>
  );
};

AdminUserAndGroupSubGroups.displayName = 'AdminUserAndGroupSubGroups';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: 8px;
`;
