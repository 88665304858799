import {FC, useCallback, useRef, useState} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {OK} from '@shared/api/status_codes';
import {HoobiizMediaId} from '@shared/dynamo_model';

import {apiCall} from '@shared-frontend/api';
import {notifyError} from '@shared-frontend/lib/notification';
import {NULL_REF} from '@shared-frontend/lib/react';

import {DropZoneDiv} from '@src/components/admin/form/drop_zone_div';
import {fileListToArray} from '@src/components/admin/form/drop_zone_lib';
import {
  MediaUploadStatus,
  MediaUploadStatusView,
} from '@src/components/admin/form/media_upload_status';

const MAX_FILE_BYTE_SIZE = 10 * 1024 * 1024; // 10 MB

interface MediaUploadProps {
  initialMediaIds?: HoobiizMediaId[];
  multiple?: boolean; // allow to upload multiple files
  onChange?: (statuses: MediaUploadStatus[]) => void;
}

function uploadMedia(file: File, callback: (status: MediaUploadStatus) => void): void {
  const errorHandler = (err: unknown): void => {
    notifyError(err, {silent: true});
    callback({status: 'error', sourceFile: file, error: `Échec lors de l'upload`});
  };
  apiCall(HoobiizApi, '/admin/start-media-upload', {mimeType: file.type})
    .then(({id, url}) => {
      const xhr = new XMLHttpRequest();
      let loaded = false;
      const readyStateComplete = 4;
      xhr.upload.addEventListener('load', () => {
        loaded = true;
      });
      xhr.onreadystatechange = function () {
        if (loaded && this.readyState === readyStateComplete && this.status === OK) {
          callback({status: 'success', id});
        }
      };
      xhr.upload.addEventListener('error', errorHandler);
      xhr.upload.addEventListener('abort', errorHandler);
      xhr.upload.addEventListener('timeout', errorHandler);
      xhr.open('PUT', url, true);
      xhr.setRequestHeader('Content-Type', file.type);
      xhr.send(file);
    })
    .catch(errorHandler);
}

export const MediaUpload: FC<MediaUploadProps> = props => {
  const {initialMediaIds: initialMedia = [], multiple, onChange} = props;
  const [mediaStatuses, setMediaStatuses] = useState<MediaUploadStatus[]>(
    initialMedia.map(id => ({status: 'success', id}))
  );

  // Trigger native upload dialog when the dropzone is clicked
  const uploadInputRef = useRef<HTMLInputElement>(NULL_REF);
  const handleUploadClick = useCallback(() => {
    if (uploadInputRef.current) {
      uploadInputRef.current.click();
    }
  }, []);

  // Callback when the user dropped files directly in the dropzone
  const handleFileDrop = useCallback(
    (files: File[]) => {
      const newStatuses = [...mediaStatuses];
      for (const file of multiple ? files : files.slice(0, 1)) {
        // Only accept image files
        if (!file.type.startsWith('image/')) {
          newStatuses.push({
            status: 'error',
            sourceFile: file,
            error: `Le fichier n'est pas une image`,
          });
          continue;
        }
        // Filter out files that are too big
        if (file.size > MAX_FILE_BYTE_SIZE) {
          newStatuses.push({
            status: 'error',
            sourceFile: file,
            error: `L'image est trop volumineuse (max. 10 Mo)`,
          });
          continue;
        }
        // All check passed, start the upload
        newStatuses.push({status: 'in-progress', sourceFile: file});
        const index = newStatuses.length - 1;
        uploadMedia(file, newStatus => {
          setMediaStatuses(mediaStatuses => {
            const newStatuses = mediaStatuses.map((s, i) => (i === index ? newStatus : s));
            onChange?.(newStatuses);
            return newStatuses;
          });
        });
      }
      setMediaStatuses(newStatuses);
      onChange?.(newStatuses);
    },
    [mediaStatuses, multiple, onChange]
  );

  // Callback when the user selected files with the native file dialog
  const handleFileChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    evt => {
      const {files} = evt.currentTarget;
      // eslint-disable-next-line no-null/no-null
      if (files === null) {
        return;
      }
      // eslint-disable-next-line no-null/no-null
      evt.currentTarget.files = null;
      handleFileDrop(fileListToArray(files));
    },
    [handleFileDrop]
  );

  // Callback when the user click to delete an image
  const handleDeleteClick = useCallback(
    (image: MediaUploadStatus) => {
      setMediaStatuses(mediaStatuses => {
        const newStatuses = mediaStatuses.filter(imageStatus => imageStatus !== image);
        onChange?.(newStatuses);
        return newStatuses;
      });
    },
    [onChange]
  );

  // Callback when the user click to move the image before the previous one
  const handleMoveBeforeClick = useCallback(
    (image: MediaUploadStatus) => {
      setMediaStatuses(mediaStatuses => {
        // Get image index
        const imageIndex = mediaStatuses.indexOf(image);
        if (imageIndex <= 0) {
          return mediaStatuses;
        }

        // Clone array and remove the image
        let newStatuses = [...mediaStatuses];
        newStatuses.splice(imageIndex, 1);

        // Recreate array with image at the new index
        newStatuses = [
          ...newStatuses.slice(0, imageIndex - 1),
          image,
          ...newStatuses.slice(imageIndex - 1),
        ];

        onChange?.(newStatuses);
        return newStatuses;
      });
    },
    [onChange]
  );

  // Callback when the user click to move the image before the previous one
  const handleMoveAfterClick = useCallback(
    (image: MediaUploadStatus) => {
      setMediaStatuses(mediaStatuses => {
        // Get image index
        const imageIndex = mediaStatuses.indexOf(image);
        if (imageIndex >= mediaStatuses.length) {
          return mediaStatuses;
        }

        // Clone array and remove the image
        let newStatuses = [...mediaStatuses];
        newStatuses.splice(imageIndex, 1);

        // Recreate array with image at the new index
        newStatuses = [
          ...newStatuses.slice(0, imageIndex + 1),
          image,
          ...newStatuses.slice(imageIndex + 1),
        ];

        onChange?.(newStatuses);
        return newStatuses;
      });
    },
    [onChange]
  );

  const dropZoneShown = mediaStatuses.length === 0 || multiple;

  return (
    <Wrapper>
      <HiddenInput
        type="file"
        multiple={multiple}
        accept="image/*"
        ref={uploadInputRef}
        onChange={handleFileChange}
      />
      {dropZoneShown ? (
        <DropZone onFilesDropped={handleFileDrop} onClick={handleUploadClick}>
          {multiple ? 'Ajouter des images' : 'Sélectionner une image'}
        </DropZone>
      ) : (
        <></>
      )}
      {mediaStatuses.length > 0 ? (
        <MediaUploadStatusWrapper $dropZoneShown={dropZoneShown}>
          {mediaStatuses.map((status, i) => (
            <MediaUploadStatusView
              key={i}
              uploadStatus={status}
              onDeleteClick={handleDeleteClick}
              onMoveBeforeClick={multiple ? handleMoveBeforeClick : undefined}
              onMoveAfterClick={multiple ? handleMoveAfterClick : undefined}
            />
          ))}
        </MediaUploadStatusWrapper>
      ) : (
        <></>
      )}
    </Wrapper>
  );
};
MediaUpload.displayName = 'MediaUpload';

const Wrapper = styled.div``;

const HiddenInput = styled.input`
  display: none;
`;

const DropZone = styled(DropZoneDiv)`
  height: 120px;
`;

const MediaUploadStatusWrapper = styled.div<{$dropZoneShown?: boolean}>`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  ${p => p.$dropZoneShown && 'margin-top: 24px;'}
`;
