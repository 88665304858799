import {FC, JSX, MouseEventHandler, useCallback} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {ApiDef} from '@shared/api/registry';
import {currencyAmountToString} from '@shared/lib/hoobiiz/currency_amount';

import {showRawModal} from '@shared-frontend/components/core/modal';

import {AdminOrderModal} from '@src/components/admin/order/admin_order_modal';
import {HoobiizOrderItemStatus} from '@src/components/ui/hoobiiz_order_item_status';

type OrderItem = ApiDef<typeof HoobiizApi>['/admin/list-orders']['res']['items'][0];

interface AdminOrdersTableProps {
  items: OrderItem[];
  extraRows?: (item: OrderItem, index: number) => JSX.Element[];
}

const dateFormat: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
};

export const AdminOrdersTable: FC<AdminOrdersTableProps> = props => {
  const {items, extraRows} = props;

  const handleRowClick = useCallback<MouseEventHandler>(
    evt => {
      const id = evt.currentTarget.getAttribute('data-order-id');
      const item = items.find(item => item.sanitizedOrderItem.id === id);
      if (!item) {
        return;
      }
      const {orderItem, sanitizedOrderItem, user} = item;
      showRawModal({
        mode: 'slide-down',
        children: (
          <AdminOrderModal
            sanitizedOrderItem={sanitizedOrderItem}
            orderItem={orderItem}
            user={user}
          />
        ),
      });
    },
    [items]
  );

  return (
    <Table>
      <thead>
        <Row $even>
          <HeaderCell>Status</HeaderCell>
          <HeaderCell>Date début</HeaderCell>
          <HeaderCell>Date fin</HeaderCell>
          <HeaderCell>Client</HeaderCell>
          <HeaderCell>Partenaire</HeaderCell>
          <HeaderCell>Activité</HeaderCell>
          <HeaderCell>Ticket</HeaderCell>
          <HeaderNumberCell>Payé</HeaderNumberCell>
        </Row>
      </thead>
      <tbody>
        {items.length === 0 ? (
          <Row $even={false}>
            <Cell colSpan={8}>Aucune commandes</Cell>
          </Row>
        ) : (
          <></>
        )}
        {items.flatMap((item, i) => {
          const startDate = new Date(item.orderItem.startedAt);
          const previousStartTs = items[i - 1]?.orderItem.startedAt;
          const previousStartDate =
            previousStartTs === undefined ? undefined : new Date(previousStartTs);

          const currentMonth = startDate.toLocaleString('fr-FR', {month: 'long'});
          const previousMonth = previousStartDate?.toLocaleString('fr-FR', {month: 'long'});
          const monthRows =
            currentMonth === previousMonth
              ? []
              : [
                  <MonthRow key={currentMonth}>
                    <MonthCell colSpan={8}>{currentMonth.toLocaleUpperCase()}</MonthCell>
                  </MonthRow>,
                ];

          return [
            ...monthRows,
            <OrderRow
              onClick={handleRowClick}
              data-order-id={item.sanitizedOrderItem.id}
              key={item.sanitizedOrderItem.id}
              $even={i % 2 === 1}
            >
              <Cell>
                <HoobiizOrderItemStatus status={item.orderItem.status} />
              </Cell>
              <Cell>{startDate.toLocaleString('fr-FR', dateFormat)}</Cell>
              <Cell>
                {item.orderItem.endedAt === undefined
                  ? '-'
                  : new Date(item.orderItem.endedAt).toLocaleString('fr-FR', dateFormat)}
              </Cell>
              <Cell>{item.user?.email}</Cell>
              <Cell>{item.sanitizedOrderItem.activity.vendor?.name}</Cell>
              <Cell>{item.sanitizedOrderItem.activity.label}</Cell>
              <Cell>
                <div>{`${item.sanitizedOrderItem.ticketInfo.label} (Qté: ${item.sanitizedOrderItem.quantity})`}</div>
                {item.sanitizedOrderItem.options.map(o => (
                  <TicketOption
                    key={o.option.id}
                  >{`Option : ${o.option.label} (Qté: ${o.quantity})`}</TicketOption>
                ))}
              </Cell>
              <NumberCell>
                {currencyAmountToString(item.sanitizedOrderItem.total.price3)}
              </NumberCell>
            </OrderRow>,
            ...(extraRows?.(item, i) ?? []),
          ];
        })}
      </tbody>
    </Table>
  );
};

AdminOrdersTable.displayName = 'AdminOrdersTable';

const Table = styled.table`
  border: solid 2px ${p => p.theme.main.accentColor};
  border-top: none;
`;
const Row = styled.tr<{$even: boolean}>`
  background-color: ${p => (p.$even ? '#00000011' : 'transparent')};
`;
const OrderRow = styled(Row)`
  &:hover {
    cursor: pointer;
    background-color: #00000022;
  }
`;
const HeaderCell = styled.th`
  padding: 8px 12px;
  background: ${p => p.theme.main.accentColor};
  color: #ffffff;
  vertical-align: middle;
`;
const HeaderNumberCell = styled(HeaderCell)`
  text-align: right;
`;
const Cell = styled.td<{$alignRight?: boolean}>`
  padding: 8px 12px;
  white-space: nowrap;
  vertical-align: middle;
  ${p => p.$alignRight && 'text-align: right;'}
`;
const NumberCell = styled(Cell)`
  text-align: right;
`;

const TicketOption = styled.div`
  font-size: 14px;
  color: #777;
`;

const MonthRow = styled.tr``;
const MonthCell = styled.td`
  height: 24px;
  text-align: center;
  vertical-align: middle;
  background: #e2dbc6;
  color: #000000;
  font-size: 11px;
  font-weight: 600;
`;
