import {FC, useCallback, useEffect, useState} from 'react';

import {HoobiizData} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizMediaId} from '@shared/dynamo_model';
import {removeUndefined} from '@shared/lib/type_utils';
import {FullItem} from '@shared/model/search_tables';

import {Input} from '@shared-frontend/components/core/input_v2';
import {Textarea} from '@shared-frontend/components/core/textarea_v2';

import {FormColumnFull, FormLabel, FormRow} from '@src/components/admin/form/form_fragments';
import {MediaUpload} from '@src/components/admin/form/media_upload';
import {MediaUploadStatus} from '@src/components/admin/form/media_upload_status';
import {adminInputTheme} from '@src/components/core/theme';

interface CommonCat {
  name: string;
  description: string;
  mediaId: HoobiizMediaId;
  media?: FullItem<'HoobiizMedia'>;
}

export interface CommonCatItem {
  name: string;
  description: string;
  mediaId: HoobiizMediaId;
}

export type CatInfo = HoobiizData<CommonCatItem>;

interface CategoryFormProps {
  initialData?: CommonCat;
  autoFocus?: boolean;
  onChange: (data: CatInfo | undefined) => void;
}

export const CategoryForm: FC<CategoryFormProps> = props => {
  const {initialData, autoFocus, onChange} = props;

  const [name, setName] = useState(initialData?.name ?? '');
  const [description, setDescription] = useState(initialData?.description ?? '');
  const [mediaId, setMediaId] = useState(initialData?.mediaId);

  useEffect(() => {
    if (mediaId === undefined) {
      return onChange(undefined);
    }
    return onChange({
      name,
      description,
      mediaId,
    });
  }, [mediaId, name, onChange, description]);

  const handleMediaChange = useCallback((statuses: MediaUploadStatus[]) => {
    for (const status of statuses) {
      if (status.status === 'success') {
        setMediaId(status.id);
        return;
      }
    }
  }, []);

  return (
    <FormRow>
      <FormColumnFull>
        <Input
          width="100%"
          value={name}
          syncState={setName}
          placeholder={initialData?.name}
          label="NOM"
          autoFocus={autoFocus}
          overrides={adminInputTheme}
        />
      </FormColumnFull>
      <FormColumnFull>
        <Textarea
          width="100%"
          height={100}
          value={description}
          syncState={setDescription}
          placeholder={initialData?.description}
          label="DESCRIPTION"
          overrides={adminInputTheme}
        />
      </FormColumnFull>
      <FormColumnFull>
        <FormLabel>IMAGE</FormLabel>
        <MediaUpload
          initialMediaIds={removeUndefined([initialData?.mediaId])}
          onChange={handleMediaChange}
        />
      </FormColumnFull>
    </FormRow>
  );
};
CategoryForm.displayName = 'CategoryForm';
