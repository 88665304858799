import {FC, useCallback, useMemo} from 'react';
import styled from 'styled-components';

import {HoobiizStockReservationFixed, HoobiizStockReservationType} from '@shared/dynamo_model';

import {Input} from '@shared-frontend/components/core/input_v2';
import {Label} from '@shared-frontend/components/core/label';

import {DateTimeInput} from '@src/components/admin/form/date_time_input';
import {FormColumnAuto, FormRow} from '@src/components/admin/form/form_fragments';
import {adminInputTheme} from '@src/components/core/theme';

interface HoobiizStockReservationFixedFormProps {
  reservation: HoobiizStockReservationFixed;
  onChange: (reservation: HoobiizStockReservationFixed) => void;
}

const fromString = (val: string): number => {
  const res = parseFloat(val);
  if (isNaN(res)) {
    throw new Error('Invalid value');
  }
  return res;
};

const toString = (val: number): string => {
  return String(val).padStart(2, '0');
};

const ReservationFixed = HoobiizStockReservationType.Fixed;

export const HoobiizStockReservationFixedForm: FC<HoobiizStockReservationFixedFormProps> = ({
  reservation,
  onChange,
}) => {
  // Compute start/end date/hours/minutes
  const {startTs, endTs} = reservation.period;
  const startDate = useMemo(() => new Date(startTs), [startTs]);
  const {startHour, startMinute} = useMemo(
    () => ({startHour: startDate.getHours(), startMinute: startDate.getMinutes()}),
    [startDate]
  );
  const {endHour, endMinute} = useMemo(() => {
    const duration = endTs - startTs;
    const endHour = startHour + Math.floor(duration / (1000 * 60 * 60));
    const endMinute = startMinute + Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
    return {endHour, endMinute};
  }, [endTs, startTs, startHour, startMinute]);

  const handleDayChange = useCallback(
    (ts: number) => {
      const newStart = new Date(ts);
      newStart.setHours(startHour, startMinute, 0, 0);
      onChange({type: ReservationFixed, period: {startTs: newStart.getTime(), endTs}});
    },
    [endTs, onChange, startHour, startMinute]
  );

  const handleStartHourChange = useCallback(
    (val: number) => {
      const newStart = new Date(startDate);
      newStart.setHours(val, startDate.getMinutes(), 0, 0);
      onChange({type: ReservationFixed, period: {startTs: newStart.getTime(), endTs}});
    },
    [endTs, onChange, startDate]
  );

  const handleStartMinuteChange = useCallback(
    (val: number) => {
      const newStart = new Date(startDate);
      newStart.setMinutes(val, 0, 0);
      onChange({type: ReservationFixed, period: {startTs: newStart.getTime(), endTs}});
    },
    [endTs, onChange, startDate]
  );

  const handleEndHourChange = useCallback(
    (val: number) => {
      const newEnd = new Date(startDate);
      newEnd.setHours(val, endMinute, 0, 0);
      onChange({type: ReservationFixed, period: {startTs, endTs: newEnd.getTime()}});
    },
    [startDate, endMinute, onChange, startTs]
  );

  const handleEndMinuteChange = useCallback(
    (val: number) => {
      const newEnd = new Date(startDate);
      newEnd.setMinutes(val, 0, 0);
      onChange({type: ReservationFixed, period: {startTs, endTs: newEnd.getTime()}});
    },
    [startDate, onChange, startTs]
  );

  return (
    <Wrapper>
      <FormRow $gap={10}>
        <FormColumnAuto>
          <StyledLabel value="Date">
            <DateTimeInput
              ts={startTs}
              syncState={handleDayChange}
              noTimePicker
              overrides={adminInputTheme}
              width={150}
            />
          </StyledLabel>
        </FormColumnAuto>
        <FormColumnAuto>
          <StyledLabel value="Heures">
            <PeriodWrapper>
              De
              <PeriodInner>
                <PeriodInput
                  value={startHour}
                  asString={toString}
                  fromString={fromString}
                  syncState={handleStartHourChange}
                  overrides={adminInputTheme}
                />
                h
                <PeriodInput
                  value={startMinute}
                  asString={toString}
                  fromString={fromString}
                  syncState={handleStartMinuteChange}
                  overrides={adminInputTheme}
                />
              </PeriodInner>
              à
              <PeriodInner>
                <PeriodInput
                  value={endHour}
                  asString={toString}
                  fromString={fromString}
                  syncState={handleEndHourChange}
                  overrides={adminInputTheme}
                />
                h
                <PeriodInput
                  value={endMinute}
                  asString={toString}
                  fromString={fromString}
                  syncState={handleEndMinuteChange}
                  overrides={adminInputTheme}
                />
              </PeriodInner>
            </PeriodWrapper>
          </StyledLabel>
        </FormColumnAuto>
      </FormRow>
    </Wrapper>
  );
};

HoobiizStockReservationFixedForm.displayName = 'HoobiizStockReservationFixedForm';

const Wrapper = styled.div`
  display: flex;
`;

const PeriodWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background: #f8f8f8;
  padding: 12px 16px;
  border-radius: 8px;
`;

const PeriodInner = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const PeriodInput = styled(Input)`
  width: 32px;
  height: 32px;
  text-align: center;
  padding: 0;
`;

const StyledLabel = styled(Label)`
  line-height: 120%;
  font-weight: 700;
  color: #14182499;
  padding-left: 16px;
  font-size: calc(18px * 0.8);
  margin-bottom: 0.4em;
`;
