import {FC, useEffect, useState} from 'react';
import styled from 'styled-components';

import {HoobiizApi, HoobiizGroupItem} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizUserGroupId} from '@shared/dynamo_model';

import {apiCall} from '@shared-frontend/api';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {notifyError} from '@shared-frontend/lib/notification';
import {useParams} from '@shared-frontend/lib/use_typed_params';

import {PageWrapper} from '@src/components/admin/page_fragment';
import {CseAdminUsersTable} from '@src/components/cse_admin/users/cse_admin_users_table';

interface CseAdminUsersPageProps {}

export const CseAdminUsersPage: FC<CseAdminUsersPageProps> = () => {
  const {groupId} = useParams<{groupId: HoobiizUserGroupId}>();
  const [group, setGroup] = useState<HoobiizGroupItem>();

  useEffect(() => {
    if (groupId === undefined) {
      notifyError('Missing groupId');
      return;
    }
    apiCall(HoobiizApi, '/cse-admin/get-user-group', {groupId})
      .then(res => setGroup(res.item))
      .catch(err => notifyError(err, {message: 'Erreur lors du chargement du CSE'}));
  }, [groupId]);

  if (groupId === undefined) {
    return <></>;
  }

  return (
    <PageWrapper>
      <Top>Code premium pour rejoindre le CSE : {group?.groupRegistrationCode ?? '...'}</Top>
      <Spacing height={24} />
      <CseAdminUsersTable groupId={groupId} />
    </PageWrapper>
  );
};

CseAdminUsersPage.displayName = 'CseAdminUsersPage';

const Top = styled.div`
  display: flex;
  align-items: center;
`;
