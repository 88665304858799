import {FC, useCallback, useMemo, useState} from 'react';
import {useTheme} from 'styled-components';

import {
  EmailString,
  FrontendUserDataContent,
  HoobiizWhiteLabeling,
  HoobiizWhiteLabelingAuthType,
} from '@shared/dynamo_model';
import {EnrichedHoobiizMedia} from '@shared/lib/hoobiiz/hoobiiz_white_labeling';
import {DeepPartial} from '@shared/lib/type_utils';

import {sharedApiCall} from '@shared-frontend/api';
import {isFrontendUserDataContentValid} from '@shared-frontend/components/auth/frontend_user_data_form';
import {RegisterPageModeDefault} from '@shared-frontend/components/auth/mode_default/register_page_theme_0';
import {ModeHeroAuthWhiteLabeling} from '@shared-frontend/components/auth/mode_hero/mode_hero_template';
import {RegisterPageModeHero} from '@shared-frontend/components/auth/mode_hero/register_page_mode_hero';
import {InputProps} from '@shared-frontend/components/core/input_v2';
import {notifyError} from '@shared-frontend/lib/notification';
import {ComponentClass} from '@shared-frontend/lib/react';

interface RegisterPageProps {
  wrapper: ComponentClass | ComponentClass[] | undefined;
  whiteLabeling: EnrichedHoobiizMedia<DeepPartial<HoobiizWhiteLabeling>> | undefined;
}

export const RegisterPage: FC<RegisterPageProps> = props => {
  const {wrapper, whiteLabeling} = props;
  const [confirmationEmailSent, setConfirmationEmailSent] = useState(false);
  const {
    main: {api},
    auth: {userDataContentType},
  } = useTheme();

  const [email, setEmail] = useState<EmailString | undefined>();
  const [password, setPassword] = useState<string | undefined>();
  const [userData, setUserData] = useState<Partial<FrontendUserDataContent> | undefined>();

  const handleRegisterSubmit = useCallback(async () => {
    if (
      email === undefined ||
      password === undefined ||
      userData === undefined ||
      !isFrontendUserDataContentValid(userDataContentType, userData)
    ) {
      notifyError('Formulaire incomplet, merci de réessayer');
      return;
    }
    return sharedApiCall(api, '/register', {email, password, userData}).then(data => {
      if (data.success) {
        setConfirmationEmailSent(true);
      } else {
        notifyError(`Une erreur s'est produite pendant l'inscription, merci de réessayer`);
      }
    });
  }, [api, email, password, userData, userDataContentType]);

  const emailInputProps = useMemo<InputProps<EmailString | undefined>>(() => {
    return {
      width: '100%',
      type: 'email',
      value: email,
      syncState: setEmail,
      placeholder: 'jean@exemple.com',
      label: 'ADRESSE EMAIL',
      autoComplete: 'email',
      autoFocus: true,
    };
  }, [email, setEmail]);

  const passwordInputProps = useMemo<InputProps<string | undefined>>(() => {
    return {
      width: '100%',
      type: 'password',
      value: password,
      syncState: setPassword,
      label: 'MOT DE PASSE (min. 8 caractères)',
      autoComplete: 'new-password',
    };
  }, [password, setPassword]);

  if (whiteLabeling?.auth?.type === HoobiizWhiteLabelingAuthType.ModeHero) {
    const whiteLabelingModeHero = whiteLabeling.auth as ModeHeroAuthWhiteLabeling;
    return (
      <RegisterPageModeHero
        whiteLabeling={whiteLabelingModeHero}
        confirmationEmailSent={confirmationEmailSent}
        emailInputProps={emailInputProps}
        passwordInputProps={passwordInputProps}
        userData={userData}
        setUserData={setUserData}
        handleRegisterSubmit={handleRegisterSubmit}
      />
    );
  }
  return (
    <RegisterPageModeDefault
      wrapper={wrapper}
      confirmationEmailSent={confirmationEmailSent}
      emailInputProps={emailInputProps}
      passwordInputProps={passwordInputProps}
      userData={userData}
      setUserData={setUserData}
      handleRegisterSubmit={handleRegisterSubmit}
    />
  );
};

RegisterPage.displayName = 'RegisterPage';
