import {FC, useEffect, useState} from 'react';

import {HoobiizTimePeriod} from '@shared/dynamo_model';
import {initialPeriod} from '@shared/lib/hoobiiz/period';

import {DateTimeInput} from '@src/components/admin/form/date_time_input';
import {FormRow} from '@src/components/admin/form/form_fragments';
import {adminInputTheme} from '@src/components/core/theme';

interface HoobiizTimePeriodFormProps {
  initialData?: HoobiizTimePeriod;
  onChange?: (target: HoobiizTimePeriod) => void;
  startLabel?: string;
  endLabel?: string;
}

export const HoobiizTimePeriodForm: FC<HoobiizTimePeriodFormProps> = props => {
  const {initialData, onChange, startLabel, endLabel} = props;

  const initialDataWithDefault = initialData ?? initialPeriod();
  const [startTs, setStartTs] = useState(initialDataWithDefault.startTs);
  const [endTs, setEndTs] = useState(initialDataWithDefault.endTs);

  useEffect(() => onChange?.({startTs, endTs}), [startTs, endTs, onChange]);

  return (
    <FormRow>
      <DateTimeInput
        label={startLabel ?? 'DÉBUT'}
        ts={startTs}
        syncState={setStartTs}
        overrides={adminInputTheme}
      />
      <DateTimeInput
        label={endLabel ?? 'FIN'}
        ts={endTs}
        syncState={setEndTs}
        overrides={adminInputTheme}
      />
    </FormRow>
  );
};
HoobiizTimePeriodForm.displayName = 'HoobiizTimePeriodForm';
