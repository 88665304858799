import {AccountId} from '@shared/dynamo_model';

export enum EnvType {
  Localhost = 'local',
  Development = 'devs',
  Production = 'prod',
}

export const EnvTypeLongName = {
  [EnvType.Localhost]: 'localhost',
  [EnvType.Development]: 'development',
  [EnvType.Production]: 'production',
};

export const allEnvs: EnvType[] = [EnvType.Localhost, EnvType.Development, EnvType.Production];

interface CommonEnvConstants {
  type: EnvType;
  region: string;
  onescaleDomainName: string;
  cidrBlock: string;
  accountId: AccountId;
  stripe: StripeConfig;
  expertTicket: ExpertTicketConfig;
  googleMapsApiKey: string;
}

interface StripeConfig {
  apiPublishableKey: string;
  liveMode: boolean;
}

interface ExpertTicketConfig {
  baseUrl: string;
  partnerId: string;
  apiVersion: string;
}

export interface EnvSecrets {
  stripeApiSecretKey: string;
  stripeWebhookSigningSecret: string;
  expertTicketApiSecretKey: string;
}

export interface LocalEnvConstants extends CommonEnvConstants {
  type: EnvType.Localhost;
  devPort: number;
}
export interface DevsEnvConstants extends CommonEnvConstants {
  type: EnvType.Development;
}
export interface ProdEnvConstants extends CommonEnvConstants {
  type: EnvType.Production;
}

export type EnvConstants = LocalEnvConstants | DevsEnvConstants | ProdEnvConstants;
