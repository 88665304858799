import {FC, useCallback, useEffect, useState} from 'react';

import {
  HoobiizOpeningHours,
  HoobiizStockId,
  HoobiizStockModeVendorConfirm,
  HoobiizStockReservationFlexible,
} from '@shared/dynamo_model';
import {hoobiizTicketInfoId} from '@shared/lib/hoobiiz/hoobiiz_ids';

import {Input} from '@shared-frontend/components/core/input_v2';

import {HoobiizStockModeVendorConfirmForm} from '@src/components/admin/activity_stock/hoobiiz_stock_mode_vendor_confirm_form';
import {HoobiizStockReservationFlexibleForm} from '@src/components/admin/activity_stock/hoobiiz_stock_reservation_flexible_form';
import {HoobiizStockTermsForm} from '@src/components/admin/activity_stock/hoobiiz_stock_terms_form';
import {MaybeTicket} from '@src/components/admin/activity_stock/hoobiiz_ticket_info_form';
import {HoobiizTicketInfoMultiForm} from '@src/components/admin/activity_stock/hoobiiz_ticket_info_multi_form';
import {
  FormColumnAuto,
  FormColumnFull,
  FormLabel,
  FormRow,
  FormWrapper,
} from '@src/components/admin/form/form_fragments';
import {adminInputTheme} from '@src/components/core/theme';

export interface FlexibleAndVendorConfirmTicket {
  id?: HoobiizStockId;
  ticket: {
    reservation: HoobiizStockReservationFlexible;
    mode: HoobiizStockModeVendorConfirm;
    quantity: number;
    ticketInfo: MaybeTicket[];
    terms: string;
  };
}

interface ActivityStockFlexibleAndVendorConfirmFormProps {
  vendorHours: HoobiizOpeningHours;
  initial?: FlexibleAndVendorConfirmTicket;
  onChange?: (ticket: FlexibleAndVendorConfirmTicket) => void;
}

const fromString = (val: string): number => {
  const res = Math.max(0, Math.round(parseFloat(val)));
  if (isNaN(res)) {
    throw new Error('Invalid value');
  }
  return res;
};

export const ActivityStockFlexibleAndVendorConfirmForm: FC<
  ActivityStockFlexibleAndVendorConfirmFormProps
> = props => {
  const {vendorHours, initial, onChange} = props;

  const [reservation, setReservation] = useState(initial?.ticket.reservation);
  const [mode, setMode] = useState<HoobiizStockModeVendorConfirm | undefined>(initial?.ticket.mode);
  const [quantity, setQuantity] = useState(initial?.ticket.quantity ?? 1);

  const [ticketInfo, setTicketInfo] = useState<MaybeTicket[]>(
    initial?.ticket.ticketInfo ?? [{id: hoobiizTicketInfoId()}]
  );
  const [terms, setTerms] = useState<string>(initial?.ticket.terms ?? '');

  useEffect(() => {
    if (!reservation || !mode) {
      return;
    }
    onChange?.({
      id: initial?.id,
      ticket: {reservation, mode, quantity, ticketInfo, terms},
    });
  }, [onChange, reservation, mode, quantity, initial?.id, ticketInfo, terms]);

  const handleModeChange = useCallback((mode: HoobiizStockModeVendorConfirm | undefined) => {
    setMode(mode);
  }, []);

  return (
    <FormWrapper>
      <HoobiizStockTermsForm terms={terms} onChange={setTerms} />
      <HoobiizTicketInfoMultiForm initialData={ticketInfo} onChange={setTicketInfo} />
      <FormColumnFull>
        <HoobiizStockReservationFlexibleForm
          vendorHours={vendorHours}
          initialData={reservation}
          onChange={setReservation}
        />
      </FormColumnFull>
      <FormColumnFull>
        <HoobiizStockModeVendorConfirmForm mode={mode} onChange={handleModeChange} />
        <div>
          <FormLabel $noMargin>TICKETS</FormLabel>
          <FormRow>
            <FormColumnAuto>
              <Input
                value={quantity}
                syncState={setQuantity}
                fromString={fromString}
                label={'QUANTITÉ'}
                overrides={adminInputTheme}
              />
            </FormColumnAuto>
          </FormRow>
        </div>
      </FormColumnFull>
    </FormWrapper>
  );
};
ActivityStockFlexibleAndVendorConfirmForm.displayName = 'ActivityStockFlexibleAndVendorConfirmForm';
