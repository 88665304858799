import {Elements, PaymentElement, useElements} from '@stripe/react-stripe-js';
import {StripePaymentElementChangeEvent} from '@stripe/stripe-js';
import {FC, useCallback, useState} from 'react';
import styled from 'styled-components';

import {CurrencyAmount, StripePaymentIntentId} from '@shared/dynamo_model';
import {currencyAmountToString} from '@shared/lib/hoobiiz/currency_amount';

import {Button} from '@shared-frontend/components/core/button';

import {Colors} from '@src/components/core/theme_base';
import {stripePromise} from '@src/lib/load_stripe';

interface PaymentFormProps {
  total: CurrencyAmount;
  onPaymentSuccess: () => Promise<void>;
}

const PaymentFormInternal: FC<PaymentFormProps> = props => {
  const {total, onPaymentSuccess} = props;

  const [isComplete, setIsComplete] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const elements = useElements();

  const handleChange = useCallback((evt: StripePaymentElementChangeEvent) => {
    setIsComplete(evt.complete);
  }, []);

  const handleReady = useCallback(() => {
    setIsReady(true);
  }, []);

  const handleSubmitBuy = useCallback(async () => {
    setError(undefined);
    const stripe = await stripePromise;
    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: document.location.href,
      },
      redirect: 'if_required',
    });

    if (result.error) {
      setError(result.error.message);
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/strict-boolean-expressions
    else if (result.paymentIntent) {
      await onPaymentSuccess();
    } else {
      setError('Erreur');
    }
  }, [elements, onPaymentSuccess]);

  return (
    <Wrapper hidden={!isReady}>
      <PaymentElementWrapper>
        <PaymentElement onChange={handleChange} onReady={handleReady} />
      </PaymentElementWrapper>
      {error === undefined ? <></> : <ErrorWrapper>{error}</ErrorWrapper>}
      {isReady ? (
        <PayButton onClickAsync={handleSubmitBuy} disabled={!isComplete}>
          {`Payer ${currencyAmountToString(total)}`}
        </PayButton>
      ) : (
        <></>
      )}
    </Wrapper>
  );
};
PaymentFormInternal.displayName = 'PaymentFormInternal';

export const PaymentForm: FC<
  Omit<PaymentFormProps, 'onPaymentSuccess'> & {
    paymentId: StripePaymentIntentId;
    clientSecret: string;
    onPaymentSuccess: (paymentId: StripePaymentIntentId) => Promise<void> | void;
  }
  // eslint-disable-next-line react/no-multi-comp
> = props => {
  const {paymentId, clientSecret, onPaymentSuccess, ...rest} = props;

  const handlePaymentSuccess = useCallback(
    async () => onPaymentSuccess(paymentId),
    [onPaymentSuccess, paymentId]
  );

  return (
    <FormWrapper>
      <FormLoadingIndicator>Chargement...</FormLoadingIndicator>
      <Elements
        stripe={stripePromise}
        options={{clientSecret, appearance: {variables: {colorDanger: Colors.Red}}}}
      >
        <PaymentFormInternal onPaymentSuccess={handlePaymentSuccess} {...rest} />
      </Elements>
    </FormWrapper>
  );
};
PaymentForm.displayName = 'PaymentForm';

const Wrapper = styled.div<{hidden?: boolean}>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: white;
  opacity: ${p => (p.hidden ? 0 : 1)};
  transition: opacity 300ms ease-in-out;
`;
const PayButton = styled(Button)`
  width: 100%;
`;
const ErrorWrapper = styled.div`
  background-color: ${Colors.Red};
  color: ${Colors.White};
  padding: 10px 14px;
  border-radius: 6px;
`;

const PaymentElementWrapper = styled.div`
  min-height: 220px;
`;

const FormWrapper = styled.div`
  position: relative;
  min-height: 282px;
`;

const FormLoadingIndicator = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  font-size: 20px;
`;
