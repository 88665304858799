import {
  ChangeEventHandler,
  ComponentPropsWithoutRef,
  Dispatch,
  JSX,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import styled, {useTheme} from 'styled-components';

import {FrontendTheme} from '@shared/frontends/frontend_theme_model';
import {randomStringUnsafe} from '@shared/lib/rand';
import {AddPrefix, addPrefix, removeUndefined} from '@shared/lib/type_utils';

import {LabelPosition} from '@shared-frontend/components/core/input_v2';
import {Label} from '@shared-frontend/components/core/label';
import {cssPx, optional, optionalPx, optionalRaw} from '@shared-frontend/lib/styled_utils';
import {useStateRef} from '@shared-frontend/lib/use_state_ref';

interface SelectProps<T> {
  placeholder?: string;
  value: T;
  values: {value: T; label: string}[];
  syncState: Dispatch<SetStateAction<T>> | ((val: T, el: HTMLSelectElement) => void);
  width?: string | number;
  overrides?: Partial<FrontendTheme['input']>;
  label?: string | JSX.Element;
  labelPosition?: LabelPosition;
  noLabelOffset?: boolean;
}

interface SelectValue<T> {
  id: string;
  value: T;
  label: string;
}

export function Select<T>(
  props: SelectProps<T> & Omit<ComponentPropsWithoutRef<'select'>, 'style' | 'children' | 'value'>
): JSX.Element {
  const {
    placeholder,
    value,
    values,
    syncState,
    width,
    overrides,
    label,
    labelPosition,
    noLabelOffset,
    ...rest
  } = props;

  const {input: themeDefault} = useTheme();
  const inputTheme = addPrefix({...themeDefault, ...overrides}, '$');

  const [indexers, setIndexers, indexersRef] = useStateRef({
    valueById: new Map<string, SelectValue<T>>(),
    idByValue: new Map<T, string>(),
  });
  const refreshValues = useCallback(() => {
    let changed = false;
    const newValues = new Set<T>(values.map(v => v.value));
    // Remove values in the `valueById` and `idByValue` indexes that are no longer
    // in the `values` array.
    for (const [id, value] of indexersRef.current.valueById.entries()) {
      if (!newValues.has(value.value)) {
        indexersRef.current.valueById.delete(id);
        changed = true;
      }
    }
    for (const value of indexersRef.current.idByValue.keys()) {
      if (!newValues.has(value)) {
        indexersRef.current.idByValue.delete(value);
        changed = true;
      }
    }
    // Add new values to the `valueById` and `idByValue` indexes.
    // If the value already exists, update the label.
    for (const v of values) {
      const id = indexersRef.current.idByValue.get(v.value);
      if (id !== undefined) {
        const current = indexersRef.current.valueById.get(id);
        if (current !== undefined) {
          if (current.label !== v.label) {
            current.label = v.label;
            changed = true;
          }
          continue;
        }
      }
      const newId = randomStringUnsafe(10);
      indexersRef.current.valueById.set(newId, {id: newId, ...v});
      indexersRef.current.idByValue.set(v.value, newId);
      changed = true;
    }
    // If any changes were made, force a re-render of the component.
    if (changed) {
      setIndexers(prev => ({...prev}));
    }
  }, [indexersRef, setIndexers, values]);

  useEffect(() => refreshValues(), [refreshValues]);

  const handleChange = useCallback<ChangeEventHandler<HTMLSelectElement>>(
    evt => {
      const id = evt.currentTarget.value;
      const value = indexers.valueById.get(id);
      if (value === undefined) {
        return;
      }
      syncState(value.value, evt.currentTarget);
    },
    [indexers, syncState]
  );

  const noValueTokenId = useMemo(() => {
    return randomStringUnsafe(10);
  }, []);

  const selectValue = useMemo(() => {
    return values
      .map(v => {
        const id = indexers.idByValue.get(v.value);
        if (id === undefined) {
          return undefined;
        }
        return indexers.valueById.get(id);
      })
      .find(v => v?.value === value);
  }, [values, indexers, value]);

  const select = (
    <StyledSelect
      onChange={handleChange}
      value={selectValue?.id ?? noValueTokenId}
      $width={width}
      {...inputTheme}
      {...rest}
    >
      {placeholder !== undefined ? (
        <Option key="placeholder" value={noValueTokenId} disabled>
          {placeholder}
        </Option>
      ) : (
        ''
      )}
      {removeUndefined(
        values.map(v => {
          const id = indexers.idByValue.get(v.value);
          if (id === undefined) {
            return undefined;
          }
          return (
            <Option key={id} value={id}>
              {v.label}
            </Option>
          );
        })
      )}
    </StyledSelect>
  );

  if (label !== undefined) {
    const labelBaseColor = inputTheme.$textColor;
    const labelColor = /#[\dA-Fa-f]{6}/u.test(labelBaseColor)
      ? `${labelBaseColor}99`
      : /#[\dA-Fa-f]{3}/u.test(labelBaseColor)
        ? `${labelBaseColor}${labelBaseColor.slice(1, 4)}99` // eslint-disable-line @typescript-eslint/no-magic-numbers
        : labelBaseColor;
    return (
      <StyledLabel
        value={label}
        $paddingLeft={noLabelOffset ? 0 : inputTheme.$paddingLeft}
        $labelPosition={labelPosition ?? 'left'}
        $fontSize={inputTheme.$fontSize}
        $marginBottom={inputTheme.$titleMarginBottom}
        $textColor={labelColor}
      >
        {select}
      </StyledLabel>
    );
  }

  return select;
}
Select.displayName = 'Select';

const StyledSelect = styled.select<
  AddPrefix<FrontendTheme['input'], '$'> & {$width: SelectProps<unknown>['width']}
>`
  display: block;
  ${p => optionalPx('width', p.$width)}
  ${p => optionalPx('height', p.$height)}
  box-sizing: border-box;

  outline: none;
  ${p => optionalPx('padding-right', p.$paddingRight)}
  ${p => optionalPx('padding-left', p.$paddingLeft)}
  ${p => optionalPx('border-radius', p.$borderRadius)}

  ${p => optional('font-family', p.$fontFamily)}
  ${p => optional('font-weight', p.$fontWeight)}
  ${p => optionalPx('font-size', p.$fontSize)}

  ${p => optional('color', p.$textColor)}
  ${p => optionalRaw(p.$borderWidth, v => `border: solid ${cssPx(v)} ${p.$borderColor};`)}
  ${p => optional('background-color', p.$backgroundColor)}

  &:hover {
    ${p => optional('background-color', p.$backgroundColorHover)}
    ${p => optional('border-color', p.$hoverBorderColor)}
  }

  &:active:not([disabled]),
  &:focus:not([disabled]) {
    ${p =>
      optionalRaw(p.$focusBorderWidth, v => `border: solid ${cssPx(v)} ${p.$focusBorderColor};`)}
    ${p =>
      optionalRaw(
        p.$focusOutlineWidth,
        v => `box-shadow: 0 0 0 ${cssPx(v)} ${p.$focusOutlineColor};`
      )}
    ${p => optional('color', p.$focusTextColor)}
    ${p => optional('background-color', p.$backgroundColorFocus)}
  }

  &:disabled {
    ${p => optional('color', p.$textColorDisabled)}
    ${p => optional('background-color', p.$backgroundColorDisabled)}
    box-shadow: none;
    ${p => optionalRaw(p.$borderWidth, v => `border: solid ${cssPx(v)} ${p.$borderColor};`)}
  }
`;

const Option = styled.option``;

const StyledLabel = styled(Label)<{
  $paddingLeft: number | string | undefined;
  $labelPosition: LabelPosition;
  $fontSize: number | string | undefined;
  $marginBottom: number | string;
  $textColor: string;
}>`
  display: inline-block;
  line-height: 120%;
  font-weight: 700;
  color: ${p => p.$textColor};
  ${p => optionalPx('padding-left', p.$labelPosition === 'left' ? p.$paddingLeft : undefined)}
  ${p => optionalRaw(p.$fontSize, v => `font-size: calc(${cssPx(v)} * 0.8);`)}
  ${p => (p.$labelPosition === 'center' ? 'text-align: center;' : false)}
  margin-bottom: ${p => cssPx(p.$marginBottom)};
`;
