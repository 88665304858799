import {FC, useCallback} from 'react';
import {styled} from 'styled-components';

import {
  HoobiizExpertTicketFixedStock,
  HoobiizExpertTicketFlexibleStock,
} from '@shared/api/definitions/search_api';
import {cents} from '@shared/lib/hoobiiz/currency_amount';
import {NonEmptyArray} from '@shared/lib/type_utils';
import {SanitizedItem, SanitizedTicketInfo} from '@shared/model/search_tables';

import {localTime} from '@shared-frontend/lib/date_format';

import {Colors} from '@src/components/core/theme_base';
import {HoobiizTicketLabel} from '@src/components/ui/hoobiiz_ticket_label';
import {
  HoobiizTicketOptionPicker,
  TicketOptionQuantityData,
} from '@src/components/ui/hoobiiz_ticket_option_picker';
import {HoobiizTicketPrices} from '@src/components/ui/hoobiiz_ticket_prices';
import {NumberPicker} from '@src/components/ui/number_picker';

export interface TicketQuantityData {
  type: 'stock';
  stocks: NonEmptyArray<SanitizedItem<'HoobiizStock'>>;
  bestOffer?: SanitizedItem<'HoobiizOffer'>;
  ticketInfo: SanitizedTicketInfo;
  quantity: number;
  options: TicketOptionQuantityData[];
}

export interface ExpertTicketQuantityData {
  type: 'expert-ticket';
  ticket:
    | HoobiizExpertTicketFlexibleStock['tickets'][0]
    | HoobiizExpertTicketFixedStock['tickets'][0];
  date?: number;
  price: number;
  originalPrice?: number;
  quantity: number;
}

interface HoobiizTicketQuantityPickerProps {
  data: TicketQuantityData | ExpertTicketQuantityData;
  noTitle?: boolean;
  onChange: (event: TicketQuantityData | ExpertTicketQuantityData) => void;
}

export const HoobiizTicketQuantityPicker: FC<HoobiizTicketQuantityPickerProps> = props => {
  const {data, noTitle, onChange} = props;
  const {label, description, minQuantity, maxQuantity} =
    data.type === 'stock' ? data.ticketInfo : data.ticket;
  const maxTicket =
    data.type === 'stock'
      ? Math.min(data.stocks[0].available, maxQuantity ?? data.stocks[0].available)
      : maxQuantity;

  const handleQuantityChange = useCallback(
    (newQuantity: number) => {
      onChange({...data, quantity: newQuantity});
    },
    [data, onChange]
  );

  const handleOptionChange = useCallback(
    (newOption: TicketOptionQuantityData) => {
      if (data.type !== 'stock') {
        return;
      }
      onChange({
        ...data,
        options: [
          ...data.options.filter(o => o.ticketInfoOption.id !== newOption.ticketInfoOption.id),
          newOption,
        ],
      });
    },
    [data, onChange]
  );

  let title = '';
  let optionTitle = '';
  if (data.type === 'stock') {
    const {startTs, endTs} = data.stocks[0].reservation.period;
    const duration = (data.ticketInfo.span ?? 1) * (endTs - startTs);
    const plural = data.ticketInfo.options.length > 1 ? 's' : '';
    title = `CRÉNEAU ${localTime(startTs)} - ${localTime(startTs + duration)}`.toUpperCase();
    optionTitle = `Option${plural} disponible${plural}`.toUpperCase();
  }

  const ticketPrices =
    data.type === 'stock' ? (
      <HoobiizTicketPrices
        ticketInfo={data.ticketInfo}
        offers={data.bestOffer}
        justify="flex-start"
      />
    ) : (
      <HoobiizTicketPrices
        ticketInfo={{
          youpiizPrice: cents(data.price),
          publicPrice:
            data.originalPrice === undefined ? cents(data.price) : cents(data.originalPrice),
        }}
        justify="flex-start"
      />
    );

  return (
    <Wrapper>
      {noTitle ? <></> : <StockTitle>{title}</StockTitle>}
      <Form>
        <TicketInfoTitle>
          <HoobiizTicketLabel label={label} description={description} />
          {ticketPrices}
        </TicketInfoTitle>
        <StyledNumberPicker
          value={data.quantity}
          onChange={handleQuantityChange}
          min={minQuantity}
          max={maxTicket}
        />
      </Form>
      {data.type === 'stock' && data.options.length > 0 ? (
        <>
          <OptionsTitle>{optionTitle}</OptionsTitle>
          <Separator />
          <OptionsWrapper>
            {data.options
              .sort((o1, o2) => o1.order - o2.order)
              .map(option => (
                <HoobiizTicketOptionPicker
                  key={option.ticketInfoOption.id}
                  data={option}
                  onChange={handleOptionChange}
                />
              ))}
          </OptionsWrapper>
        </>
      ) : (
        <></>
      )}
    </Wrapper>
  );
};

HoobiizTicketQuantityPicker.displayName = 'HoobiizTicketOptionsPicker';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Form = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StockTitle = styled.div`
  color: ${Colors.Grey};
  font-size: 20px;
  font-weight: 500;
  border-bottom: solid 3px #e8e8e8;
  padding-bottom: 8px;
  margin-bottom: 12px;
`;

const TicketInfoTitle = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const StyledNumberPicker = styled(NumberPicker)`
  flex-shrink: 0;
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const OptionsTitle = styled.div`
  margin-top: 22px;
  font-size: 14px;
  color: ${Colors.Grey};
  font-weight: 700;
`;

const Separator = styled.div`
  height: 1px;
  width: 100%;
  margin: 5px 0 18px 0;
  background-color: #f0eeee;
`;
