import {styled} from 'styled-components';

import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {Tooltip} from '@shared-frontend/components/core/tooltip';
import {Custom} from '@shared-frontend/lib/react';

import {Colors} from '@src/components/core/theme_base';

interface HoobiizTicketLabelProps {
  label: string;
  description?: string;
}

export const HoobiizTicketLabel: Custom<HoobiizTicketLabelProps, 'div'> = props => {
  const {label, description, ...rest} = props;

  return (
    <Wrapper {...rest}>
      <span>{label}</span>
      {description === undefined || description.length === 0 ? (
        <></>
      ) : (
        <StyledTooltip
          content={description}
          position="top"
          maxWidth={300}
          fontSize={14}
          padding="8px 16px"
        >
          <SvgIcon name="Info" color={Colors.Grey} size={14} />
        </StyledTooltip>
      )}
    </Wrapper>
  );
};

HoobiizTicketLabel.displayName = 'HoobiizTicketOptionsPicker';

const Wrapper = styled.div`
  font-size: 18px;
`;

const StyledTooltip = styled(Tooltip)`
  display: inline-flex;
  height: min-content;
  margin-left: 6px;
`;
