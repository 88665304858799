import {FC, JSX} from 'react';
import styled, {useTheme} from 'styled-components';

import {AppLogo} from '@shared-frontend/components/auth/app_logo';
import {UnthemedNavLink} from '@shared-frontend/components/core/button';
import {Modal} from '@shared-frontend/components/core/modal';

import {GlobalStyle} from '@src/components/core/global_styles';

interface CleanAppWrapperProps {
  children: JSX.Element;
}

export const CleanAppWrapper: FC<CleanAppWrapperProps> = props => {
  const {children} = props;
  const theme = useTheme();

  return (
    <>
      <GlobalStyle />
      <Wrapper>
        <Top>
          <UnthemedNavLink to="/">
            <AppLogo logo={theme.main.logo} />
          </UnthemedNavLink>
        </Top>
        <Bottom>{children}</Bottom>
      </Wrapper>
      <Modal />
    </>
  );
};
CleanAppWrapper.displayName = 'CleanAppWrapper';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Top = styled.div`
  flex-shrink: 0;
  margin: auto;
  padding-top: 24px;
`;
const Bottom = styled.div`
  flex-grow: 1;
`;
