import {FC, useCallback, useRef, useState} from 'react';

import {
  HoobiizOpeningHours,
  HoobiizStockModeVendorConfirm,
  HoobiizStockWeeklyTemplateId,
} from '@shared/dynamo_model';
import {removeUndefined} from '@shared/lib/type_utils';

import {HoobiizStockModeVendorConfirmForm} from '@src/components/admin/activity_stock/hoobiiz_stock_mode_vendor_confirm_form';
import {HoobiizStockTermsForm} from '@src/components/admin/activity_stock/hoobiiz_stock_terms_form';
import {
  HoobiizTicketWeeklySchedule,
  HoobiizTicketWeeklyScheduleInfo,
  mergeTicketInfoAndInitialData,
} from '@src/components/admin/activity_stock/hoobiiz_ticket_weekly_schedule';
import {FormColumnFull, FormWrapper} from '@src/components/admin/form/form_fragments';

export interface FixedAndVendorConfirmTicket {
  id?: HoobiizStockWeeklyTemplateId;
  template?: {
    weeklyScheduleInfo: HoobiizTicketWeeklyScheduleInfo;
    mode: HoobiizStockModeVendorConfirm;
    terms: string;
  };
}

interface ActivityStockFixedAndVendorConfirmFormProps {
  vendorHours: HoobiizOpeningHours;
  initialData?: FixedAndVendorConfirmTicket[];
  onChange: (tickets: FixedAndVendorConfirmTicket[] | undefined) => void;
}

export const ActivityStockFixedAndVendorConfirmForm: FC<
  ActivityStockFixedAndVendorConfirmFormProps
> = props => {
  const {initialData, vendorHours, onChange} = props;
  const [terms, setTerms] = useState<string>(initialData?.[0]?.template?.terms ?? '');

  const initialDataRef = useRef(initialData);
  const ticketsRef = useRef(
    initialData ? removeUndefined(initialData.map(d => d.template?.weeklyScheduleInfo)) : undefined
  );
  const modeRef = useRef(initialData?.[0]?.template?.mode);

  const handleChange = useCallback(() => {
    if (ticketsRef.current === undefined || modeRef.current === undefined) {
      return onChange(undefined);
    }
    onChange(
      mergeTicketInfoAndInitialData(
        initialDataRef.current,
        ticketsRef.current,
        modeRef.current,
        terms
      )
    );
  }, [onChange, terms]);

  const handleTicketsChange = useCallback(
    (tickets: HoobiizTicketWeeklyScheduleInfo[] | undefined) => {
      ticketsRef.current = tickets;
      handleChange();
    },
    [handleChange]
  );
  const handleModeChange = useCallback(
    (mode: HoobiizStockModeVendorConfirm | undefined) => {
      modeRef.current = mode;
      handleChange();
    },
    [handleChange]
  );

  return (
    <FormWrapper>
      <HoobiizStockTermsForm terms={terms} onChange={setTerms} />
      <FormColumnFull>
        <HoobiizStockModeVendorConfirmForm mode={modeRef.current} onChange={handleModeChange} />
      </FormColumnFull>
      <FormColumnFull>
        <HoobiizTicketWeeklySchedule
          initialData={ticketsRef.current}
          vendorHours={vendorHours}
          onChange={handleTicketsChange}
        />
      </FormColumnFull>
    </FormWrapper>
  );
};
ActivityStockFixedAndVendorConfirmForm.displayName = 'ActivityStockFixedAndVendorConfirmForm';
