import {FC, useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router';
import styled, {useTheme} from 'styled-components';

import {AppLogo} from '@shared-frontend/components/auth/app_logo';
import {UnthemedNavLink} from '@shared-frontend/components/core/button';
import {Select} from '@shared-frontend/components/core/select';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {useSession} from '@shared-frontend/lib/session_store';

interface AdminNavProps {
  nav: {label: string; path: string; otherPaths?: string[]}[];
}

export const AdminNav: FC<AdminNavProps> = props => {
  const {nav = []} = props;
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const session = useSession();

  const adminPaths = useMemo(() => {
    const res: {value: string; label: string}[] = [];
    if ('sessionId' in session) {
      if (session.isAdmin) {
        res.push({value: '/admin', label: 'SUPER ADMIN'});
      }
      res.push(
        ...session.adminGroups.map(g => ({
          value: `/cse-admin/${g.groupId}`,
          label: g.groupName.toUpperCase(),
        }))
      );
    }
    return res;
  }, [session]);

  const [selectedAdminPath, setSelectedAdminPath] = useState<string | undefined>(
    (adminPaths.find(p => location.pathname.startsWith(p.value)) ?? adminPaths[0])?.value
  );

  useEffect(() => {
    if (adminPaths.length === 0) {
      navigate('/', {replace: true});
      return;
    }
    if (selectedAdminPath === undefined || location.pathname.startsWith(selectedAdminPath)) {
      return;
    }
    navigate(selectedAdminPath, {replace: true});
  }, [adminPaths.length, location.pathname, navigate, selectedAdminPath]);

  return (
    <Container>
      <LogoWrapper>
        <LogoNavLink to="/">
          <AppLogo logo={theme.main.logo} />
        </LogoNavLink>
      </LogoWrapper>
      {adminPaths.length > 1 ? (
        <SelectWrapper>
          <Select
            value={selectedAdminPath}
            syncState={setSelectedAdminPath}
            values={adminPaths}
            width="100%"
          />
        </SelectWrapper>
      ) : (
        <></>
      )}
      <Spacing height={8} />
      {nav.map(({label, path, otherPaths}) => (
        <NavigationLink to={path} key={path} $forceActive={otherPaths?.includes(location.pathname)}>
          {label}
        </NavigationLink>
      ))}
    </Container>
  );
};
AdminNav.displayName = 'AdminNav';

const SelectWrapper = styled.div`
  padding: 10px;
  width: 100%;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${p => p.theme.main.accentColor};
  overflow-y: auto;
  overflow-x: hidden;
  color: ${p => p.theme.main.accentTextColor};
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px 0;
`;

const NavigationLink = styled(UnthemedNavLink)<{$forceActive?: boolean}>`
  display: block;
  width: 100%;
  padding: 20px;
  font-size: 20px;
  text-decoration: none;

  &:hover {
    background-color: #ffffff15;
  }

  &.active {
    background-color: #00000040;
  }
  ${p => p.$forceActive && 'background-color: #00000040 !important;'}
`;

const LogoNavLink = styled(UnthemedNavLink)`
  height: 57px; // Bug for youpiiz logo only
`;
