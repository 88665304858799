import {FC} from 'react';
import styled from 'styled-components';

import {FullItem} from '@shared/model/search_tables';

import {HoobiizMediaView} from '@shared-frontend/components/auth/hoobiiz_media_view';
import {NavLink} from '@shared-frontend/components/core/button';

import {getVendorUrl} from '@src/lib/hoobiiz_urls';

interface AdminVendorViewProps {
  vendor?: FullItem<'HoobiizVendor'>;
}

export const AdminVendorView: FC<AdminVendorViewProps> = ({vendor}) => {
  if (!vendor) {
    return <></>;
  }
  return (
    <Wrapper>
      <HoobiizMediaView media={vendor.logo} size={{height: 64}} />
      <NavLink to={getVendorUrl(vendor)}>{vendor.name}</NavLink>
    </Wrapper>
  );
};

AdminVendorView.displayName = 'AdminVendorView';

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
`;
