import {FC, useCallback, useRef, useState} from 'react';

import {
  HoobiizOpeningHours,
  HoobiizStockModePregenerated,
  HoobiizStockModeType,
  HoobiizStockWeeklyTemplateId,
} from '@shared/dynamo_model';
import {removeUndefined} from '@shared/lib/type_utils';

import {HoobiizStockTermsForm} from '@src/components/admin/activity_stock/hoobiiz_stock_terms_form';
import {
  HoobiizTicketWeeklySchedule,
  HoobiizTicketWeeklyScheduleInfo,
  mergeTicketInfoAndInitialData,
} from '@src/components/admin/activity_stock/hoobiiz_ticket_weekly_schedule';
import {FormColumnFull, FormWrapper} from '@src/components/admin/form/form_fragments';

export interface FixedAndPregeneratedTicket {
  id?: HoobiizStockWeeklyTemplateId;
  template?: {
    weeklyScheduleInfo: HoobiizTicketWeeklyScheduleInfo;
    mode: HoobiizStockModePregenerated;
    terms: string;
  };
}

interface ActivityStockFixedAndPregeneratedFormProps {
  vendorHours: HoobiizOpeningHours;
  initialData?: FixedAndPregeneratedTicket[];
  onChange: (tickets: FixedAndPregeneratedTicket[] | undefined) => void;
}

export const ActivityStockFixedAndPregeneratedForm: FC<
  ActivityStockFixedAndPregeneratedFormProps
> = props => {
  const {initialData, vendorHours, onChange} = props;
  const initialDataRef = useRef(initialData);
  const [terms, setTerms] = useState<string>(initialData?.[0]?.template?.terms ?? '');

  const handleTicketsChange = useCallback(
    (tickets: HoobiizTicketWeeklyScheduleInfo[] | undefined) => {
      if (tickets === undefined) {
        return onChange(undefined);
      }
      const newTickets: FixedAndPregeneratedTicket[] = mergeTicketInfoAndInitialData(
        initialDataRef.current,
        tickets,
        initialDataRef.current?.[0]?.template?.mode ?? {type: HoobiizStockModeType.Pregenerated},
        terms
      );
      onChange(newTickets);
    },
    [onChange, terms]
  );

  return (
    <FormWrapper>
      <HoobiizStockTermsForm terms={terms} onChange={setTerms} />
      <FormColumnFull>
        <HoobiizTicketWeeklySchedule
          initialData={
            initialData
              ? removeUndefined(initialData.map(d => d.template?.weeklyScheduleInfo))
              : undefined
          }
          vendorHours={vendorHours}
          onChange={handleTicketsChange}
        />
      </FormColumnFull>
    </FormWrapper>
  );
};
ActivityStockFixedAndPregeneratedForm.displayName = 'ActivityStockFixedAndPregeneratedForm';
