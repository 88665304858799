import {FC, MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizExpertTicketFixedStock} from '@shared/api/definitions/search_api';
import {HoobiizActivityId} from '@shared/dynamo_model';
import {
  endOfLocalDay,
  endOfUtcDay,
  franceToUtc,
  startOfFranceDay,
  startOfLocalDay,
  startOfLocalMonth,
  startOfUtcDay,
  utcToFrance,
} from '@shared/lib/date_utils';
import {cents, currencyAmountToShortString} from '@shared/lib/hoobiiz/currency_amount';
import {FixedStockItem, isFixedStockItemWithOffer} from '@shared/model/hoobiiz/hoobiiz_stock';
import {SanitizedItem} from '@shared/model/search_tables';

import {apiCall} from '@shared-frontend/api';
import {Calendar, calendarWeeks} from '@shared-frontend/components/core/calendar';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {notifyError} from '@shared-frontend/lib/notification';
import {NULL_REF} from '@shared-frontend/lib/react';

import {Colors} from '@src/components/core/theme_base';
import {HoobiizExpertTicketPicker} from '@src/components/ui/hoobiiz_expert_ticket_picker';
import {HoobiizTicketHourPicker} from '@src/components/ui/hoobiiz_ticket_hour_picker';
import {getDiscount, getDiscountColor} from '@src/lib/discount';

interface HoobiizTicketDayPickerProps {
  activityId: HoobiizActivityId;
  initialMonth: number;
  initialYear: number;
  initialStocks: {stock: FixedStockItem; offers: SanitizedItem<'HoobiizOffer'>[]}[];
  initialExpertTicketStocks: HoobiizExpertTicketFixedStock[];
}

export interface ExpertTicketStock {
  stock: HoobiizExpertTicketFixedStock;
  ticket: HoobiizExpertTicketFixedStock['tickets'][0];
  price: HoobiizExpertTicketFixedStock['tickets'][0]['prices'][0];
}

export const HoobiizTicketDayPicker: FC<HoobiizTicketDayPickerProps> = props => {
  const {activityId, initialMonth, initialYear, initialStocks, initialExpertTicketStocks} = props;
  const wrapperRef = useRef<HTMLDivElement>(NULL_REF);

  const [stocks, setStocks] = useState<
    {stock: FixedStockItem; offers: SanitizedItem<'HoobiizOffer'>[]}[] | undefined
  >(initialStocks);
  const [expertTicketStocks, setExpertTicketStocks] =
    useState<HoobiizExpertTicketFixedStock[]>(initialExpertTicketStocks);
  const lastLoaded = useRef({activityId, month: initialMonth, year: initialYear});
  const [selectedDay, setSelectedDay] = useState<Date | undefined>();

  const [month, setMonth] = useState(initialMonth);
  const [year, setYear] = useState(initialYear);

  useEffect(() => {
    // Don't trigger update if we are already loading the data of if it's already loaded
    const {current} = lastLoaded;
    if (current.activityId === activityId && current.month === month && current.year === year) {
      return;
    }
    lastLoaded.current = {activityId, month, year};
    setStocks(undefined);
    setSelectedDay(undefined);

    // Compute the range to load
    const weeks = calendarWeeks({month, year});
    let firstDay = weeks[0]?.[0];
    let lastDay = weeks.at(-1)?.at(-1);
    if (!firstDay || !lastDay) {
      // Should never happen
      notifyError(new Error(`Failure to identify startTs and endTs`), {silent: true});
      firstDay = startOfLocalMonth();
      lastDay = startOfLocalMonth(undefined, 1);
    }

    apiCall(HoobiizApi, '/list-activity-stock', {
      activityId,
      startTs: startOfLocalDay(firstDay).getTime(),
      endTs: endOfLocalDay(lastDay).getTime(),
    })
      .then(res => {
        setStocks(res.stocks.filter(isFixedStockItemWithOffer));
        setExpertTicketStocks(res.expertTicketFixedStocks);
      })
      .catch(err => {
        notifyError(err, {
          message: `Échec du chargement des billets. Vous pouvez rafraichir la page pour réessayer`,
        });
      });
  }, [activityId, month, year]);

  const getStocksAndOffersAtDate = useCallback(
    (date: Date) => {
      const dateAsUTC = utcToFrance(date);
      const startOfDateAsUtc = startOfUtcDay(dateAsUTC);
      const endOfDateAsUtc = endOfUtcDay(dateAsUTC);
      const startOfDate = franceToUtc(startOfDateAsUtc);
      const endOfDate = franceToUtc(endOfDateAsUtc);
      return {
        stocks:
          stocks?.filter(
            stock =>
              !(stock.stock.reservation.period.startTs > endOfDate.getTime()) &&
              !(stock.stock.reservation.period.endTs < startOfDate.getTime())
          ) ?? [],
        expertTicketStocks: expertTicketStocks
          .flatMap(stock =>
            stock.tickets.flatMap(ticket => ticket.prices.map(price => ({stock, ticket, price})))
          )
          .filter(s => s.price.ts <= endOfDate.getTime() && s.price.ts >= startOfDate.getTime()),
      };
    },
    [expertTicketStocks, stocks]
  );

  const handlePreviousMonthClick = useCallback(() => {
    const newDate = new Date(year, month);
    newDate.setMonth(newDate.getMonth() - 1);
    setMonth(newDate.getMonth());
    setYear(newDate.getFullYear());
  }, [month, year]);
  const handleNextMonthClick = useCallback(() => {
    const newDate = new Date(year, month);
    newDate.setMonth(newDate.getMonth() + 1);
    setMonth(newDate.getMonth());
    setYear(newDate.getFullYear());
  }, [month, year]);
  const handleDayClick = useCallback<MouseEventHandler>(evt => {
    const date = new Date(parseFloat(evt.currentTarget.getAttribute('data-ts') ?? ''));
    if (isNaN(date.getTime())) {
      return;
    }
    setSelectedDay(date);
  }, []);

  useEffect(() => {
    if (selectedDay !== undefined && wrapperRef.current) {
      wrapperRef.current.scrollIntoView({block: 'start'});
    }
  }, [selectedDay]);

  const renderCell = useCallback(
    (date: Date) => {
      const frDate = utcToFrance(date);
      const startOfFrDate = startOfFranceDay();
      const past = frDate.getTime() < startOfFrDate.getTime();
      const today = frDate.getTime() === startOfFrDate.getTime();

      const {stocks, expertTicketStocks} = past
        ? {stocks: [], expertTicketStocks: []}
        : getStocksAndOffersAtDate(date);

      let bestInfo: ReturnType<typeof getDiscount> | undefined;
      for (const {stock, offers} of stocks) {
        for (const ticketInfo of stock.availableTickets) {
          const info = getDiscount(ticketInfo, offers);
          if (!bestInfo || info.totalDiscount.percent > bestInfo.totalDiscount.percent) {
            bestInfo = info;
          }
        }
      }
      for (const {ticket, price} of expertTicketStocks) {
        const info = getDiscount(
          {
            publicPrice:
              ticket.originalPrice !== undefined ? cents(ticket.originalPrice) : cents(price.price),
            youpiizPrice: cents(price.price),
          },
          []
        );
        if (!bestInfo || info.totalDiscount.percent > bestInfo.totalDiscount.percent) {
          bestInfo = info;
        }
      }

      const accentColor = getDiscountColor(bestInfo?.totalDiscount);
      const accentTextColor = Colors.Grey;

      return (
        <CalendarCell
          key={date.getTime()}
          data-ts={date.getTime()}
          onClick={handleDayClick}
          $disabled={bestInfo === undefined}
          $selected={selectedDay?.getTime() === date.getTime()}
          $past={past}
          $accentColor={accentColor}
          $accentTextColor={accentTextColor}
        >
          <CalendarCellDay>
            <CalendarCellDayNumber $today={today}>{date.getDate()}</CalendarCellDayNumber>
          </CalendarCellDay>
          {bestInfo === undefined ? (
            <></>
          ) : (
            <CalendarCellPromo $accentColor={accentColor} $accentTextColor="#ffffff">
              {bestInfo.totalDiscount.percent <= 0
                ? // <SvgIcon name="Check" color="#ffffff" size={11} />
                  currencyAmountToShortString(bestInfo.amount.price3)
                : `-${Math.round(bestInfo.totalDiscount.percent)}%`}
            </CalendarCellPromo>
          )}
        </CalendarCell>
      );
    },
    [getStocksAndOffersAtDate, handleDayClick, selectedDay]
  );

  const selectedDayStocks = useMemo(
    () => (selectedDay ? getStocksAndOffersAtDate(selectedDay) : undefined),
    [getStocksAndOffersAtDate, selectedDay]
  );

  return (
    <Wrapper $loading={stocks === undefined} ref={wrapperRef}>
      <Calendar
        month={month}
        year={year}
        renderCell={renderCell}
        onPreviousClick={handlePreviousMonthClick}
        onNextClick={handleNextMonthClick}
      />
      {selectedDay && selectedDayStocks?.stocks && selectedDayStocks.stocks.length > 0 ? (
        <>
          <Spacing height={28} />
          <HoobiizTicketHourPicker day={selectedDay} stockAndOffers={selectedDayStocks.stocks} />
        </>
      ) : (
        <></>
      )}
      {selectedDay &&
      selectedDayStocks?.expertTicketStocks &&
      selectedDayStocks.expertTicketStocks.length > 0 ? (
        <>
          <Spacing height={28} />
          <HoobiizExpertTicketPicker
            day={selectedDay}
            stocks={selectedDayStocks.expertTicketStocks}
          />
        </>
      ) : (
        <></>
      )}
    </Wrapper>
  );
};

HoobiizTicketDayPicker.displayName = 'HoobiizTicketDayPicker';

const Wrapper = styled.div<{$loading: boolean}>`
  ${p =>
    p.$loading
      ? `
  tbody {
    position: relative;
    &:after {
      content: 'Chargement...';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      opacity: 0.65;
    }
  }`
      : false}
  position: relative;
  display: flex;
  flex-direction: column;
`;

const CalendarCell = styled.div<{
  $disabled: boolean;
  $past: boolean;
  $selected: boolean;
  $accentColor: string;
  $accentTextColor: string;
}>`
  display: flex;
  flex-direction: column;
  width: 48px;
  height: 52px;
  border-radius: 6px;
  margin: 3px;
  cursor: ${p => (p.$disabled ? 'default' : 'pointer')};
  pointer-events: ${p => (p.$disabled ? 'none' : 'inherit')};
  background: #00000006;
  color: ${p => (p.$selected ? p.$accentColor : '#00000060')};
  border: solid 2px ${p => (p.$selected ? p.$accentColor : '#00000010')};
  ${p => p.$past && `opacity: 0.5;`}
  ${p =>
    !p.$disabled &&
    `&:hover {
        border: solid 2px ${p.$accentColor};
        color: ${p.$accentColor};
    }`}
`;

const CalendarCellDay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;
const CalendarCellDayNumber = styled.div<{
  $today: boolean;
}>`
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  ${p => (p.$today ? 'background-color: #00000010;' : false)};
`;
const CalendarCellPromo = styled.div<{$accentColor: string; $accentTextColor: string}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background: ${p => p.$accentColor};
  color: ${p => p.$accentTextColor};
  font-size: 12px;
  padding: 2px 0;
  margin: 0 -2px -2px -2px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`;
