import {FC, ReactNode, useCallback, useEffect} from 'react';
import styled from 'styled-components';

import {
  setSharedAdminOptions,
  setSidebarCollapsed,
  useSharedAdminOptions,
} from '@shared-frontend/components/admin/admin_data_store';
import {AdminNav} from '@shared-frontend/components/admin/admin_nav';
import {UnthemedButton} from '@shared-frontend/components/core/button';
import {Modal} from '@shared-frontend/components/core/modal';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {useResponsive} from '@shared-frontend/lib/use_responsive';
import {ZINDEX} from '@shared-frontend/lib/zindex';

interface AdminAppProps {
  nav: {label: string; path: string; otherPaths?: string[]}[];
  children?: ReactNode | ReactNode[];
}

export const AdminApp: FC<AdminAppProps> = props => {
  const {nav, children} = props;
  const {isDesktop} = useResponsive();
  const appOptions = useSharedAdminOptions();
  const {sidebarCollapsed} = appOptions;

  useEffect(() => {
    if (!isDesktop) {
      setSidebarCollapsed(true);
    }
  }, [isDesktop]);

  const handleToggleSidebarClick = useCallback(() => {
    setSharedAdminOptions({...appOptions, sidebarCollapsed: !sidebarCollapsed});
  }, [appOptions, sidebarCollapsed]);

  return (
    <>
      <Wrapper>
        <Left $sidebarCollapsed={sidebarCollapsed}>
          <AdminNav nav={nav} />
        </Left>
        <Right $sidebarCollapsed={sidebarCollapsed}>{children}</Right>
        <ToggleSidebarButton onClick={handleToggleSidebarClick}>
          {sidebarCollapsed ? (
            <SideBarRightIcon name="SideBarRight" />
          ) : (
            <SideBarLeftIcon name="SideBarLeft" />
          )}
        </ToggleSidebarButton>
      </Wrapper>
      <Modal />
    </>
  );
};
AdminApp.displayName = 'AdminApp';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  background-color: ${p => p.theme.main.backgroundColor};
  color: ${p => p.theme.main.textColor};
`;

const Left = styled.div<{$sidebarCollapsed: boolean}>`
  ${p => (p.$sidebarCollapsed ? 'display: none;' : undefined)}
  position: fixed;
  top: 0;
  left: 0;
  width: ${p => p.theme.admin.navWidth}px;
  bottom: 0;
  box-shadow: -16px 0 20px 20px #00000060;
  z-index: ${ZINDEX.AdminSidebar};
`;

const Right = styled.div<{$sidebarCollapsed: boolean}>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: ${p => (p.$sidebarCollapsed ? 0 : p.theme.admin.navWidth)}px;
  overflow: auto;
`;

const ToggleSidebarButton = styled(UnthemedButton)`
  position: fixed;
  top: 0;
  left: 0;
  border-radius: 0;
  padding: 0;
  height: 32px;
  background-color: ${p => p.theme.main.accentColor};
  border-bottom-right-radius: 4px;
`;

const SideBarRightIcon = styled(SvgIcon)`
  margin: 0 5px 0 10px;
`;

const SideBarLeftIcon = styled(SvgIcon)`
  margin: 0 10px 0 5px;
  fill: ${p => p.theme.main.accentTextColor};
`;
