import {FC, JSX} from 'react';
import {useLocation} from 'react-router';
import styled from 'styled-components';

import {AdminBar} from '@shared-frontend/components/admin_bar';
import {Modal} from '@shared-frontend/components/core/modal';
import {useParams} from '@shared-frontend/lib/use_typed_params';

import {Footer} from '@src/components/core/footer';
import {GlobalStyle} from '@src/components/core/global_styles';
import {Navigation} from '@src/components/core/navigation';
import {Sizes} from '@src/components/core/theme_base';
import {useNavHeight} from '@src/lib/use_nav_height';

interface AppWrapperProps {
  center?: boolean;
  ignoreBars?: boolean;
  children: JSX.Element;
}

export const AppWrapper: FC<AppWrapperProps> = props => {
  const {center, ignoreBars, children} = props;
  const {pathname} = useLocation();
  const params = useParams();
  const {headerHeight, footerHeight, adminBarHeight} = useNavHeight();

  let adminPath = '';
  if (pathname.startsWith('/a/')) {
    const {id} = params;
    if (id !== undefined) {
      adminPath = `/activity/${id}`;
    }
  }
  if (pathname.startsWith('/v/')) {
    const {id} = params;
    if (id !== undefined) {
      adminPath = `/vendor/${id}`;
    }
  }

  return (
    <>
      <AdminBar adminPath={adminPath} />
      <Main>
        <GlobalStyle />
        <Wrapper>
          <Navigation />
          <Content
            $center={center}
            $ignoreBars={ignoreBars}
            $headerHeight={headerHeight}
            $footerHeight={footerHeight}
            $adminBarHeight={adminBarHeight}
          >
            {children}
          </Content>
          <Footer />
        </Wrapper>
      </Main>
      <Modal />
    </>
  );
};
AppWrapper.displayName = 'AppWrapper';

const Main = styled.div``;

const Wrapper = styled.div`
  height: 100%;
`;

const Content = styled.div<{
  $center?: boolean;
  $ignoreBars?: boolean;
  $headerHeight: number;
  $footerHeight: number;
  $adminBarHeight: number;
}>`
  margin: auto;
  max-width: ${Sizes.Content};
  min-height: ${({$ignoreBars, $headerHeight, $footerHeight, $adminBarHeight}) =>
    $ignoreBars
      ? '100svh'
      : `calc(100svh - ${$headerHeight}px - ${$footerHeight}px - ${$adminBarHeight}px)`};
  margin-top: ${({$ignoreBars, $headerHeight, $adminBarHeight}) =>
    $ignoreBars ? `-${$headerHeight + $adminBarHeight}px` : undefined};
  display: flex;
  align-items: ${({$center}) => ($center ? 'center' : 'baseline')};
  justify-content: center;
`;
