import {FC, useCallback} from 'react';

import {HoobiizStockModePregenerated, HoobiizStockModeType} from '@shared/dynamo_model';

import {Checkbox} from '@shared-frontend/components/core/checkbox';

import {FormColumnAuto, FormLabel} from '@src/components/admin/form/form_fragments';

interface HoobiizStockModePregeneratedFormProps {
  initialMode: HoobiizStockModePregenerated;
  onChange?: (mode: HoobiizStockModePregenerated) => void;
}

export const HoobiizStockModePregeneratedForm: FC<HoobiizStockModePregeneratedFormProps> = ({
  initialMode,
  onChange,
}) => {
  const handleUnlimitedChange = useCallback(
    (unlimited: boolean) => onChange?.({type: HoobiizStockModeType.Pregenerated, unlimited}),
    [onChange]
  );
  return (
    <FormColumnAuto>
      <FormLabel $noMargin>ILLIMITÉ</FormLabel>
      <Checkbox checked={initialMode.unlimited} syncState={handleUnlimitedChange}>
        Autoriser les dépassements de stock.
      </Checkbox>
    </FormColumnAuto>
  );
};
HoobiizStockModePregeneratedForm.displayName = 'HoobiizStockModePregeneratedForm';
