import {FC, MouseEvent, useCallback} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {SearchApiQueryType} from '@shared/api/definitions/search_api';
import {isNull} from '@shared/lib/type_utils';

import {apiCall} from '@shared-frontend/api';
import {Button} from '@shared-frontend/components/core/button';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {useApiCall} from '@shared-frontend/lib/use_api_call';

import {PageWrapper} from '@src/components/admin/page_fragment';
import {AdminNavLink} from '@src/components/core/theme';
import {HoobiizCat1View} from '@src/components/ui/hoobiiz_cat1_view';

export const CategoryDashboard: FC = () => {
  const {data, refreshData} = useApiCall(HoobiizApi, '/admin/search/query', {
    table: 'HoobiizCat1',
    mode: 'full',
    sort: {name: 'order', params: undefined},
    permissions: [],
  });
  const res = data as SearchApiQueryType<'HoobiizCat1', 'full'>['res'] | undefined;
  const cat1 = res?.items.map(i => i.item);

  const handleSwapClick = useCallback(
    async (evt: MouseEvent<HTMLElement>) => {
      const iStr = evt.currentTarget.getAttribute('data-index');
      if (isNull(iStr)) {
        return;
      }
      const i = parseFloat(iStr);
      const firstCat = cat1?.[i];
      const secondCat = cat1?.[i + 1];
      if (!firstCat || !secondCat) {
        return;
      }
      await Promise.all([
        apiCall(HoobiizApi, '/admin/update-cat1', {
          id: firstCat.id,
          updates: {order: secondCat.order},
        }),
        apiCall(HoobiizApi, '/admin/update-cat1', {
          id: secondCat.id,
          updates: {order: firstCat.order},
        }),
      ]);
      await refreshData();
    },
    [cat1, refreshData]
  );

  return (
    <Wrapper>
      <AdminNavLink to={`/admin/cat1/create`}>Ajouter</AdminNavLink>
      <Grid>
        {cat1?.map((cat, i, arr) => (
          <CatWrapper key={cat.id}>
            <HoobiizCat1View
              to={`/admin/cat1/${cat.id}`}
              name={cat.name}
              media={{id: cat.mediaId, media: cat.media}}
            />
            {i < arr.length - 1 ? (
              <SwapButtonWrapper>
                <SwapButton
                  data-index={i}
                  onClickAsync={handleSwapClick}
                  overrides={{backgroundActive: '#00000000', backgroundHover: '#00000011'}}
                >
                  <SvgIcon name="Swap" color="#000000" size={20} />
                </SwapButton>
              </SwapButtonWrapper>
            ) : (
              <></>
            )}
          </CatWrapper>
        ))}
      </Grid>
    </Wrapper>
  );
};
CategoryDashboard.displayName = 'CategoryDashboard';

const Wrapper = styled(PageWrapper)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
  gap: 32px;
`;

const CatWrapper = styled.div`
  position: relative;
`;

const SwapButtonWrapper = styled.div`
  position: absolute;
  right: -32px;
  top: calc(50% - 16px);
  transform: rotate(90deg);
  z-index: 1;
`;

const SwapButton = styled(Button)`
  padding: 6px;
`;
