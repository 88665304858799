import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {styled} from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {
  HoobiizStockEntryItem,
  HoobiizStockId,
  HoobiizStockItem,
  HoobiizStockModeType,
  HoobiizStockWeeklyTemplateId,
} from '@shared/dynamo_model';
import {errorAsString} from '@shared/lib/type_utils';

import {apiCall} from '@shared-frontend/api';
import {Button} from '@shared-frontend/components/core/button';
import {LoadingIndicator} from '@shared-frontend/components/core/loading_indicator';
import {showRawModal} from '@shared-frontend/components/core/modal';
import {notifyError} from '@shared-frontend/lib/notification';

import {HoobiizBatchAddStockEntriesForm} from '@src/components/admin/activity_stock/hoobiiz_batch_add_stock_entries_form';
import {HoobiizStockEntryForm} from '@src/components/admin/activity_stock/hoobiiz_stock_entry_form';
import {HoobiizStockPdfModal} from '@src/components/admin/activity_stock/hoobiiz_stock_pdf_modal';
import {HoobiizStockQuantitiesTable} from '@src/components/admin/activity_stock/hoobiiz_stock_quantities_table';

interface HoobiizStockEntriesFormProps {
  stockIds?: HoobiizStockId[];
  weeklyTemplateIds?: HoobiizStockWeeklyTemplateId[];
}

export const HoobiizStockEntriesForm: FC<HoobiizStockEntriesFormProps> = ({
  stockIds,
  weeklyTemplateIds,
}) => {
  const [stockEntries, setStockEntries] = useState<HoobiizStockEntryItem[] | undefined>();
  const [stock, setStock] = useState<HoobiizStockItem | undefined>();

  const refreshStockEntries = useCallback(() => {
    setStockEntries(undefined);
    const [stockId] = stockIds ?? [];
    const promise =
      stockId !== undefined
        ? apiCall(HoobiizApi, '/admin/list-stock-entries', {stockId})
        : weeklyTemplateIds !== undefined
          ? apiCall(HoobiizApi, '/admin/list-stock-entries-for-weekly-templates', {
              weeklyTemplateIds,
            })
          : Promise.resolve({stockEntries: []});
    promise
      .then(res => setStockEntries(res.stockEntries))
      .catch(err => {
        notifyError(err, {
          message: `Échec de la récupération des lignes de stock (${errorAsString(err)})`,
        });
      });
  }, [stockIds, weeklyTemplateIds]);

  const refreshStock = useCallback(() => {
    setStock(undefined);
    const [stockId] = stockIds ?? [];
    if (stockId === undefined || stockIds?.length !== 1) {
      return;
    }
    apiCall(HoobiizApi, '/admin/get-stock', {stockId})
      .then(res => setStock(res.stock))
      .catch(err => {
        notifyError(err, {
          message: `Échec de la récupération des infos du stock`,
        });
      });
  }, [stockIds]);

  const refresh = useCallback(() => {
    refreshStock();
    refreshStockEntries();
  }, [refreshStock, refreshStockEntries]);

  useEffect(refresh, [refresh]);

  const handleDeleteStockEntry = useCallback((stockEntry: HoobiizStockEntryItem) => {
    setStockEntries(stockEntries => stockEntries?.filter(e => e.id !== stockEntry.id));
  }, []);

  const handleChangeStockEntry = useCallback(
    (newStockEntry: HoobiizStockEntryItem, oldStockEntry: HoobiizStockEntryItem) => {
      setStockEntries(
        stockEntries => stockEntries?.map(e => (e.id === oldStockEntry.id ? newStockEntry : e))
      );
    },
    []
  );

  const handleBatchAddClick = useCallback(() => {
    if (stockIds === undefined) {
      return;
    }
    const [stockId] = stockIds;
    if (stockId === undefined) {
      console.log({stockIds}); // eslint-disable-line no-console
      notifyError(new Error('stockIds is empty'));
      return;
    } else if (stockIds.length > 1) {
      console.log({stockIds}); // eslint-disable-line no-console
      notifyError(new Error('stockIds is not a single stockId'));
      return;
    }
    showRawModal({
      children: <HoobiizBatchAddStockEntriesForm stockId={stockId} />,
      mode: 'slide-right',
      width: '80vw',
      maxWidth: 800,
      onHide: refreshStockEntries,
    });
  }, [stockIds, refreshStockEntries]);

  const handleGeneratePdfClick = useCallback(() => {
    const stockId = stockIds?.[0] ?? weeklyTemplateIds?.[0];
    if (stockId === undefined) {
      return;
    }
    showRawModal({
      children: <HoobiizStockPdfModal stockId={stockId} />,
      mode: 'slide-right',
      width: '80vw',
      maxWidth: 500,
    });
  }, [stockIds, weeklyTemplateIds]);

  const sortedStockEntries = useMemo(() => {
    return stockEntries?.sort(
      (a, b) => (b.history?.at(-1)?.ts ?? 0) - (a.history?.at(-1)?.ts ?? 0)
    );
  }, [stockEntries]);

  if (stockIds === undefined || weeklyTemplateIds === undefined) {
    return <></>;
  }

  if (sortedStockEntries === undefined) {
    return (
      <Wrapper>
        <LoadingIndicator />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Header>
        <HeaderLeft>
          {stock && stock.mode.type === HoobiizStockModeType.Pregenerated ? (
            <Button onClick={handleBatchAddClick}>Ajouter des lignes de stocks</Button>
          ) : undefined}
          <Button onClick={handleGeneratePdfClick}>Générer des PDFs</Button>
          <Button onClick={refresh}>Actualiser</Button>
        </HeaderLeft>
        <HeaderRight>
          {stock !== undefined ? <HoobiizStockQuantitiesTable stock={stock} /> : undefined}
        </HeaderRight>
      </Header>

      <StockEntryList>
        {sortedStockEntries.map(e => (
          <HoobiizStockEntryForm
            key={e.id}
            stockEntry={e}
            onDelete={handleDeleteStockEntry}
            onChange={handleChangeStockEntry}
          />
        ))}
      </StockEntryList>
    </Wrapper>
  );
};
HoobiizStockEntriesForm.displayName = 'HoobiizStockEntriesForm';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StockEntryList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
`;

const HeaderLeft = styled.div`
  display: flex;
  gap: 16px;
`;

const HeaderRight = styled.div``;
