import {FC, useMemo} from 'react';
import styled from 'styled-components';

import {EmailString, FrontendUserAuthMethodType} from '@shared/dynamo_model';

import {
  ModeHeroAuthWhiteLabeling,
  modeHeroButtonOverrides,
  modeHeroInputOverrides,
  modeHeroNavLinkOverrides,
  ModeHeroTemplate,
} from '@shared-frontend/components/auth/mode_hero/mode_hero_template';
import {RegisterForm} from '@shared-frontend/components/auth/register_form';
import {RegisterMfaCheckboxes} from '@shared-frontend/components/auth/register_invite_mfa_checkboxes';
import {Button} from '@shared-frontend/components/core/button';
import {QrCode} from '@shared-frontend/components/core/qr_code';

interface RegisterInvitePageModeHeroProps {
  whiteLabeling: ModeHeroAuthWhiteLabeling;
  email: EmailString;
  totpUri: string | undefined;
  authType: FrontendUserAuthMethodType;
  isAdmin: boolean;
  handleAccessClick: () => void;
  handleMfaRegisterSubmit: () => Promise<void>;
  handleMagicLinkRegisterSubmit: () => Promise<void>;
  handlePasswordRegisterSubmit: (email: EmailString, password: string) => Promise<void>;
}

export const RegisterInvitePageModeHero: FC<RegisterInvitePageModeHeroProps> = props => {
  const {
    whiteLabeling,
    email,
    totpUri,
    authType,
    isAdmin,
    handleAccessClick,
    handleMfaRegisterSubmit,
    handleMagicLinkRegisterSubmit,
    handlePasswordRegisterSubmit,
  } = props;

  const {inputOverrides, buttonOverrides, navLinkOverrides} = useMemo(() => {
    return {
      inputOverrides: modeHeroInputOverrides(whiteLabeling),
      buttonOverrides: modeHeroButtonOverrides(whiteLabeling),
      navLinkOverrides: modeHeroNavLinkOverrides(whiteLabeling),
    };
  }, [whiteLabeling]);

  return (
    <ModeHeroTemplate whiteLabeling={whiteLabeling}>
      {totpUri !== undefined ? (
        <>
          <Title>Votre compte est prêt !</Title>
          <Content>
            <div>
              Veuillez enregistrer ce QR Code dans votre application Google Authenticator ou autre
              application de MFA.
            </div>
            <div>Il sera affiché uniquement une fois.</div>
          </Content>
          {IS_BROWSER ? <QrCode totpUri={totpUri} /> : <></>}
          <Button onClick={handleAccessClick} overrides={buttonOverrides}>
            Accéder à mon compte !
          </Button>
        </>
      ) : authType === FrontendUserAuthMethodType.MfaCode ? (
        <>
          <Title>Bienvenue</Title>
          <RegisterMfaCheckboxes onSubmit={handleMfaRegisterSubmit} />
        </>
      ) : authType === FrontendUserAuthMethodType.MagicLink ? (
        <>
          <Content>
            <Title>Welcome</Title>
            <div>
              {/* We are happy to have you among us. An administrator just created your account. */}
              Nous sommes heureux de vous compter parmi nous. Un administrateur vient de créer votre
              compte.
            </div>
            {/* You will receive an email everytime you login with a Magic Link inside. */}
            <div>Vous recevrez un email à chaque connexion contenant un lien magique.</div>
          </Content>
          <Button onClickAsync={handleMagicLinkRegisterSubmit} overrides={buttonOverrides}>
            Accéder à mon compte !
          </Button>
        </>
      ) : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      authType === FrontendUserAuthMethodType.Password ? (
        <Content>
          <Title>{String(isAdmin ? 'Administrateur' : 'Inscription')}</Title>
          <RegisterForm
            onRegisterSubmit={handlePasswordRegisterSubmit}
            initialEmail={email}
            buttonOverrides={buttonOverrides}
            inputOverrides={inputOverrides}
            navLinkOverrides={navLinkOverrides}
          />
        </Content>
      ) : (
        <></>
      )}
    </ModeHeroTemplate>
  );
};

RegisterInvitePageModeHero.displayName = 'RegisterInvitePageModeHero';

const Content = styled.div`
  font-weight: 300;
  font-size: 34px;
  width: 100%;
`;

const Title = styled.h1`
  font-size: 28px;
  font-weight: 400;
  color: ${p => p.theme.main.accentTextColor};
  margin-bottom: 12px;
  text-align: center;
`;
