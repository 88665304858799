import {FC, useCallback, useState} from 'react';
import {styled} from 'styled-components';

import {HoobiizData} from '@shared/api/definitions/public_api/hoobiiz_api';
import {
  HoobiizActivityId,
  HoobiizExpertTicketInfo,
  HoobiizExpertTicketStockId,
  HoobiizExpertTicketStockItem,
} from '@shared/dynamo_model';
import {NonEmptyArray} from '@shared/lib/type_utils';
import {FullItem} from '@shared/model/search_tables';

import {Button} from '@shared-frontend/components/core/button';
import {notifyError} from '@shared-frontend/lib/notification';

import {
  ActivityExpertTicketStockTicketsForm,
  ExpertTicketStockAndId,
} from '@src/components/admin/activity_stock/activity_expert_ticket_stock_tickets_form';
import {ensureExpertTicketInfo} from '@src/components/admin/activity_stock/hoobiiz_expert_ticket_info_form';
import {FormWrapper} from '@src/components/admin/form/form_fragments';
import {AlertButton} from '@src/components/core/theme';

export interface ActivityExpertTicketStockFormProps {
  activityId: HoobiizActivityId;
  initialData?: NonEmptyArray<FullItem<'HoobiizExpertTicketStock'>>;
  submitButtonText?: string;
  onSubmit: (data: ExpertTicketStockItemAndId[]) => Promise<void>;
}

interface ExpertTicketStockItemAndId {
  id?: HoobiizExpertTicketStockId;
  ticket?: HoobiizData<HoobiizExpertTicketStockItem>;
}

function ticketsAreValid(tickets: ExpertTicketStockAndId[] | undefined): string | undefined {
  if (tickets === undefined) {
    return 'Ticket incomplet ou invalide';
  }
  if (tickets.length === 0) {
    return 'Aucun ticket renseigné';
  }
  for (const ticket of tickets) {
    const ticketInfo = ticket.ticketInfo;
    if (!ticketInfo || ticketInfo.length === 0) {
      return 'Aucun ticket renseigné';
    }
    const res = ensureExpertTicketInfo(ticketInfo);
    if (!res.success) {
      return res.err;
    }
  }
  return undefined; // No error, tickets are valid
}

export const ActivityExpertTicketStockForm: FC<ActivityExpertTicketStockFormProps> = props => {
  const {activityId, initialData, onSubmit, submitButtonText} = props;

  const [tickets, setTickets] = useState<ExpertTicketStockAndId[] | undefined>(initialData);

  const handleTicketsChange = useCallback(
    (tickets: ExpertTicketStockAndId | ExpertTicketStockAndId[] | undefined) => {
      if (tickets === undefined) {
        return setTickets(undefined);
      }
      setTickets(Array.isArray(tickets) ? tickets : [tickets]);
    },
    []
  );

  const handleSubmitClick = useCallback(async () => {
    const error = ticketsAreValid(tickets);
    if (
      error !== undefined ||
      tickets === undefined // For typings
    ) {
      notifyError(new Error(error), {extra: tickets});
      return;
    }
    await onSubmit(
      tickets.map(t => ({
        id: t.id,
        ticket:
          t.ticketInfo !== undefined
            ? {
                activityId,
                ticketInfo: t.ticketInfo as HoobiizExpertTicketInfo[],
              }
            : undefined,
      }))
    );
  }, [activityId, onSubmit, tickets]);

  const handleDeleteClick = useCallback(async () => {
    if (!initialData || !tickets) {
      return;
    }
    await onSubmit(tickets.map(t => ({id: t.id, ticket: undefined})));
  }, [initialData, onSubmit, tickets]);

  return (
    <FormWrapper>
      <ActivityExpertTicketStockTicketsForm initial={tickets?.[0]} onChange={handleTicketsChange} />
      <ButtonsWrapper>
        <Button onClickAsync={handleSubmitClick}>{submitButtonText}</Button>
        {initialData ? (
          <AlertButton onClickAsync={handleDeleteClick}>Supprimer</AlertButton>
        ) : (
          <></>
        )}
      </ButtonsWrapper>
    </FormWrapper>
  );
};
ActivityExpertTicketStockForm.displayName = 'ActivityExpertTicketStockForm';

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
