import {useCallback, useRef} from 'react';
import styled from 'styled-components';

import {
  HoobiizActivityAddress,
  HoobiizActivityId,
  HoobiizPromoCodeStock,
  HoobiizVendorId,
  HoobiizVendorLocation,
  Markdown,
} from '@shared/dynamo_model';
import {arrayJoin} from '@shared/lib/array_utils';
import {getActivityLocations} from '@shared/lib/hoobiiz/activity_address_and_phone';
import {isCat1Id, isCat2Id, isCat3Id} from '@shared/lib/hoobiiz/hoobiiz_ids';
import {removeUndefined} from '@shared/lib/type_utils';
import {FullItem, SanitizedItem} from '@shared/model/search_tables';

import {NavLink} from '@shared-frontend/components/core/button';
import {Custom, NULL_REF} from '@shared-frontend/lib/react';
import {useResponsive} from '@shared-frontend/lib/use_responsive';

import {ColumnWrapper} from '@src/components/core/scaffolding';
import {HoobiizActivityModule} from '@src/components/ui/hoobiiz_activity_module';
import {HoobiizContactAndAccessSection} from '@src/components/ui/hoobiiz_contact_and_access_section';
import {HoobiizMediaGalleryView} from '@src/components/ui/hoobiiz_media_gallery_view';
import {HoobiizReservationCta} from '@src/components/ui/hoobiiz_reservation_cta';
import {HoobiizStockModule} from '@src/components/ui/hoobiiz_stock_module';
import {MarkdownRenderer} from '@src/components/ui/markdown';
import {getCat1Url, getCat2Url, getCat3Url, getVendorUrl} from '@src/lib/hoobiiz_urls';

type Cat1 = SanitizedItem<'HoobiizCat1'> | FullItem<'HoobiizCat1'>;
type Cat2 = SanitizedItem<'HoobiizCat2'> | FullItem<'HoobiizCat2'>;
type Cat3 = SanitizedItem<'HoobiizCat3'> | FullItem<'HoobiizCat3'>;

interface HoobiizActivityPageViewProps {
  activityId?: HoobiizActivityId;
  label?: string;
  activityAddress?: HoobiizActivityAddress;
  cat?: (
    | SanitizedItem<'HoobiizCat1' | 'HoobiizCat2' | 'HoobiizCat3'>
    | FullItem<'HoobiizCat1' | 'HoobiizCat2' | 'HoobiizCat3'>
  )[];
  vendor?: {
    id: HoobiizVendorId;
    name: string;
    addresses: HoobiizVendorLocation[];
    slugName: string;
    logo?: SanitizedItem<'HoobiizMedia'>;
  };
  media?: SanitizedItem<'HoobiizMedia'>[];
  markdown: Markdown;
  headerOverride?: string;
  firstStockTs?: number;
  promoCodeStock?: HoobiizPromoCodeStock;
  bestStock?: {
    hasFixedStocks?: boolean;
    hasFlexibleStocks?: boolean;
  };
}

export const HoobiizActivityPageView: Custom<HoobiizActivityPageViewProps, 'div'> = ({
  activityId,
  label,
  activityAddress,
  vendor,
  media = [],
  headerOverride,
  firstStockTs,
  markdown,
  cat,
  promoCodeStock,
  bestStock,
  ...rest
}) => {
  const stockModuleRef = useRef<HTMLDivElement>(NULL_REF);
  const {isMobile} = useResponsive();

  const locations =
    activityAddress && vendor
      ? getActivityLocations({
          activityAddress,
          vendorLocations: vendor.addresses,
        })
      : [];

  const handleCtaClick = useCallback(() => {
    if (!stockModuleRef.current) {
      return;
    }
    stockModuleRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
  }, []);

  let cat1: Cat1 | undefined;
  let cat2: Cat2 | undefined;
  let cat3: Cat3 | undefined;
  if (cat) {
    cat3 = cat.find(c => isCat3Id(c.id)) as Cat3 | undefined;
    if (cat3) {
      cat2 = cat3.cat2;
      cat1 = cat2?.cat1;
    }
    if (!cat2) {
      cat2 = cat.find(c => isCat2Id(c.id)) as Cat2 | undefined;
      if (cat2) {
        cat1 = cat2.cat1;
      }
    }
    if (!cat1) {
      cat1 = cat.find(c => isCat1Id(c.id)) as Cat1 | undefined;
    }
  }

  const cat1Link = cat1 ? (
    <NavLink key="cat1" to={getCat1Url(cat1)}>
      {cat1.name}
    </NavLink>
  ) : undefined;
  const cat2Link = cat2 ? (
    <NavLink key="cat2" to={getCat2Url(cat2)}>
      {cat2.name}
    </NavLink>
  ) : undefined;
  const cat3Link = cat3 ? (
    <NavLink key="cat3" to={getCat3Url(cat3)}>
      {cat3.name}
    </NavLink>
  ) : undefined;

  const showStocks: boolean =
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
    bestStock?.hasFixedStocks === true ||
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
    bestStock?.hasFlexibleStocks === true ||
    promoCodeStock !== undefined;

  return (
    <ColumnWrapper $isMobile={isMobile} {...rest}>
      <div>
        <Breadcrumb>
          {arrayJoin(removeUndefined([cat1Link, cat2Link, cat3Link]), i => (
            <BreadcrumbSeparator key={i}>{'>'}</BreadcrumbSeparator>
          ))}
        </Breadcrumb>
        <Header $isMobile={isMobile}>
          <HeaderLeft>
            <ActivityTitle $isMobile={isMobile}>{label}</ActivityTitle>
            {vendor === undefined ? (
              <></>
            ) : (
              <ProviderInfo $isMobile={isMobile}>
                Proposé par
                <NavLink to={getVendorUrl({slugName: vendor.slugName, id: vendor.id})}>
                  {vendor.name}
                </NavLink>
              </ProviderInfo>
            )}
          </HeaderLeft>
          <HeaderRight>
            <HoobiizReservationCta
              bestStock={bestStock}
              promoCodeStock={promoCodeStock}
              onClick={handleCtaClick}
            />
          </HeaderRight>
        </Header>
      </div>
      <HoobiizMediaGalleryView media={media} />
      <Content $isMobile={isMobile}>
        <ContentLeft>
          <MarkdownRenderer markdown={markdown} logo={vendor?.logo} />
        </ContentLeft>
        {activityId === undefined || !showStocks ? (
          <></>
        ) : (
          <ContentRight ref={stockModuleRef}>
            <HoobiizStockModule
              activityId={activityId}
              headerOverride={headerOverride}
              firstStockTs={firstStockTs}
              promoCodeStock={promoCodeStock}
            />
          </ContentRight>
        )}
      </Content>
      <Separator />
      <HoobiizContactAndAccessSection locations={locations} />
      {vendor === undefined ? (
        <></>
      ) : (
        <HoobiizActivityModule
          vendorId={vendor.id}
          cse={{mode: 'all', showCheckbox: true}}
          title={`Les activités proposées par ${vendor.name}`}
        />
      )}
    </ColumnWrapper>
  );
};

HoobiizActivityPageView.displayName = 'HoobiizActivityPageView';

const Breadcrumb = styled.div`
  display: flex;
  align-items: baseline;
  gap: 9px;
  margin-bottom: 8px;
`;

const BreadcrumbSeparator = styled.div`
  color: #a09f9f;
  font-size: 14px;
  font-weight: 700;
`;

const Header = styled.div<{$isMobile: boolean}>`
  display: flex;
  flex-direction: ${({$isMobile}) => ($isMobile ? 'column' : 'row')};
  gap: ${({$isMobile}) => ($isMobile ? '16px' : '0')};
  justify-content: space-between;
`;
const HeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const HeaderRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ProviderInfo = styled.div<{$isMobile: boolean}>`
  display: flex;
  align-items: center;
  color: #5e5e5e;
  gap: 8px;
  font-size: ${({$isMobile}) => ($isMobile ? '14px' : '18px')};
`;

const ActivityTitle = styled.div<{$isMobile: boolean}>`
  font-size: ${({$isMobile}) => ($isMobile ? '24px' : '32px')};
  font-weight: bold;
`;

const Content = styled.div<{$isMobile: boolean}>`
  width: 100%;
  display: flex;
  flex-direction: ${({$isMobile}) => ($isMobile ? 'column' : 'row')};
  align-items: ${({$isMobile}) => ($isMobile ? 'center' : 'flex-start')};
  gap: 32px;
`;

const ContentLeft = styled.div`
  flex-grow: 1;
`;
const ContentRight = styled.div`
  flex-shrink: 0;
`;

const Separator = styled.div`
  height: 1px;
  width: 100%;
  margin: 22px 0 10px 0;
  background-color: #eeeeee;
`;
