import {FC} from 'react';
import {useParams} from 'react-router';

import {HoobiizCat1Id} from '@shared/dynamo_model';

import {useResponsive} from '@shared-frontend/lib/use_responsive';

import {ColumnWrapper} from '@src/components/core/scaffolding';
import {HoobiizActivityModule} from '@src/components/ui/hoobiiz_activity_module';
import {HoobiizCat2ListView} from '@src/components/ui/hoobiiz_cat2_list_view';

interface Cat1PageProps {}

export const Cat1Page: FC<Cat1PageProps> = () => {
  const {cat1Id: cat1IdStr = ''} = useParams();
  const cat1Id = cat1IdStr as HoobiizCat1Id;
  const {isMobile} = useResponsive();

  return (
    <ColumnWrapper $isMobile={isMobile}>
      <HoobiizCat2ListView cat1Id={cat1Id} />
      <HoobiizActivityModule catId={cat1Id} cse={{mode: 'all', showCheckbox: true}} />
    </ColumnWrapper>
  );
};

Cat1Page.displayName = 'Cat1Page';
