import {FC, MouseEventHandler, useCallback, useEffect, useState} from 'react';
import {useTheme} from 'styled-components';

import {HoobiizUser} from '@shared/model/hoobiiz/hoobiiz_users';

import {Input} from '@shared-frontend/components/core/input_v2';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';

import {PageWrapper} from '@src/components/admin/page_fragment';
import {
  Table,
  TableHeaderCell,
  TableHeaderText,
  TableLineCellWithImage,
} from '@src/components/admin/table_fragment';
import {loadAllUsers, useAllUsers} from '@src/components/admin/user_and_group/user_store';
import {Pagination} from '@src/components/core/pagination';
import {adminInputTheme} from '@src/components/core/theme';
import {formatFullHoobiizDate} from '@src/lib/hoobiiz_date';

export const AdminUsersPage: FC = () => {
  const theme = useTheme();
  const users = useAllUsers();
  const [sort, setSort] = useState<{name: keyof HoobiizUser; desc: boolean}>({
    name: 'createdAt',
    desc: false,
  });

  useEffect(loadAllUsers, []);

  // When clicking a column to change the sorting
  const handleHeaderClick = useCallback<MouseEventHandler>(evt => {
    const name = evt.currentTarget.getAttribute('data-sort') as keyof HoobiizUser;
    setSort(sort => ({
      name,
      desc: sort.name === name && !sort.desc,
    }));
  }, []);

  // Utility to generate the sort icon on the column headers
  const sortIcon = useCallback(
    (sortName: keyof HoobiizUser) => {
      const {name, desc} = sort;
      if (name !== sortName) {
        return <></>;
      }
      return (
        <SvgIcon
          name={desc ? 'ChevronUp' : 'ChevronDown'}
          size={12}
          color={theme.main.accentTextColor}
        />
      );
    },
    [sort, theme.main.accentTextColor]
  );

  const [searchQuery, setSearchQuery] = useState('');

  return (
    <PageWrapper>
      <Input
        placeholder="Filtrer"
        value={searchQuery}
        syncState={setSearchQuery}
        overrides={adminInputTheme}
        width="100%"
      />
      <Pagination>{users?.length.toLocaleString()} utilisateurs</Pagination>
      <Table $isLoading={users === undefined}>
        {/* HEADER */}
        <thead>
          <tr>
            <th>
              <TableHeaderCell $clickable onClick={handleHeaderClick} data-sort={'email'}>
                <TableHeaderText>Email</TableHeaderText>
                {sortIcon('email')}
              </TableHeaderCell>
            </th>
            <th>
              <TableHeaderCell $clickable onClick={handleHeaderClick} data-sort={'firstName'}>
                <TableHeaderText>Prénom</TableHeaderText>
                {sortIcon('firstName')}
              </TableHeaderCell>
            </th>
            <th>
              <TableHeaderCell $clickable onClick={handleHeaderClick} data-sort={'lastName'}>
                <TableHeaderText>Nom</TableHeaderText>
                {sortIcon('lastName')}
              </TableHeaderCell>
            </th>
            <th>
              <TableHeaderCell $clickable onClick={handleHeaderClick} data-sort={'phoneNumber'}>
                <TableHeaderText>Téléphone</TableHeaderText>
                {sortIcon('phoneNumber')}
              </TableHeaderCell>
            </th>
            <th>
              <TableHeaderCell $clickable onClick={handleHeaderClick} data-sort={'createdAt'}>
                <TableHeaderText>Date d'inscription</TableHeaderText>
                {sortIcon('createdAt')}
              </TableHeaderCell>
            </th>
          </tr>
        </thead>
        {/* BODY */}
        <tbody>
          {users
            ?.filter(user => {
              if (searchQuery.length === 0) {
                return true;
              }
              const s = searchQuery.toLocaleLowerCase();
              return (
                user.email.toLocaleLowerCase().includes(s) ||
                user.firstName.toLocaleLowerCase().includes(s) ||
                user.lastName.toLocaleLowerCase().includes(s) ||
                user.phoneNumber.toLocaleLowerCase().includes(s)
              );
            })
            .sort((a, b) => {
              const {name, desc} = sort;
              return desc ? a[name].localeCompare(b[name]) : b[name].localeCompare(a[name]);
            })
            .map(user => {
              return (
                <tr key={user.userId}>
                  <td>
                    <TableLineCellWithImage $noWrapp $overflow="hidden">
                      {user.email}
                    </TableLineCellWithImage>
                  </td>
                  <td>
                    <TableLineCellWithImage $noWrapp $overflow="hidden">
                      {user.firstName}
                    </TableLineCellWithImage>
                  </td>
                  <td>
                    <TableLineCellWithImage $noWrapp $overflow="hidden">
                      {user.lastName}
                    </TableLineCellWithImage>
                  </td>
                  <td>
                    <TableLineCellWithImage $noWrapp $overflow="hidden">
                      {user.phoneNumber}
                    </TableLineCellWithImage>
                  </td>
                  <td>
                    <TableLineCellWithImage $noWrapp $overflow="hidden">
                      {formatFullHoobiizDate(new Date(user.createdAt))}
                    </TableLineCellWithImage>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </PageWrapper>
  );
};

AdminUsersPage.displayName = 'AdminUsersPage';
