import {FC, MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';

import {groupStockByTicketInfo} from '@shared/lib/hoobiiz/stock';
import {isNull, removeUndefined} from '@shared/lib/type_utils';
import {FixedStockItem} from '@shared/model/hoobiiz/hoobiiz_stock';
import {SanitizedItem} from '@shared/model/search_tables';

import {Spacing} from '@shared-frontend/components/core/spacing';
import {localTime} from '@shared-frontend/lib/date_format';
import {notifyError} from '@shared-frontend/lib/notification';
import {NULL_REF} from '@shared-frontend/lib/react';

import {Colors} from '@src/components/core/theme_base';
import {AddToCartButton} from '@src/components/ui/add_to_cart_button';
import {HoobiizTicketLabel} from '@src/components/ui/hoobiiz_ticket_label';
import {HoobiizTicketPrices} from '@src/components/ui/hoobiiz_ticket_prices';
import {
  ExpertTicketQuantityData,
  HoobiizTicketQuantityPicker,
  TicketQuantityData,
} from '@src/components/ui/hoobiiz_ticket_quantity_picker';
import {getDiscount, getDiscountColor} from '@src/lib/discount';
import {formatHoobiizDate} from '@src/lib/hoobiiz_date';

interface HoobiizTicketHourPickerProps {
  day: Date;
  stockAndOffers: {stock: FixedStockItem; offers: SanitizedItem<'HoobiizOffer'>[]}[];
}

export const HoobiizTicketHourPicker: FC<HoobiizTicketHourPickerProps> = props => {
  const {day, stockAndOffers} = props;

  const [ticketData, setTicketData] = useState<TicketQuantityData | undefined>();
  const handleQuantityChange = useCallback(
    (ticketData: TicketQuantityData | ExpertTicketQuantityData) => {
      if (ticketData.type !== 'stock') {
        notifyError('Unexpected ExpertTicket data');
        return;
      }
      setTicketData(ticketData);
    },
    []
  );

  const scrollTargetRef = useRef<HTMLDivElement>(NULL_REF);

  const stocksByTicketInfo = useMemo(
    () => groupStockByTicketInfo(stockAndOffers),
    [stockAndOffers]
  );

  // Reset selection when a prop changes
  useEffect(() => {
    setTicketData(undefined);
  }, [day, stockAndOffers]);

  const handleDayClick = useCallback<MouseEventHandler>(
    evt => {
      const stockId = evt.currentTarget.getAttribute('data-stock-id');
      const stockInfoId = evt.currentTarget.getAttribute('data-stock-info-id');
      if (isNull(stockId) || isNull(stockInfoId)) {
        return;
      }
      const selected = stocksByTicketInfo.find(s => s.ticketInfo.id === stockInfoId);
      if (!selected) {
        setTicketData(undefined);
        return;
      }
      const {ticketInfo, stockGroups} = selected;
      const options = ticketInfo.options.map((o, i) => ({
        ticketInfoOption: o,
        order: i,
        quantity: 0,
      }));
      const stockGroup = stockGroups.find(group => group.stocks[0].id === stockId);
      if (!stockGroup) {
        setTicketData(undefined);
        return;
      }
      const {stocks, bestOffer} = stockGroup;
      setTicketData({type: 'stock', stocks, ticketInfo, bestOffer, options, quantity: 0});
    },
    [stocksByTicketInfo]
  );

  const handleAddToCartSubmit = useCallback(() => {
    if (!ticketData) {
      return;
    }
    setTicketData({
      type: 'stock',
      stocks: ticketData.stocks,
      ticketInfo: ticketData.ticketInfo,
      bestOffer: ticketData.bestOffer,
      options: ticketData.ticketInfo.options.map((o, i) => ({
        ticketInfoOption: o,
        order: i,
        quantity: 0,
      })),
      quantity: 0,
    });
  }, [ticketData]);

  useEffect(() => {
    if (ticketData !== undefined && scrollTargetRef.current) {
      scrollTargetRef.current.scrollIntoView({block: 'nearest'});
    }
  }, [ticketData]);

  if (stocksByTicketInfo.length === 0) {
    return <div>Stock épuisé</div>;
  }

  return (
    <>
      <StockTitle>{`HORAIRES DU ${formatHoobiizDate(day)}`}</StockTitle>
      <StockWrapper>
        {stocksByTicketInfo.map(s => {
          return (
            <TicketGroup key={s.ticketInfo.label}>
              <TicketGroupTitle>
                <StyledHoobiizTicketLabel
                  label={s.ticketInfo.label}
                  description={s.ticketInfo.description}
                />
                <StyledHoobiizPrices
                  ticketInfo={s.ticketInfo}
                  offers={s.bestOffer}
                  justify="flex-end"
                />
              </TicketGroupTitle>
              <TicketGroupHours>
                {s.stockGroups
                  .sort(
                    (s1, s2) =>
                      s1.stocks[0].reservation.period.startTs -
                      s2.stocks[0].reservation.period.startTs
                  )
                  .map(stock => {
                    const {totalDiscount} = getDiscount(
                      s.ticketInfo,
                      removeUndefined([stock.bestOffer])
                    );
                    const totalDiscountColor = getDiscountColor(totalDiscount);
                    const [firstStock] = stock.stocks;
                    return (
                      <HourCell
                        key={firstStock.id}
                        data-stock-id={firstStock.id}
                        data-stock-info-id={s.ticketInfo.id}
                        onClick={handleDayClick}
                        $selected={
                          ticketData?.stocks[0].id === firstStock.id &&
                          ticketData.ticketInfo.id === s.ticketInfo.id
                        }
                        $accentColor={totalDiscountColor}
                        $accentTextColor={Colors.Grey}
                      >
                        <HourCellDay>
                          {localTime(firstStock.reservation.period.startTs)}
                        </HourCellDay>
                        {totalDiscount.percent <= 0 ? (
                          <></>
                        ) : (
                          <HourCellPromo
                            $accentColor={totalDiscountColor}
                            $accentTextColor="#ffffff"
                          >
                            {`-${Math.round(totalDiscount.percent)}%`}
                          </HourCellPromo>
                        )}
                      </HourCell>
                    );
                  })}
              </TicketGroupHours>
            </TicketGroup>
          );
        })}
      </StockWrapper>
      {ticketData ? (
        <>
          <Spacing height={28} />
          <HoobiizTicketQuantityPicker data={ticketData} onChange={handleQuantityChange} />
          <Spacing height={28} />
          <AddToCartButton ticketData={[ticketData]} onSubmit={handleAddToCartSubmit} />
        </>
      ) : (
        <></>
      )}
      <div ref={scrollTargetRef} />
    </>
  );
};

HoobiizTicketHourPicker.displayName = 'HoobiizTicketHourPicker';

const StockTitle = styled.div`
  color: ${Colors.Grey};
  font-size: 20px;
  font-weight: 500;
  border-bottom: solid 3px #e8e8e8;
  padding-bottom: 8px;
  margin-bottom: 12px;
`;

const StockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TicketGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TicketGroupTitle = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 12px;
`;
const StyledHoobiizTicketLabel = styled(HoobiizTicketLabel)`
  flex-shrink: 1;
  flex-grow: 1;
`;
const StyledHoobiizPrices = styled(HoobiizTicketPrices)`
  flex-shrink: 1;
  flex-grow: 1;
`;

const TicketGroupHours = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
  grid-gap: 7px;
`;

const HourCell = styled.div<{
  $selected: boolean;
  $accentColor: string;
  $accentTextColor: string;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 52px;
  border-radius: 6px;
  cursor: pointer;
  background: #00000006;
  color: ${p => (p.$selected ? p.$accentColor : Colors.Grey)};
  border: solid 2px ${p => (p.$selected ? p.$accentColor : '#00000010')};
  &:hover {
    border: solid 2px ${p => p.$accentColor};
    color: ${p => p.$accentColor};
  }
`;

const HourCellDay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;
const HourCellPromo = styled.div<{$accentColor: string; $accentTextColor: string}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background: ${p => p.$accentColor};
  color: ${p => p.$accentTextColor};
  font-size: 12px;
  padding: 2px 0;
  margin: 0 -2px -2px -2px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`;
