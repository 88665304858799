import {FC} from 'react';
import styled from 'styled-components';

import {FullItem} from '@shared/model/search_tables';

import {HoobiizMediaView} from '@shared-frontend/components/auth/hoobiiz_media_view';
import {NavLink} from '@shared-frontend/components/core/button';

import {getActivityUrl} from '@src/lib/hoobiiz_urls';

interface AdminActivityViewProps {
  activity?: FullItem<'HoobiizActivity'>;
}

export const AdminActivityView: FC<AdminActivityViewProps> = ({activity}) => {
  if (!activity) {
    return <></>;
  }
  return (
    <Wrapper>
      <HoobiizMediaView media={activity.media[0]} size={{height: 64}} />
      <NavLink to={getActivityUrl(activity)}>{activity.label}</NavLink>
    </Wrapper>
  );
};

AdminActivityView.displayName = 'AdminActivityView';

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
`;
