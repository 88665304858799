import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';

import {HoobiizUserGroupId, HoobiizUserProfile} from '@shared/dynamo_model';
import {
  ALL_HOOBIIZ_PERMISSIONS,
  flattenHoobiizPermissions,
  HoobiizPermissionName,
} from '@shared/lib/hoobiiz/hoobiiz_groups_and_users_permissions';
import {neverHappens, removeUndefined} from '@shared/lib/type_utils';

import {ButtonAsLink, NavLink, UnthemedButton} from '@shared-frontend/components/core/button';
import {Select} from '@shared-frontend/components/core/select';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {useStateRef} from '@shared-frontend/lib/use_state_ref';

import {
  getAdminUserGroup,
  updateHoobiizUserGroup,
  useHoobiizUserGroup,
  useHoobiizUserGroupHierarchy,
} from '@src/components/admin/user_and_group/admin_user_and_group_store';
import {Colors} from '@src/components/core/theme_base';

export type HoobiizUserProfilePermission = HoobiizUserProfile['permissions'];

interface AdminUserAndGroupUserProfilePermissionFormProps {
  groupId: HoobiizUserGroupId;
  name: HoobiizPermissionName;
}

export const AdminUserAndGroupUserProfilePermissionForm: FC<
  AdminUserAndGroupUserProfilePermissionFormProps
> = props => {
  const {groupId, name} = props;

  //
  // Fetch current permission data
  //
  const group = useHoobiizUserGroup({groupId});
  const permission = useMemo(
    () => group?.item.groupProfile.permissions?.find(p => p.name === name),
    [group, name]
  );
  const hierarchy = useHoobiizUserGroupHierarchy({groupId});
  const currentPermission = useMemo(
    () =>
      flattenHoobiizPermissions(
        removeUndefined(
          hierarchy.map(g =>
            'group' in g
              ? {
                  groupId: g.group.groupId,
                  permissions: g.group.groupProfile.permissions ?? [],
                }
              : undefined
          )
        )
      )[name],
    [hierarchy, name]
  );
  const {label, type} = ALL_HOOBIIZ_PERMISSIONS[name];

  //
  // Handle showing/hiding the form
  //
  const [formShown, setFormShown] = useState(false);
  const showForm = useCallback(() => {
    setFormShown(true);
  }, []);
  const hideForm = useCallback(() => {
    setFormShown(false);
  }, []);

  //
  // Current value of the permission
  //
  const {buttonContent, buttonInfo} = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (type === 'flag') {
      const enabled = currentPermission?.permission.value;
      const inheritedFrom =
        currentPermission?.fromGroupId !== undefined && currentPermission.fromGroupId !== groupId
          ? hierarchy.find(g => 'group' in g && g.group.groupId === currentPermission.fromGroupId)
          : undefined;
      const buttonContent = (
        <ValueButtonWrapper>
          <FlagValue $on={enabled}>
            {enabled === undefined ? 'NON DÉFINI' : enabled ? 'ON' : 'OFF'}
          </FlagValue>
          <SvgIcon name="Pencil" color={'#888'} size={14} />
        </ValueButtonWrapper>
      );
      const buttonInfo =
        inheritedFrom && 'group' in inheritedFrom ? (
          <InheritedFrom>
            <div>Hérité de</div>
            <NavLink to={inheritedFrom.modalPath}>{inheritedFrom.group.groupLabel}</NavLink>
          </InheritedFrom>
        ) : (
          <></>
        );
      return {buttonContent, buttonInfo};
    }
    neverHappens(type);
  }, [type, currentPermission, groupId, hierarchy]);

  //
  // Update the permission form
  //
  const [formValue, setFormValue, formValueRef] = useStateRef(permission?.value);
  useEffect(() => setFormValue(permission?.value), [permission, setFormValue, formShown]);
  const handleUpdateClick = useCallback(async () => {
    const group = getAdminUserGroup(groupId)?.item;
    const current = group?.groupProfile;
    if (!group || !current) {
      return;
    }
    const newPermissions = current.permissions?.filter(p => p.name !== name) ?? [];
    if (formValueRef.current !== undefined) {
      newPermissions.push({name, value: formValueRef.current});
    }
    await updateHoobiizUserGroup({
      groupId,
      newRegistrationCode: group.groupRegistrationCode,
      newProfile: {...current, permissions: newPermissions},
    });
    setFormShown(false);
  }, [groupId, name, formValueRef]);

  const form = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (type === 'flag') {
      return (
        <Select<boolean | undefined>
          value={formValue}
          syncState={setFormValue}
          values={[
            {label: 'HÉRITE DU PARENT', value: undefined},
            {label: 'ON', value: true},
            {label: 'OFF', value: false},
          ]}
          overrides={{paddingLeft: 4, paddingRight: 4}}
        />
      );
    }
    neverHappens(type);
  }, [formValue, setFormValue, type]);

  if (formShown) {
    return (
      <FormWrapper>
        <Label>{label}</Label>
        {form()}
        <ButtonAsLink onClickAsync={handleUpdateClick}>Modifier</ButtonAsLink>
        <ButtonAsLink onClick={hideForm}>Annuler</ButtonAsLink>
      </FormWrapper>
    );
  }
  return (
    <ValueWrapper>
      <Label>{label}</Label>
      <ValueButton onClick={showForm}>{buttonContent}</ValueButton>
      {buttonInfo}
    </ValueWrapper>
  );
};

AdminUserAndGroupUserProfilePermissionForm.displayName =
  'AdminUserAndGroupUserProfilePermissionForm';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
`;

const FormWrapper = styled(Wrapper)`
  gap: 14px;
`;
const ValueWrapper = styled(Wrapper)`
  gap: 5px;
`;

const Label = styled.div``;

const ValueButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const FlagValue = styled.div<{$on: boolean | undefined}>`
  color: #fff;
  background-color: ${p => (p.$on ? Colors.Green : p.$on === false ? Colors.Red : '#888')};
  padding: 3px 6px;
  border-radius: 3px;
`;

const InheritedFrom = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #888;
  font-size: 14px;
`;

const ValueButton = styled(UnthemedButton)`
  border-radius: 4px;
  padding: 8px;
  background-color: transparent;
  &:hover {
    background-color: #0000000a;
  }
`;
