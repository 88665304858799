import {FC} from 'react';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizVendorId} from '@shared/dynamo_model';

import {useApiCall} from '@shared-frontend/lib/use_api_call';
import {useResponsive} from '@shared-frontend/lib/use_responsive';
import {useParams} from '@shared-frontend/lib/use_typed_params';

import {ColumnWrapper} from '@src/components/core/scaffolding';
import {NotFoundPage} from '@src/components/pages/not_found_page';
import {HoobiizVendorPageView} from '@src/components/ui/hoobiiz_vendor_page_view';

export const HoobiizVendorPage: FC = () => {
  const {id = ''} = useParams();
  const hoobiizVendorId = id as HoobiizVendorId;
  const {isMobile} = useResponsive();

  const {data} = useApiCall(
    HoobiizApi,
    '/get-vendor',
    {vendorId: hoobiizVendorId},
    {errMsg: `Échec du chargement du partenaire. Vous pouvez rafraichir la page pour réessayer`}
  );

  if (!data) {
    return <ColumnWrapper $isMobile={isMobile}>Chargement...</ColumnWrapper>;
  }

  const {vendor} = data;
  if (!vendor) {
    return <NotFoundPage />;
  }

  return (
    <HoobiizVendorPageView
      vendorId={vendor.id}
      name={vendor.name}
      mediaIds={vendor.media.map(m => m.id)}
      info={vendor.markdown}
      locations={vendor.addresses}
    />
  );
};

HoobiizVendorPage.displayName = 'HoobiizVendorPage';
