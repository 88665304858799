import {FC, useCallback, useState} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {NO_TIMEOUT} from '@shared/constants/uncategorized_constants';
import {padNumber} from '@shared/lib/format_utils';
import {arrayRandomUnsafe, randBetween} from '@shared/lib/rand';
import {parseExpertTicketPricesAndDates} from '@shared/model/hoobiiz/expert_ticket_api/expert_ticket_common';

import {apiCall} from '@shared-frontend/api';
import {Button, ButtonAsLink} from '@shared-frontend/components/core/button';
import {Input} from '@shared-frontend/components/core/input_v2';
import {triggerTextFileDownload} from '@shared-frontend/lib/download';

import {
  setExpertTicketDocument,
  setExpertTicketReservation,
  setExpertTicketTransaction,
} from '@src/components/admin/expert_ticket/expert_ticket_admin_store';
import {ExpertTicketProductAndTickets} from '@src/components/admin/expert_ticket/expert_ticket_page';
import {adminInputTheme} from '@src/components/core/theme';

interface ExpertTicketCertificationProps {
  products: ExpertTicketProductAndTickets[];
}

function getAccessDate(date: Date): string {
  return [date.getFullYear(), padNumber(date.getMonth() + 1, 2), padNumber(date.getDate(), 2)].join(
    '-'
  );
}

export const ExpertTicketCertification: FC<ExpertTicketCertificationProps> = props => {
  const {products} = props;

  const [accessDate, setAccessDate] = useState<string | undefined>();
  const [ticketCount, setTicketCount] = useState<number | undefined>();

  const [bookingCount, setBookingCount] = useState<number>();
  const [transactionCount, setTransactionCount] = useState<number>();
  const [cancellationRequestCount, setCancellationRequestCount] = useState<number>();
  const [warnings, setWarnings] = useState<string[]>([]);
  const [documents, setDocuments] = useState<string[]>([]);
  const [csvLines, setCsvLines] = useState<string[]>([]);

  const processProduct = useCallback(
    async (index: number, product: ExpertTicketProductAndTickets) => {
      // Parse product and decide what and how much to book
      const {ProductId, ProductName, PricesAndDates, RequiresRealTimePrice, realTimePrices} =
        product;
      const prefix = `[${index + 1}/${products.length}]`;
      const pricesAndDates = RequiresRealTimePrice
        ? realTimePrices.map(p => ({date: new Date(p.ts), price: p.cents / 100}))
        : parseExpertTicketPricesAndDates(PricesAndDates);
      const priceAndDates =
        accessDate === undefined
          ? arrayRandomUnsafe(pricesAndDates.filter(p => p.date.getTime() > Date.now()))
          : pricesAndDates.find(p => getAccessDate(p.date) === accessDate);
      if (!priceAndDates) {
        setWarnings(w => [
          ...w,
          `${prefix} Skipping ${ProductName} (${ProductId}): No PricesAndDates`,
        ]);
        return;
      }
      const {price, date} = priceAndDates;
      const accessDateTime = getAccessDate(date);
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      const quantity = ticketCount ?? Math.round(randBetween(1, 3));
      const debugDetails = `Date: ${accessDateTime} - Price: ${price}€ - Quantity: ${quantity}`;

      // Book the product
      const bookingRes = await apiCall(
        HoobiizApi,
        '/admin/expert-ticket-reservation',
        {
          products: [{id: ProductId, quantity, accessDateTime}],
        },
        {timeout: NO_TIMEOUT}
      ).catch(err => {
        setWarnings(w => [
          ...w,
          `${prefix} Failure to book ${ProductName} (${ProductId}) - ${debugDetails} - ${String(
            err
          )}`,
        ]);
        return undefined;
      });
      if (!bookingRes?.Success) {
        return;
      }
      setExpertTicketReservation(bookingRes.ReservationId, bookingRes);
      setBookingCount(c => (c ?? 0) + 1);

      // Create a transaction
      const transactionRes = await apiCall(
        HoobiizApi,
        '/admin/expert-ticket-create-transaction',
        {
          products: bookingRes.Products.map(p => ({
            id: p.ProductId,
            quantity: p.Quantity,
            accessDateTime: p.AccessDateTime,
          })),
          reservationId: bookingRes.ReservationId,
        },
        {timeout: NO_TIMEOUT}
      ).catch(err => {
        setWarnings(w => [
          ...w,
          `${prefix} Failure to create transaction for ${ProductName} (${ProductId}) - Reservation: ${
            bookingRes.ReservationId
          } - ${debugDetails} - ${String(err)}`,
        ]);
        return undefined;
      });
      if (!transactionRes?.res.Success) {
        return;
      }
      setExpertTicketTransaction(transactionRes.res.SaleId, transactionRes.res);
      setExpertTicketDocument(transactionRes.res.SaleId, transactionRes.documents);
      setTransactionCount(c => (c ?? 0) + 1);
      setDocuments(docs => [...docs, ...transactionRes.documents]);

      const [document = ''] = transactionRes.documents;
      const documentName =
        new URL(document).searchParams
          .get('response-content-disposition')
          ?.match(/filename=(?<fileName>.*);/u)?.[1] ?? '';
      setCsvLines(csvLines => [
        ...csvLines,
        [
          transactionRes.res.TransactionDateTime,
          transactionRes.res.SaleId,
          transactionRes.res.PartnerSaleId ?? '',
          ProductId,
          price,
          quantity,
          accessDateTime,
          `"${ProductName}"`,
          documentName,
          document,
        ].join(','),
      ]);

      // Create a cancellation request
      await apiCall(
        HoobiizApi,
        '/admin/expert-ticket-cancellation-request',
        {saleId: transactionRes.res.SaleId},
        {timeout: NO_TIMEOUT}
      ).catch(err => {
        setWarnings(w => [
          ...w,
          `${prefix} Failure to create cancellation request for ${ProductName} (${ProductId}) - Transaction: ${
            transactionRes.res.SaleId
          } - ${debugDetails} - ${String(err)}`,
        ]);
        return undefined;
      });
      setCancellationRequestCount(c => (c ?? 0) + 1);
    },
    [accessDate, products.length, ticketCount]
  );

  const handleRunClick = useCallback(async () => {
    // Reset states
    setWarnings([]);
    setBookingCount(0);
    setTransactionCount(0);
    setCancellationRequestCount(0);

    // Perform a Booking->Transaction->CancellationRequest for each products
    for (const [index, product] of products.entries()) {
      // eslint-disable-next-line no-await-in-loop
      await processProduct(index, product);
    }
  }, [processProduct, products]);

  const handleOpenDocumentsClick = useCallback(() => {
    for (const document of documents) {
      window.open(document, '_blank');
    }
  }, [documents]);

  const handleOpenCsvClick = useCallback(() => {
    triggerTextFileDownload({
      contentType: 'text/csv',
      content: [
        'TransactionDateTime,SaleId,PartnerSaleId,ProductId,Price,Quantity,AccessDateTime,ProductName,Document,DocumentLink',
        ...csvLines,
      ].join('\n'),
      fileName: `sales.csv`,
    });
  }, [csvLines]);

  return (
    <Wrapper>
      <Input
        overrides={adminInputTheme}
        label={'ACCESS DATE (leave empty for random)'}
        value={accessDate}
        syncState={setAccessDate}
        placeholder={getAccessDate(new Date())}
      />
      <Input
        overrides={adminInputTheme}
        label={'TICKET COUNT (leave empty for random)'}
        value={ticketCount}
        syncState={setTicketCount}
        placeholder="1"
      />
      <Button
        onClickAsync={handleRunClick}
      >{`RUN CERTIFICATION ON ${products.length} PRODUCTS`}</Button>
      {bookingCount !== undefined &&
      transactionCount !== undefined &&
      cancellationRequestCount !== undefined ? (
        <Status>
          <div>{`${bookingCount}/${products.length} Bookings`}</div>
          <div>{`${transactionCount}/${products.length} Transactions`}</div>
          <div>{`${cancellationRequestCount}/${products.length} Cancellation requests`}</div>
        </Status>
      ) : (
        <></>
      )}
      {csvLines.length > 0 ? (
        <ButtonAsLink onClick={handleOpenCsvClick}>{`Download CSV`}</ButtonAsLink>
      ) : (
        <></>
      )}
      {documents.length > 0 ? (
        <ButtonAsLink
          onClick={handleOpenDocumentsClick}
        >{`Open ${documents.length} documents`}</ButtonAsLink>
      ) : (
        <></>
      )}
      <Warnings>
        {warnings.map(w => (
          <Warning key={w}>{w}</Warning>
        ))}
      </Warnings>
    </Wrapper>
  );
};

ExpertTicketCertification.displayName = 'ExpertTicketCertification';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Status = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const Warnings = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const Warning = styled.div`
  background-color: #ffff0050;
  border-radius: 4px;
  padding: 4px;
`;
