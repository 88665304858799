/* eslint-disable react/no-multi-comp */
import {FC, memo, useCallback, useMemo} from 'react';

import {ageString, TimeFormatOptions} from '@shared/lib/time/format';

import {useHeartBeat} from '@shared-frontend/lib/use_heart_beat';

// Base component to display a timestamp

export const AutoRefresh: FC<{formatter: () => string}> = memo(({formatter}) => {
  const heartBeat = useHeartBeat();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const label = useMemo(() => formatter(), [formatter, heartBeat]); // Refresh every second

  return <>{label}</>;
});
AutoRefresh.displayName = 'AutoRefresh';

interface DurationComponentProps {
  ts: number;
  end?: number;
  future?: boolean;
}

function makeDurationComponent(
  formatter: (duration: number) => string
): FC<DurationComponentProps> {
  // eslint-disable-next-line react/display-name
  return function (props) {
    const {ts, end, future} = props;
    const f = useCallback(() => {
      const endTs = end ?? Date.now();
      const duration = future ? ts - endTs : endTs - ts;
      return formatter(duration);
    }, [end, future, ts]);
    return <AutoRefresh formatter={f} />;
  };
}

// Auto-refresh Timestamp component

export const SecondsAgo = makeDurationComponent(d => `${Math.floor(d / 1000)}s ago`);
SecondsAgo.displayName = 'SecondsAgo';

export const AgeString: FC<Partial<TimeFormatOptions> & DurationComponentProps> = memo(props => {
  const {ts, end, future} = props;
  const f = useCallback(() => {
    const endTs = end ?? Date.now();
    const duration = future ? ts - endTs : endTs - ts;
    return ageString(duration, props);
  }, [end, future, props, ts]);
  return <AutoRefresh formatter={f} />;
});
AgeString.displayName = 'AgeString';
