import {FC, Fragment, useCallback, useMemo} from 'react';
import {styled} from 'styled-components';

import {
  HoobiizWhiteLabeling,
  HoobiizWhiteLabelingAuth,
  HoobiizWhiteLabelingAuthModeHero,
  HoobiizWhiteLabelingAuthType,
} from '@shared/dynamo_model';
import {EnrichedHoobiizMedia} from '@shared/lib/hoobiiz/hoobiiz_white_labeling';
import {DeepPartial, removeUndefined} from '@shared/lib/type_utils';

import {RegisterPage} from '@shared-frontend/components/auth/base/register_page';
import {ColorInput} from '@shared-frontend/components/core/color_input';
import {Textarea} from '@shared-frontend/components/core/textarea_v2';
import {useEnrichedHoobiizMedia} from '@shared-frontend/lib/hoobiiz_media_store';

import {FormColumnFull, FormLabel} from '@src/components/admin/form/form_fragments';
import {MediaUpload} from '@src/components/admin/form/media_upload';
import {MediaUploadStatus} from '@src/components/admin/form/media_upload_status';
import {AdminUserAndGroupWhiteLabelingFormProps} from '@src/components/admin/user_and_group/admin_user_and_group_white_labeling_page';
import {AutoScale} from '@src/components/admin/user_and_group/auto_scale';
import {adminInputTheme, theme} from '@src/components/core/theme';
import {AdminWhiteLabelingValidator} from '@src/lib/hoobiiz_admin_white_labeling';

export type AdminWhiteLabelingModeHeroAuth = HoobiizWhiteLabelingAuth & {
  type: HoobiizWhiteLabelingAuthType.ModeHero;
};

export const isValidWhiteLabelingAuthModeHero: AdminWhiteLabelingValidator<
  HoobiizWhiteLabelingAuthModeHero
> = data => {
  if (!data) {
    return {valid: false};
  }

  return {
    valid: true,
    data: {
      ...data,
      type: HoobiizWhiteLabelingAuthType.ModeHero,
    },
  };
};

function useWhiteLabeling(
  labeling?: DeepPartial<HoobiizWhiteLabeling>
): EnrichedHoobiizMedia<DeepPartial<HoobiizWhiteLabeling>> {
  return useEnrichedHoobiizMedia(labeling) ?? {};
}

export const AdminUserAndGroupWhiteLabelingAuthModeHero: FC<
  AdminUserAndGroupWhiteLabelingFormProps<AdminWhiteLabelingModeHeroAuth>
> = props => {
  const {data, onChange, baseWhiteLabeling} = props;
  const handleTitleChange = useCallback(
    (title?: string) => onChange({...data, title}),
    [onChange, data]
  );

  const handleHeroMediaChange = useCallback(
    (statuses: MediaUploadStatus[]) => {
      const {hero, ...rest} = data ?? {};
      for (const status of statuses) {
        if (status.status === 'success') {
          onChange({...rest, hero: status.id});
          return;
        }
      }
      onChange({...rest, hero: undefined});
    },
    [data, onChange]
  );

  const handleLogoMediaChange = useCallback(
    (statuses: MediaUploadStatus[]) => {
      const {logo, ...rest} = data ?? {};
      for (const status of statuses) {
        if (status.status === 'success') {
          onChange({...rest, logo: status.id});
          return;
        }
      }
      onChange({...rest, logo: undefined});
    },
    [data, onChange]
  );

  //

  const handleBgGradient1Change = useCallback(
    (color: string) => onChange({...data, bgGradient1: color}),
    [onChange, data]
  );

  const handleBgGradient2Change = useCallback(
    (color: string) => onChange({...data, bgGradient2: color}),
    [onChange, data]
  );

  const handleButtonBackgroundColorChange = useCallback(
    (color: string) => onChange({...data, buttonBackgroundColor: color}),
    [onChange, data]
  );

  const handleButtonTextColorChange = useCallback(
    (color: string) => onChange({...data, buttonTextColor: color}),
    [onChange, data]
  );

  const handleInputBackgroundColorChange = useCallback(
    (color: string) => onChange({...data, inputBackgroundColor: color}),
    [onChange, data]
  );

  const handleInputTextColorChange = useCallback(
    (color: string) => onChange({...data, inputTextColor: color}),
    [onChange, data]
  );

  const handleInputBorderColorChange = useCallback(
    (color: string) => onChange({...data, inputBorderColor: color}),
    [onChange, data]
  );

  const handleTitleColorChange = useCallback(
    (color: string) => onChange({...data, titleColor: color}),
    [onChange, data]
  );

  const handleTextColorChange = useCallback(
    (color: string) => onChange({...data, textColor: color}),
    [onChange, data]
  );

  const handleLinkTextColorChange = useCallback(
    (color: string) => onChange({...data, linkTextColor: color}),
    [onChange, data]
  );

  const futureWhiteLabeling: HoobiizWhiteLabeling = useMemo(() => {
    return {
      ...baseWhiteLabeling,
      auth: {
        type: HoobiizWhiteLabelingAuthType.ModeHero,
        ...baseWhiteLabeling?.auth,
        register: data,
      },
    };
  }, [baseWhiteLabeling, data]);
  const previewWhiteLabeling = useWhiteLabeling(futureWhiteLabeling);

  return (
    <Wrapper>
      <Left1>
        <FormColumnFull>
          <Textarea
            label="Titre"
            placeholder="BIENVENUE SUR VOTRE SITE"
            height={64}
            value={data?.title}
            syncState={handleTitleChange}
            overrides={adminInputTheme}
          />
        </FormColumnFull>
        <FormColumnFull>
          <FormLabel>LOGO</FormLabel>
          <MediaUpload
            initialMediaIds={removeUndefined([data?.logo])}
            onChange={handleLogoMediaChange}
          />
        </FormColumnFull>
        <FormColumnFull>
          <FormLabel>HERO</FormLabel>
          <MediaUpload
            initialMediaIds={removeUndefined([data?.hero])}
            onChange={handleHeroMediaChange}
          />
        </FormColumnFull>
      </Left1>
      <Left2>
        <FormColumnFull>
          <FormLabel>Dégradé gauche</FormLabel>
          <ColorInput
            color={data?.bgGradient1 ?? theme.main.backgroundColor}
            syncState={handleBgGradient1Change}
            overrides={adminInputTheme}
          />
        </FormColumnFull>
        <FormColumnFull>
          <FormLabel>Dégradé droit</FormLabel>
          <ColorInput
            color={data?.bgGradient2 ?? theme.main.backgroundColor}
            syncState={handleBgGradient2Change}
            overrides={adminInputTheme}
          />
        </FormColumnFull>

        <FormColumnFull>
          <FormLabel>Bouton : Couleur</FormLabel>
          <ColorInput
            color={data?.buttonBackgroundColor ?? theme.button.backgroundActive ?? '#000000'}
            syncState={handleButtonBackgroundColorChange}
            overrides={adminInputTheme}
          />
        </FormColumnFull>
        <FormColumnFull>
          <FormLabel>Bouton : Texte</FormLabel>
          <ColorInput
            color={data?.buttonTextColor ?? theme.button.textColorActive ?? '#000000'}
            syncState={handleButtonTextColorChange}
            overrides={adminInputTheme}
          />
        </FormColumnFull>

        <FormColumnFull>
          <FormLabel>Champs de texte : Couleur</FormLabel>
          <ColorInput
            color={data?.inputBackgroundColor ?? theme.input.backgroundColor}
            syncState={handleInputBackgroundColorChange}
            overrides={adminInputTheme}
          />
        </FormColumnFull>
        <FormColumnFull>
          <FormLabel>Champs de texte : Texte</FormLabel>
          <ColorInput
            color={data?.inputTextColor ?? theme.input.textColor}
            syncState={handleInputTextColorChange}
            overrides={adminInputTheme}
          />
        </FormColumnFull>
        <FormColumnFull>
          <FormLabel>Champs de texte : Bordure</FormLabel>
          <ColorInput
            color={data?.inputBorderColor ?? theme.input.borderColor}
            syncState={handleInputBorderColorChange}
            overrides={adminInputTheme}
          />
        </FormColumnFull>

        <FormColumnFull>
          <FormLabel>Couleur du titre</FormLabel>
          <ColorInput
            color={data?.titleColor ?? theme.main.textColor}
            syncState={handleTitleColorChange}
            overrides={adminInputTheme}
          />
        </FormColumnFull>
        <FormColumnFull>
          <FormLabel>Couleur du texte</FormLabel>
          <ColorInput
            color={data?.textColor ?? theme.main.textColor}
            syncState={handleTextColorChange}
            overrides={adminInputTheme}
          />
        </FormColumnFull>
        <FormColumnFull>
          <FormLabel>Couleur des liens</FormLabel>
          <ColorInput
            color={data?.linkTextColor ?? theme.link.textColorActive ?? '#000000'}
            syncState={handleLinkTextColorChange}
            overrides={adminInputTheme}
          />
        </FormColumnFull>
      </Left2>
      <Right>
        <AutoScale width={1280} height={1280}>
          <RegisterPage whiteLabeling={previewWhiteLabeling} wrapper={Fragment} />
        </AutoScale>
      </Right>
    </Wrapper>
  );
};

AdminUserAndGroupWhiteLabelingAuthModeHero.displayName =
  'AdminUserAndGroupWhiteLabelingAuthModeHero';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

const Left1 = styled.div`
  flex-shrink: 0;
  width: 256px;
`;

const Left2 = styled.div`
  flex-shrink: 0;
  width: 256px;
`;

const Right = styled.div`
  flex-grow: 1;
  box-shadow: 0px 0px 20px -10px black;
  border-radius: 8px;
  overflow: hidden;
`;
