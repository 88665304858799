import {FC, useCallback, useEffect, useState} from 'react';
import {styled} from 'styled-components';

import {
  HoobiizFlexibleStockScheduleInherit,
  HoobiizFlexibleStockScheduleType,
  HoobiizOpeningHours,
} from '@shared/dynamo_model';

import {ButtonAsLink} from '@shared-frontend/components/core/button';

import {OpeningHoursForm} from '@src/components/admin/form/opening_hours_form';

interface HoobiizFlexibleStockScheduleInheritFormProps {
  vendorHours: HoobiizOpeningHours;
  initialData?: HoobiizFlexibleStockScheduleInherit;
  onChange: (newVal: HoobiizFlexibleStockScheduleInherit) => void;
}

export const HoobiizFlexibleStockScheduleInheritForm: FC<
  HoobiizFlexibleStockScheduleInheritFormProps
> = props => {
  const {vendorHours, onChange} = props;

  const [vendorShown, setVendorShown] = useState(false);

  useEffect(() => {
    onChange({type: HoobiizFlexibleStockScheduleType.Inherit});
  }, [onChange]);

  const handleShowClick = useCallback(() => setVendorShown(shown => !shown), []);

  return (
    <Wrapper>
      <StyledButton onClick={handleShowClick}>{`${
        vendorShown ? 'Cacher' : 'Afficher'
      } les horaires`}</StyledButton>
      {vendorShown ? <OpeningHoursForm openingHours={vendorHours} disabled /> : <></>}
    </Wrapper>
  );
};
HoobiizFlexibleStockScheduleInheritForm.displayName = 'HoobiizFlexibleStockScheduleInheritForm';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledButton = styled(ButtonAsLink)`
  margin-left: 8px;
`;
