import {FC, JSX} from 'react';
import styled from 'styled-components';

interface VendorConfirmPageProps {
  message: string;
  content?: JSX.Element;
  button: JSX.Element;
}

export const HoobiizVendorConfirmModal: FC<VendorConfirmPageProps> = ({
  message,
  button,
  content,
}) => {
  return (
    <ModalWrapper>
      <ModalTitle>{message}</ModalTitle>
      {content ?? <></>}
      <ModalForm>{button}</ModalForm>
    </ModalWrapper>
  );
};
HoobiizVendorConfirmModal.displayName = 'HoobiizVendorConfirmModal';

const margin = 16;
const width = 600;
const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  width: ${width}px;
  padding: 48px;
  background-color: white;
  border-radius: 16px;
  @media (max-width: ${width + 2 * margin}px) {
    width: calc(100vw - ${2 * margin}px);
    gap: 24px;
  }
`;

const ModalTitle = styled.div`
  text-align: center;
  width: 100%;
  font-size: 25px;
`;

const ModalForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
