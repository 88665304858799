import {useEffect, useRef, useState} from 'react';
import {styled} from 'styled-components';

import {
  HoobiizStockModeType,
  HoobiizStockReservationType,
  HoobiizTicketInfo,
  HoobiizVisibility,
} from '@shared/dynamo_model';
import {joinWithLastJoiner} from '@shared/lib/string_utils';

import {Input} from '@shared-frontend/components/core/input_v2';
import {Select} from '@shared-frontend/components/core/select';
import {localDuration} from '@shared-frontend/lib/date_format';
import {Custom, NULL_REF} from '@shared-frontend/lib/react';

import {
  ensureTicketInfoOption,
  MaybeTicketOption,
} from '@src/components/admin/activity_stock/hoobiiz_ticket_info_options_form';
import {HoobiizTicketInfoOptionsMultiForm} from '@src/components/admin/activity_stock/hoobiiz_ticket_info_options_multi_form';
import {
  FormColumn,
  FormColumnAuto,
  FormColumnFix,
  FormColumnFull,
  FormRow,
} from '@src/components/admin/form/form_fragments';
import {HoobiizMessage} from '@src/components/admin/form/hoobiiz_message';
import {HoobiizVisibilityForm} from '@src/components/admin/form/hoobiiz_visibility_form';
import {adminInputTheme} from '@src/components/core/theme';
import {discountToString, getDiscount} from '@src/lib/discount';
import {
  currencyAmountToInputString,
  inputStringToCurrencyAmount,
  inputStringToQty,
  qtyToInputString,
} from '@src/lib/hoobiiz_input_string';

export type MaybeTicket = Exclude<HoobiizTicketInfo | Pick<HoobiizTicketInfo, 'id'>, 'options'> & {
  options?: MaybeTicketOption[];
};

export function ensureTicketInfo(
  tickets: MaybeTicket[]
): {success: false; err: string} | {success: true; tickets: HoobiizTicketInfo[]} {
  const ticketInfos: HoobiizTicketInfo[] = [];
  const incompleteIndexes: number[] = [];
  for (const [index, ticket] of tickets.entries()) {
    if ('label' in ticket) {
      const {success} = ensureTicketInfoOption(ticket.options ?? []);
      if (success) {
        ticketInfos.push(ticket);
      } else {
        incompleteIndexes.push(index);
      }
    } else {
      incompleteIndexes.push(index);
    }
  }
  if (incompleteIndexes.length > 0) {
    const plural = incompleteIndexes.length > 1 ? 's' : '';
    return {
      success: false,
      err: `Ticket${plural} ${joinWithLastJoiner(
        incompleteIndexes.map(i => `#${i + 1}`),
        {sep: ', ', lastSep: ' et '}
      )} incomplet${plural}`,
    };
  }
  return {success: true, tickets: ticketInfos};
}

interface HoobiizTicketInfoFormProps {
  initialData: MaybeTicket;
  baseDurationMinutes?: number;
  maxDurationMinutes?: number;
  reservationType?: HoobiizStockReservationType;
  modeType?: HoobiizStockModeType;
  onChange?: (newVal: MaybeTicket, el: HTMLDivElement) => void;
}

export const HoobiizTicketInfoForm: Custom<
  HoobiizTicketInfoFormProps,
  'div',
  'onChange'
> = props => {
  const ref = useRef<HTMLDivElement>(NULL_REF);
  const {
    initialData,
    baseDurationMinutes,
    maxDurationMinutes,
    reservationType,
    modeType,
    onChange,
    ...rest
  } = props;

  const durationMinutes =
    baseDurationMinutes === undefined || baseDurationMinutes < 1 ? undefined : baseDurationMinutes;

  const [label, setLabel] = useState('label' in initialData ? initialData.label : '');
  const [description, setDescription] = useState(
    'description' in initialData ? initialData.description ?? '' : ''
  );
  const [buyingPrice, setBuyingPrice] = useState(
    'buyingPrice' in initialData ? initialData.buyingPrice : undefined
  );
  const [publicPrice, setPublicPrice] = useState(
    'publicPrice' in initialData ? initialData.publicPrice : undefined
  );
  const [youpiizPrice, setYoupiizPrice] = useState(
    'youpiizPrice' in initialData ? initialData.youpiizPrice : undefined
  );
  const [fees, setFees] = useState('fees' in initialData ? initialData.fees : undefined);
  const [visibility, setVisibility] = useState(
    'visibility' in initialData ? initialData.visibility : HoobiizVisibility.Public
  );
  const [span, setSpan] = useState('span' in initialData ? initialData.span : 1);
  const [minQuantity, setMinQuantity] = useState(
    'minQuantity' in initialData ? initialData.minQuantity : undefined
  );
  const [maxQuantity, setMaxQuantity] = useState(
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    'maxQuantity' in initialData ? initialData.maxQuantity : 20
  );
  const [options, setOptions] = useState<MaybeTicketOption[]>(
    ('options' in initialData ? initialData.options : []) ?? []
  );
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    if (
      !buyingPrice ||
      !publicPrice ||
      !youpiizPrice ||
      label.length === 0 ||
      (minQuantity !== undefined && maxQuantity !== undefined && maxQuantity < minQuantity)
    ) {
      setIsComplete(false);
      onChange?.({id: initialData.id}, ref.current);
      return;
    }
    setIsComplete(true);
    onChange?.(
      {
        id: initialData.id,
        label,
        description,
        buyingPrice,
        publicPrice,
        youpiizPrice,
        fees,
        span,
        visibility,
        options,
        minQuantity,
        maxQuantity,
      },
      ref.current
    );
  }, [
    buyingPrice,
    description,
    initialData.id,
    label,
    maxQuantity,
    minQuantity,
    onChange,
    options,
    publicPrice,
    span,
    visibility,
    youpiizPrice,
    fees,
  ]);

  const discount = getDiscount(
    publicPrice !== undefined && youpiizPrice !== undefined
      ? {publicPrice, youpiizPrice}
      : undefined,
    []
  );

  return (
    <>
      <FormRow ref={ref} $align="flex-end" {...rest}>
        {durationMinutes !== undefined ? (
          <FormColumnFix $width={128}>
            <Select
              placeholder="Durée"
              syncState={setSpan}
              value={span}
              values={[
                ...new Array(Math.floor((maxDurationMinutes ?? durationMinutes) / durationMinutes)),
              ].map((_, i) => ({
                label: localDuration((i + 1) * durationMinutes),
                value: i + 1,
              }))}
              overrides={adminInputTheme}
              label="DURÉE"
            />
          </FormColumnFix>
        ) : (
          <></>
        )}
        <FormColumn>
          <Input
            width="100%"
            value={label}
            syncState={setLabel}
            placeholder={'label' in initialData ? initialData.label : ''}
            label="LIBELLÉ"
            overrides={adminInputTheme}
          />
        </FormColumn>
        <FormColumn>
          <Input
            width="100%"
            value={description}
            syncState={setDescription}
            placeholder={'description' in initialData ? initialData.description : ''}
            label="INFOBULLE"
            overrides={adminInputTheme}
          />
        </FormColumn>
        <FormRow>
          <FormColumnFix $width={96}>
            <Input
              width="100%"
              value={publicPrice}
              syncState={setPublicPrice}
              asString={currencyAmountToInputString}
              fromString={inputStringToCurrencyAmount}
              label="PRIX PUBLIC"
              overrides={adminInputTheme}
            />
          </FormColumnFix>
          <FormColumnFix $width={96}>
            <Input
              width="100%"
              value={youpiizPrice}
              syncState={setYoupiizPrice}
              asString={currencyAmountToInputString}
              fromString={inputStringToCurrencyAmount}
              label={
                <YoupiizPrice>
                  <div>PRIX YOUPIIZ</div>
                  {discount.totalDiscount.percent > 0 ? (
                    <DiscountPreview>{discountToString(discount.totalDiscount)}</DiscountPreview>
                  ) : (
                    <></>
                  )}
                </YoupiizPrice>
              }
              overrides={adminInputTheme}
            />
          </FormColumnFix>
          <FormColumnFix $width={96}>
            <Input
              width="100%"
              value={fees}
              syncState={setFees}
              asString={currencyAmountToInputString}
              fromString={inputStringToCurrencyAmount}
              label="FRAIS DE GESTION"
              overrides={adminInputTheme}
            />
          </FormColumnFix>
          <FormColumnFix $width={96}>
            <Input
              width="100%"
              value={buyingPrice}
              syncState={setBuyingPrice}
              asString={currencyAmountToInputString}
              fromString={inputStringToCurrencyAmount}
              label="PRIX ACHAT"
              overrides={adminInputTheme}
            />
          </FormColumnFix>
        </FormRow>
        <FormRow>
          <FormColumnFix $width={96}>
            <Input
              width="100%"
              value={minQuantity}
              syncState={setMinQuantity}
              asString={qtyToInputString}
              fromString={inputStringToQty}
              label="QTÉ MIN"
              overrides={adminInputTheme}
            />
          </FormColumnFix>
          <FormColumnFix $width={96}>
            <Input
              width="100%"
              value={maxQuantity}
              syncState={setMaxQuantity}
              asString={qtyToInputString}
              fromString={inputStringToQty}
              label="QTÉ MAX"
              overrides={adminInputTheme}
            />
          </FormColumnFix>
        </FormRow>
        <FormColumnAuto>
          <HoobiizVisibilityForm value={visibility} syncState={setVisibility} tall />
        </FormColumnAuto>
        <HoobiizTicketInfoOptionsMultiForm initialData={options} onChange={setOptions} />
      </FormRow>
      {isComplete ? (
        <></>
      ) : (
        <FormColumnFull>
          <HoobiizMessage color="warning" messages={['Ticket incomplet ou invalide']} />
        </FormColumnFull>
      )}
    </>
  );
};
HoobiizTicketInfoForm.displayName = 'HoobiizTicketInfoForm';

const YoupiizPrice = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const DiscountPreview = styled.div`
  text-align: center;
  color: green;
`;
