import {FC, useCallback} from 'react';
import {styled} from 'styled-components';

import {HoobiizWhiteLabelingAuth, HoobiizWhiteLabelingAuthType} from '@shared/dynamo_model';
import {neverHappensLog, UnionToTuple} from '@shared/lib/type_utils';

import {Select} from '@shared-frontend/components/core/select';

import {
  AdminUserAndGroupWhiteLabelingAuthModeHero,
  isValidWhiteLabelingAuthModeHero,
} from '@src/components/admin/user_and_group/admin_user_and_group_white_labeling_auth_mode_hero';
import {AdminUserAndGroupWhiteLabelingFormProps} from '@src/components/admin/user_and_group/admin_user_and_group_white_labeling_page';
import {adminInputTheme} from '@src/components/core/theme';
import {AdminWhiteLabelingValidator} from '@src/lib/hoobiiz_admin_white_labeling';

const MODE_LABELS: Record<HoobiizWhiteLabelingAuthType, string> = {
  [HoobiizWhiteLabelingAuthType.ModeDefault]: 'Par défaut',
  [HoobiizWhiteLabelingAuthType.ModeHero]: 'Bannière',
};

const ALL_MODES: UnionToTuple<
  (typeof HoobiizWhiteLabelingAuthType)[keyof typeof HoobiizWhiteLabelingAuthType]
> = [HoobiizWhiteLabelingAuthType.ModeDefault, HoobiizWhiteLabelingAuthType.ModeHero];

export const isValidWhiteLabelingAuth: AdminWhiteLabelingValidator<
  HoobiizWhiteLabelingAuth | undefined
> = data => {
  // No theme override is the default theme
  if (data?.type === undefined || data.type === HoobiizWhiteLabelingAuthType.ModeDefault) {
    return {valid: true, data: undefined};
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (data.type === HoobiizWhiteLabelingAuthType.ModeHero) {
    // return {valid: true, whiteLabeling: {}};
    const res = isValidWhiteLabelingAuthModeHero(data);
    if (!res.valid) {
      return {valid: false};
    }
    return {valid: true, data: res.data};
  }

  neverHappensLog(data.type);
  return {valid: false};
};

export const AdminUserAndGroupWhiteLabelingAuth: FC<
  AdminUserAndGroupWhiteLabelingFormProps<HoobiizWhiteLabelingAuth>
> = props => {
  const {data, onChange, baseWhiteLabeling} = props;
  // Reset form when theme changes
  const handleModeChange = useCallback(
    (type: HoobiizWhiteLabelingAuthType | undefined) => {
      onChange(type === undefined ? undefined : {type});
    },
    [onChange]
  );

  const handleAuthChange = useCallback(
    (data?: Partial<HoobiizWhiteLabelingAuth>) => {
      onChange(data);
    },
    [onChange]
  );

  return (
    <Wrapper>
      <div>
        <Select<HoobiizWhiteLabelingAuthType | undefined>
          label="Mise en page"
          placeholder="Choisir une mise en page"
          value={data?.type}
          values={ALL_MODES.map(key => ({value: key, label: MODE_LABELS[key]}))}
          syncState={handleModeChange}
          overrides={adminInputTheme}
        />
      </div>
      {data?.type === HoobiizWhiteLabelingAuthType.ModeHero ? (
        <AdminUserAndGroupWhiteLabelingAuthModeHero
          data={data}
          onChange={handleAuthChange}
          baseWhiteLabeling={baseWhiteLabeling}
        />
      ) : (
        <></>
      )}
    </Wrapper>
  );
};

AdminUserAndGroupWhiteLabelingAuth.displayName = 'AdminUserAndGroupWhiteLabelingLoginConfig';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
