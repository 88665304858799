import {FC, JSX, useMemo} from 'react';
import styled from 'styled-components';

import {optional} from '@shared-frontend/lib/styled_utils';

interface HoobiizMessageProps {
  messages: (string | JSX.Element)[];
  color?: 'warning' | 'info';
  marginTop?: string | number;
  textAlign?: 'left' | 'center' | 'right';
}

export const HoobiizMessage: FC<HoobiizMessageProps> = props => {
  const {messages, color = 'info', marginTop, textAlign} = props;

  const backgroundColor = useMemo(() => {
    if (color === 'warning') {
      return '#f8edc2';
    }
    return '#e5f0fa';
  }, [color]);

  return (
    <Message $backgroundColor={backgroundColor} $marginTop={marginTop} $textAlign={textAlign}>
      {messages.map((m, i) => (
        <Warning key={String(i)}>{m}</Warning>
      ))}
    </Message>
  );
};

HoobiizMessage.displayName = 'HoobiizMessage';

const Message = styled.div<{
  $backgroundColor: string;
  $marginTop?: string | number;
  $textAlign?: 'left' | 'center' | 'right';
}>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: ${p => p.$backgroundColor};
  border-radius: 8px;
  padding: 16px;
  ${p => optional('margin-top', p.$marginTop)};
  ${p => optional('text-align', p.$textAlign)};
`;

const Warning = styled.div``;
