/**
 * Inspired from react-colorful.
 * https://github.com/omgovich/react-colorful
 */
/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable react/no-multi-comp */
import {FC, ReactNode, useCallback, useMemo, useRef} from 'react';
import styled from 'styled-components';

import {CustomWithStyle, NULL_REF} from '@shared-frontend/lib/react';

//
// INTERACTIVE COMPONENT
//

const clamp = (value: number, min = 0, max = 1): number => {
  return Math.min(Math.max(value, min), max);
};

const Interactive: CustomWithStyle<
  {
    onMove: (pos: {x: number; y: number}) => void;
    children: ReactNode;
  },
  'div'
> = props => {
  const {onMove, children, ...rest} = props;
  const wrapperRef = useRef<HTMLDivElement>(NULL_REF);

  const handleMoveEvent = useCallback(
    (event: {preventDefault: () => void; pageX: number; pageY: number}) => {
      const el = wrapperRef.current;
      const parentWindow = el?.ownerDocument.defaultView;
      if (!el || !parentWindow) {
        return;
      }
      event.preventDefault();

      const rect = el.getBoundingClientRect();
      const x = clamp((event.pageX - (rect.left + parentWindow.scrollX)) / rect.width);
      const y = clamp((event.pageY - (rect.top + parentWindow.scrollY)) / rect.height);
      onMove({x, y});
      return {el, parentWindow};
    },
    [onMove]
  );

  const handleMoveEnd = useCallback(() => {
    const parentWindow = wrapperRef.current?.ownerDocument.defaultView;
    if (!parentWindow) {
      return;
    }
    parentWindow.removeEventListener('mousemove', handleMoveEvent);
    parentWindow.removeEventListener('mouseup', handleMoveEnd);
  }, [handleMoveEvent]);

  const handleMoveStart = useCallback(
    (event: React.MouseEvent) => {
      const res = handleMoveEvent(event);
      if (!res) {
        return;
      }
      const {parentWindow} = res;
      parentWindow.addEventListener('mousemove', handleMoveEvent);
      parentWindow.addEventListener('mouseup', handleMoveEnd);
    },
    [handleMoveEvent, handleMoveEnd]
  );

  return (
    <div ref={wrapperRef} onMouseDown={handleMoveStart} {...rest}>
      {children}
    </div>
  );
};
Interactive.displayName = 'Interactive';

//
// COLOR CONVERSIONS
//

interface RgbaColor {
  r: number;
  g: number;
  b: number;
  a: number;
}

interface HsvaColor {
  h: number;
  s: number;
  v: number;
  a: number;
}

const hexToVal = (val: string): number => {
  const num = parseInt(val, 16);
  return isNaN(num) ? 1 : num / 255;
};

const valToHex = (val: number): string => {
  return Math.round(val * 255)
    .toString(16)
    .padStart(2, '0');
};

export function hexStringToRgba(hex: string): RgbaColor {
  // Convert to RGBA
  let value = hex;
  if (hex.startsWith('#')) {
    value = hex.slice(1);
  }
  const r = hexToVal(value.slice(0, 2));
  const g = hexToVal(value.slice(2, 4));
  const b = hexToVal(value.slice(4, 6));
  const a = hexToVal(value.slice(6, 8));
  return {r, g, b, a};
}

export function rgbaToHsva(rgba: RgbaColor): HsvaColor {
  const {r, g, b, a} = rgba;

  // Convert to HSV
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  const d = max - min;
  let h = max;
  const s = max === 0 ? 0 : d / max;
  const v = max;

  if (max === min) {
    h = 0; // achromatic
  } else if (max === r) {
    h = (g - b) / d + (g < b ? 6 : 0);
  } else if (max === g) {
    h = (b - r) / d + 2;
  } else if (max === b) {
    h = (r - g) / d + 4;
  }
  h /= 6;

  // Return HSVA
  return {h, s, v, a};
}

export function hsvaToRgba(hsva: HsvaColor): RgbaColor {
  const {h, s, v, a} = hsva;

  const i = Math.floor(h * 6);
  const f = h * 6 - i;
  const p = v * (1 - s);
  const q = v * (1 - f * s);
  const t = v * (1 - (1 - f) * s);

  let r = 0;
  let g = 0;
  let b = 0;

  if (i % 6 === 0) {
    r = v;
    g = t;
    b = p;
  } else if (i % 6 === 1) {
    r = q;
    g = v;
    b = p;
  } else if (i % 6 === 2) {
    r = p;
    g = v;
    b = t;
  } else if (i % 6 === 3) {
    r = p;
    g = q;
    b = v;
  } else if (i % 6 === 4) {
    r = t;
    g = p;
    b = v;
  } else if (i % 6 === 5) {
    r = v;
    g = p;
    b = q;
  }

  return {r, g, b, a};
}

export function rgbaToHexString(rgba: RgbaColor): string {
  const {r, g, b, a} = rgba;
  return `#${valToHex(r)}${valToHex(g)}${valToHex(b)}${a === 1 ? '' : valToHex(a)}`;
}

// Utils
export const hsvaToHexString = (hsva: HsvaColor): string => rgbaToHexString(hsvaToRgba(hsva));
export const hexStringToHsva = (hex: string): HsvaColor => rgbaToHsva(hexStringToRgba(hex));

//
// COLOR PICKER COMPONENT
//

export interface ColorData {
  hex: string;
  hsva: HsvaColor;
}

interface ColorPickerProps {
  color: ColorData;
  onChange: (color: ColorData) => void;
}

export const ColorPicker: FC<ColorPickerProps> = ({color, onChange}) => {
  const handleColorChange = useCallback(
    (hsva: HsvaColor) => onChange({hsva, hex: hsvaToHexString(hsva)}),
    [onChange]
  );

  const handleSaturationMove = useCallback(
    (pos: {x: number; y: number}) => {
      handleColorChange({...color.hsva, s: pos.x, v: 1 - pos.y});
    },
    [handleColorChange, color]
  );
  const handleHueMove = useCallback(
    (pos: {x: number; y: number}) => {
      handleColorChange({...color.hsva, h: pos.y});
    },
    [handleColorChange, color]
  );
  const handleAlphaMove = useCallback(
    (pos: {x: number; y: number}) => {
      handleColorChange({...color.hsva, a: 1 - pos.y});
    },
    [handleColorChange, color]
  );

  const hueString = useMemo(
    () => hsvaToHexString({h: color.hsva.h, s: 1, v: 1, a: 1}),
    [color.hsva.h]
  );
  const colorOpaqueString = useMemo(() => hsvaToHexString({...color.hsva, a: 1}), [color.hsva]);

  const alphaGradient = useMemo(() => {
    const from = hsvaToHexString({...color.hsva, a: 0});
    const to = hsvaToHexString({...color.hsva, a: 1});
    return `linear-gradient(0, ${from}, ${to})`;
  }, [color.hsva]);

  const saturationStyle = useMemo(() => ({backgroundColor: hueString}), [hueString]);
  const saturationPointerStyle = useMemo(
    () => ({
      backgroundColor: colorOpaqueString,
      left: `${100 * color.hsva.s}%`,
      top: `${100 * (1 - color.hsva.v)}%`,
    }),
    [colorOpaqueString, color.hsva.s, color.hsva.v]
  );
  const huePointerStyle = useMemo(
    () => ({backgroundColor: hueString, left: `50%`, top: `${100 * color.hsva.h}%`}),
    [hueString, color.hsva.h]
  );
  const alphaGradientStyle = useMemo(() => ({backgroundImage: alphaGradient}), [alphaGradient]);
  const alphaPointerStyle = useMemo(
    () => ({
      backgroundColor: color.hex,
      left: `50%`,
      top: `${100 * (1 - color.hsva.a)}%`,
    }),
    [color.hex, color.hsva.a]
  );

  /* eslint-disable react/forbid-component-props */
  return (
    <Wrapper>
      <Saturation style={saturationStyle} onMove={handleSaturationMove}>
        <Pointer style={saturationPointerStyle} />
      </Saturation>
      <Hue onMove={handleHueMove}>
        <Pointer style={huePointerStyle} />
      </Hue>
      <Alpha onMove={handleAlphaMove}>
        <AlphaGradient style={alphaGradientStyle} />
        <Pointer style={alphaPointerStyle} />
      </Alpha>
    </Wrapper>
  );
  /* eslint-enable react/forbid-component-props */
};
ColorPicker.displayName = 'ColorPicker';

const Wrapper = styled.div`
  display: flex;
`;

const Pointer = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #ffffff;
`;

const Saturation = styled(Interactive)`
  position: relative;
  width: 300px;
  height: 300px;
  flex-shrink: 0;
  border-color: transparent;
  background-image: linear-gradient(to top, #000, rgba(0, 0, 0, 0)),
    linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
  z-index: 20;
`;

const Hue = styled(Interactive)`
  position: relative;
  flex-shrink: 0;
  width: 32px;
  background: linear-gradient(
    to bottom,
    #f00 0%,
    #ff0 17%,
    #0f0 33%,
    #0ff 50%,
    #00f 67%,
    #f0f 83%,
    #f00 100%
  );
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
  z-index: 10;
`;

const Alpha = styled(Interactive)`
  position: relative;
  flex-shrink: 0;
  width: 32px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill-opacity=".05"><rect x="8" width="8" height="8"/><rect y="8" width="8" height="8"/></svg>');
  z-index: 10;
`;

const AlphaGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
